<template>
    <div>
        <v-dialog v-model="dialog_edit" max-width="600">
            <v-card>
                <v-card-title>
                    <span class="headline" v-if="new_item">Create New User</span>
                    <span class="headline" v-else-if="selected.length > 1">Bulk Edit Users</span>
                    <span class="headline" v-else>Edit User</span>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 v-if="new_item | (selected.length == 1)">
                                <v-text-field
                                    v-model="editedItem.profile.name"
                                    :rules="[rules.required]"
                                    prepend-icon="mdi-account-circle"
                                    label="Name"
                                    hint="Full name, so please enter first and last name."
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 v-if="new_item | (selected.length == 1)">
                                <v-text-field
                                    v-model="editedItem.profile.email"
                                    :rules="[rules.required, rules.email]"
                                    prepend-icon="mdi-email"
                                    label="Email"
                                    hint="This will be used to login."
                                    class="mb-4"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-expansion-panels focusable>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header disable-icon-rotate>
                                            Choose Password
                                            <template v-slot:actions>
                                                <v-icon>mdi-key</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-text-field
                                                v-model="password"
                                                :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                                :rules="[rules.min]"
                                                :type="show_password ? 'text' : 'password'"
                                                name="password"
                                                label="Password"
                                                counter
                                                @click:append="show_password = !show_password"
                                            ></v-text-field>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header disable-icon-rotate>
                                            Choose Role
                                            <template v-slot:actions>
                                                <v-icon>mdi-shield-key</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-autocomplete
                                                v-model="editedItem.role"
                                                :items="roleItems"
                                                :loading="roleLoading"
                                                placeholder="Start typing to search for roles."
                                                item-text="name"
                                                item-value="id"
                                                auto-select-first
                                                return-object
                                            ></v-autocomplete>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header disable-icon-rotate>
                                            Choose Customer
                                            <template v-slot:actions>
                                                <v-icon>mdi-account-tie</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-autocomplete
                                                v-model="editedItem.customer"
                                                :items="customerItems"
                                                :loading="customerLoading"
                                                placeholder="Start typing to search for customers"
                                                item-text="customer_name"
                                                item-value="id"
                                                dense
                                                auto-select-first
                                                return-object
                                            >
                                            </v-autocomplete>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header disable-icon-rotate>
                                            Customer Group Access
                                            <template v-slot:actions>
                                                <v-icon>mdi-account-group</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-switch
                                                v-model="editedItem.group_all"
                                                label="Access all data of all customers"
                                                append-icon="mdi-alert"
                                                color="red"
                                                hide-details
                                            ></v-switch>
                                            <v-expand-transition>
                                                <v-autocomplete
                                                    v-if="!editedItem.group_all"
                                                    v-model="editedItem.customer_group"
                                                    :items="customerItems"
                                                    :loading="customerLoading"
                                                    placeholder="Start typing to search for customers."
                                                    item-text="customer_name"
                                                    item-value="id"
                                                    auto-select-first
                                                    dense
                                                    multiple
                                                    chips
                                                    deletable-chips
                                                    hide-selected
                                                    class="mt-3"
                                                >
                                                </v-autocomplete>
                                            </v-expand-transition>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header disable-icon-rotate>
                                            SmartSim Credits
                                            <template v-slot:actions>
                                                <v-icon>mdi-wallet</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content class="mt-2">
                                            <v-text-field
                                                v-model="editedItem.profile.credits"
                                                label="Credit amount in customer SmartSim Wallet"
                                                dense
                                                hide-details
                                                prepend-icon="mdi-wallet-plus"
                                            ></v-text-field>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-flex>
                        </v-layout>
                        <v-flex mt-4>
                            <v-btn @click.native="dialog_reset = true; dialog_edit = false" block color="warning">
                                <v-icon left>mdi-account-convert</v-icon>
                                Reset User Account
                            </v-btn>
                        </v-flex>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" text @click.native="close">Close</v-btn>
                    <v-btn :disabled="saveButtonState" color="primary darken-1" text @click.native="save">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_delete" max-width="548">
            <v-card>
                <v-card-title class="headline">Are you sure?</v-card-title>

                <v-card-text>
                    <p>You are about to kill {{ this.selected.length }} users. Are you sure you want to do this?</p>
                    <img src="/img/kill.gif" />
                </v-card-text>

                <v-card-actions>
                    <div class="flex-grow-1"></div>

                    <v-btn color="primary darken-1" text @click.native="dialog_delete = false"> Cancel </v-btn>

                    <v-btn color="primary darken-1" text @click.native="delete_user"> Murder! </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_reset" max-width="548">
            <v-card>
                <v-card-title>Confirm Account Reset</v-card-title>
                
                <v-card-text>
                    <p>
                        You are about to initiate a reset for the account belonging to: <strong>{{ this.editedItem.profile.name }}</strong>. Once confirmed, the following actions will occur:
                    </p>
                    <ul>
                        <li>An email will be dispatched to: <strong>{{ this.editedItem.profile.email }}</strong>.</li>
                        <li>The email will contain a link allowing the user to set a new password.</li>
                        <li>Upon setting a new password, the user will regain access to their account.</li>
                        <li>All security settings, including 2FA, will be disabled. The user will need to set them up again for added security.</li>
                    </ul>
                    <p>
                        Please proceed with caution. Only confirm if you're sure about resetting this account and the security implications involved.
                    </p>
                </v-card-text>

                <v-card-actions>

                    <v-btn color="primary darken-1" text @click.native="dialog_reset = false">Cancel</v-btn>

                    <v-btn color="warning darken-1" text @click.native="reset_user"> Confirm Reset </v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>



        <h1 class="mb-4">Users</h1>

        <v-toolbar dense dark>
            <v-text-field name="searchfield" prepend-icon="mdi-account-search" v-model="search" clearable hide-details>
            </v-text-field>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn text @click="showEditDialog(true)"> <v-icon left>mdi-account-plus</v-icon>New User </v-btn>
                <v-menu offset-y>
                    <template v-slot:[`activator`]="{ on }">
                        <v-btn text v-on="on" :disabled="toggleActionsButton">
                            <v-icon left>mdi-ballot-outline</v-icon>
                            Actions
                        </v-btn>
                    </template>
                    <v-list v-if="selected.length == 1" dense>
                        <v-list-item-group color="primary">
                            <v-list-item @click="showDeleteDialog()">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-skull</v-icon>
                                        Kill User
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="showEditDialog()">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-account-edit</v-icon>
                                        Edit User
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                    <v-list v-else-if="selected.length > 1" dense>
                        <v-list-item-group color="primary">
                            <v-list-item @click="showDeleteDialog()">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-skull</v-icon>
                                        Bulk Kill Users
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="showEditDialog()">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-account-edit</v-icon>
                                        Bulk Edit Users
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table
            v-model="selected"
            :loading="pending"
            :headers="headers"
            :items="payload"
            :search="search"
            :mobile-breakpoint="0"
            :footer-props="{ itemsPerPageOptions: [50, 100, 500] }"
            no-results-text="Loading data..."
            class="elevation-1"
            item-key="id"
            sort-by="id"
            sort-desc
            show-select
            @click:row="clickSelect"
        >
            <template v-slot:[`item.customer_group_view`]="{ item }">
                <div v-if="typeof item.customer_group_view !== 'undefined'">
                    <v-chip v-if="item.customer_group_view == '#all'" color="red" text-color="white" small>
                        <v-avatar left>
                            <v-icon>mdi-alert-decagram</v-icon>
                        </v-avatar>
                        All Access
                    </v-chip>

                    <v-tooltip v-else bottom>
                        <template v-slot:[`activator`]="{ on }">
                            <v-chip v-on="on" color="primary" text-color="white" small>
                                <v-avatar left class="secondary">
                                    {{ item.customer_group_view.length }}
                                </v-avatar>
                                Customers
                            </v-chip>
                        </template>
                        <ul>
                            <li v-for="customer in item.customer_group_view" :key="customer.value">{{ customer }}</li>
                        </ul>
                    </v-tooltip>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
export default {
    data() {
        return {
            search: null,
            headers: [
                { text: 'Name', value: 'profile.name' },
                { text: 'Email', value: 'profile.email' },
                { text: 'Customer', value: 'customer_view' },
                { text: 'Customer Group', value: 'customer_group_view' },
                { text: 'Role', value: 'role.name' },
                { text: 'Credits', value: 'profile.credits', filterable: false },
            ],
            options: {},
            selected: [],
            dialog_edit: false,
            dialog_delete: false,
            dialog_reset: false,
            new_item: false,
            show_password: false,
            password: '',
            editedItem: { group_all: false, customer_group: [], profile: {}, customer: {}, role: {} },
            rules: {
                required: (value) => !!value || 'Required.',
                min: (v) => v.length >= 8 || 'Minimum of 8 characters.',
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            },
        }
    },
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
            customers: (state) => state.calls.customer_list,
            roles: (state) => state.calls.role_list,
        }),
        toggleActionsButton() {
            return this.selected.length ? false : true
        },
        customerLoading() {
            return this.customers ? this.customers.pending : true
        },
        customerItems() {
            return this.customers ? this.customers.payload : []
        },
        userLoading() {
            return this.payload ? this.payload.pending : true
        },
        roleLoading() {
            return this.roles ? this.roles.pending : true
        },
        roleItems() {
            return this.roles ? this.roles.payload : []
        },
        saveButtonState() {
            if (this.selected.length <= 1) {
                return this.editedItem['profile']['name'] &&
                    this.editedItem['profile']['email'] &&
                    !_isEmpty(this.editedItem['role'])
                    ? false
                    : true
            } else {
                return _isEmpty(this.editedItem['role']) ? true : false
            }
        },
    },
    methods: {
        showEditDialog(new_item = false) {
            if (new_item) {
                this.new_item = true
                this.selected = []
                this.dialog_edit = true
            } else if (this.selected.length > 0) {
                this.new_item = false
                if (this.selected.length == 1) {
                    this.editedItem = Object.assign({}, this.selected[0])
                } else {
                    this.editedItem = { group_all: false, customer_group: [], profile: {}, customer: {}, role: {} }
                }
                this.password = ''
                this.dialog_edit = true
            }
        },
        showDeleteDialog() {
            this.dialog_delete = true
        },
        getUsers() {
            this.$store
                .dispatch('api/call', {
                    url: '/root/users',
                })
                .then((response) => {
                    for (var i = 0; i < this.payload.length; i++) {
                        if (typeof this.payload[i]['customer'] !== 'undefined') {
                            var customer = `
                            ${this.payload[i]['customer']['customer_name']} 
                            (${this.payload[i]['customer']['id']})
                            `
                            Vue.set(this.payload[i], 'customer_view', customer)
                        }

                        if (typeof this.payload[i]['customer_group'] !== 'undefined') {
                            if (typeof this.payload[i]['customer_group']['all'] !== 'undefined') {
                                this.payload[i]['group_all'] = true
                                this.payload[i]['customer_group'] = []
                                Vue.set(this.payload[i], 'customer_group_view', '#all')
                            } else {
                                let group = []
                                for (var c = 0; c < this.payload[i]['customer_group'].length; c++) {
                                    var result = this.customers.payload.filter((obj) => {
                                        return obj.id === this.payload[i]['customer_group'][c]
                                    })
                                    if (result.length > 0) {
                                        group.push(result[0].customer_name);
                                    } else {
                                        console.warn("Error processing customer group. Skipping.");
                                    }
                                }
                                Vue.set(this.payload[i], 'customer_group_view', group)
                            }
                        }
                    }
                })
        },
        clickSelect(item) {
            const index = this.selected.findIndex((v) => v.id === item.id)
            if (index !== -1) {
                this.selected.splice(index, 1)
            } else {
                this.selected.push(item)
            }
        },
        save() {
            const data = {
                name: this.editedItem['profile']['name'],
                email: this.editedItem['profile']['email'],
                role: this.editedItem['role']['id'],
                credits: parseInt(this.editedItem['profile']['credits']),
            }

            if (typeof this.editedItem['customer'] !== 'undefined') {
                data['customer'] = this.editedItem['customer']['id']
            }

            if (this.editedItem['group_all'] == true) {
                data['customer_group'] = { all: true }
            } else if (typeof this.editedItem['customer_group'] !== 'undefined') {
                if (this.editedItem['customer_group'].length > 0) {
                    data['customer_group'] = this.editedItem['customer_group']
                } else {
                    data['customer_group'] = []
                }
            } else {
                data['customer_group'] = []
            }

            if (this.password.length >= 8) {
                data['password'] = this.password
            }

            if ('is_otp_enabled' in this.editedItem.profile && !this.editedItem.profile.is_otp_enabled) {
                data['remove_otp'] = true
            }

            if (this.new_item) {
                this.$store
                    .dispatch('api/call', {
                        name: 'user_add',
                        method: 'post',
                        url: '/root/users',
                        data: { ...data },
                    })
                    .then((response) => {
                        this.getUsers()
                    })
            } else if (this.selected.length > 0) {
                this.$store
                    .dispatch('api/call', {
                        name: 'user_edit',
                        method: 'put',
                        url: '/root/users',
                        data: {
                            ids: _map(this.selected, 'id'),
                            ...data,
                        },
                    })
                    .then((response) => {
                        this.selected = []
                        this.getUsers()
                    })
            }
            this.close()
        },
        delete_user() {
            if (this.selected.length > 0) {
                this.$store
                    .dispatch('api/call', {
                        name: 'user_edit',
                        method: 'put',
                        url: '/root/users',
                        data: {
                            ids: _map(this.selected, 'id'),
                            role: 1,
                        },
                    })
                    .then((response) => {
                        this.selected = []
                        this.getUsers()
                    })
            }
            this.dialog_delete = false
        },
        reset_user() {
            if (this.selected.length == 1) {
                this.$store
                    .dispatch('api/call', {
                        name: 'user_edit',
                        method: 'put',
                        url: '/root/users',
                        data: {
                            ids: _map(this.selected, 'id'),
                            reset_authentication: true,
                        },
                    })
                    .then((response) => {
                        this.selected = []
                        this.getUsers()
                    })
            }
            this.close()
        },
        close() {
            this.dialog_edit = false
            this.dialog_reset = false
            setTimeout(() => {
                this.new_item = false
                this.editedItem = { group_all: false, customer_group: [], profile: {}, customer: {}, role: {} }
                this.password = ''
            }, 300)
        },
    },
    created() {
        this.$store
            .dispatch('api/call', {
                name: 'customer_list',
                url: '/root/customers',
            })
            .then((response) => {
                this.customers.payload.sort((a, b) => a.customer_name.localeCompare(b.customer_name))

                for (var i = 0; i < this.customers.payload.length; i++) {
                    this.customers.payload[i]['customer_name'] += ` (${this.customers.payload[i]['id']})`
                }

                this.getUsers()
            })
        this.$store
            .dispatch('api/call', {
                name: 'role_list',
                url: '/root/roles',
            })
            .then((response) => {
                this.roles.payload.sort((a, b) => a.name.localeCompare(b.name))
            })
    },
}
</script>