<template>
  <div>
    <template v-if="view_list">
      <h1 class="mb-4">Views</h1>
      <div
        class="mb-1"
        v-for="(view, index) in views"
        :key="index"
      >
        <v-icon left>
          mdi-table
        </v-icon>
        <a
          href="#"
          @click="showView(view)"
          class="text-decoration-none"
        >{{view}}</a>
      </div>
    </template>

    <template v-else>
      <h1 class="">Views</h1>
      <h3 class="mb-4">{{selected_view}}</h3>
      <v-btn
        dark
        small
        color="primary"
        class="mb-4"
        @click="view_list = true"
      >
        <v-icon
          left
          dark
        >
          mdi-arrow-left-bold
        </v-icon>
        Return to list
      </v-btn>
      <v-toolbar
        dense
        dark
      >
        <v-text-field
          name="searchfield"
          prepend-icon="mdi-telescope"
          v-model="search"
          clearable
          hide-details
        >
        </v-text-field>
      </v-toolbar>

      <v-data-table
        :loading="pending"
        :headers="headers"
        :items="payload"
        :search="search"
        :mobile-breakpoint="0"
        :footer-props="{ itemsPerPageOptions: [10, 30, 50, 100, 500] }"
        no-results-text="Loading data..."
        class="elevation-1"
      >
      </v-data-table>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { access } from 'fs'
export default {
    data() {
        return {
            view_list: true,
            selected_view: null,
            search: null,
            views: [],
        }
    },
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
        }),
        headers() {
            let return_headers = []
            if (this.success) {
                for (let column in this.payload[0]) {
                    return_headers.push({ text: column, value: column })
                }
                return return_headers
            }
        },
    },
    methods: {
        showView(name) {
            this.selected_view = name
            this.view_list = false

            this.$store
                .dispatch('api/call', {
                    url: '/root/views',
                    params: {
                        name: name,
                    },
                })
                .then((response) => {
                    this.selected_view = name
                    this.view_list = false
                })
        },
    },
    created() {
        this.$store
            .dispatch('api/call', {
                name: 'views_list',
                url: '/root/views',
            })
            .then((response) => {
                this.views = response.data.payload
            })
    },
}
</script>