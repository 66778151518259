<template>
  <div>
    <v-dialog
      v-model="dialog_iccids.show"
      max-width="400px"
      scrollable
    >
      <v-card>
        <v-card-title>
          <v-icon class="mr-1">mdi-sim</v-icon> {{ dialog_iccids.title }} ICCIDs
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
          {{ dialog_iccids.list }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog_iccids.show = false"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-form
      ref="form"
      v-model="valid"
    >
      <v-dialog
        v-model="dialog_edit"
        max-width="500"
      >
        <v-card>
          <v-card-title>
            <span
              class="headline"
              v-if="new_item"
            >Create New Trigger</span>
            <span
              class="headline"
              v-else
            >Edit Trigger</span>
          </v-card-title>
          <v-card-text>

            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedItem.name"
                    :rules="[rules.required]"
                    label="Name"
                    placeholder="My Trigger"
                    persistent-hint
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <p>The following iccids will be monitored and trigger an alert when the data usage of a simcard exceeds the data usage value.</p>
                  <v-switch
                    v-model="editedItem.all_iccids"
                    label="Monitor all simcards in your account"
                    @change="validateForm()"
                  ></v-switch>
                  <v-textarea
                    v-model="editedItem.iccids"
                    :rules="[rules.required_if]"
                    outlined
                    :disabled="editedItem.all_iccids == true"
                    placeholder="8931234567891234567, 8931234567891234568, 8931234567891234569, ..."
                  ></v-textarea>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedItem.usage"
                    :rules="[rules.required, rules.min]"
                    prepend-icon="mdi-chart-bar"
                    label="Data Usage (MB)"
                    placeholder="100"
                    hint="This trigger will activate when data usage exceeds this value."
                    persistent-hint
                    class="mt-n2"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedItem.email"
                    :rules="[rules.required, rules.email]"
                    prepend-icon="mdi-email"
                    label="Email"
                    placeholder="support@company.com"
                    hint="Alerts will be sent to this email address."
                    persistent-hint
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-switch
                    v-model="editedItem.deactivate"
                    label="Deactivate triggered simcards"
                    hint="Deactivated simcards will reactivate on the first day of the month."
                    persistent-hint
                    class="mt-1"
                    @change="checkDeactivate()"
                  ></v-switch>
                </v-flex>

              </v-layout>
            </v-container>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary darken-1"
              text
              @click.native="close"
            >Close</v-btn>
            <v-btn
              :disabled="!valid"
              color="primary darken-1"
              text
              @click.native="save_trigger"
            >Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-dialog
      v-model="dialog_delete"
      max-width="300"
    >
      <v-card
        color="amber darken-3"
        dark
      >
        <v-card-title class="headline">
          <v-icon
            large
            left
          >
            mdi-alert
          </v-icon>Are you sure?
        </v-card-title>

        <v-card-text>
          You are about to delete this trigger. Are you sure you want to do this?
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1"></div>

          <v-btn
            text
            @click.native="dialog_delete = false"
          >
            Cancel
          </v-btn>

          <v-btn
            text
            @click.native="delete_trigger"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog_deactivate"
      max-width="300"
    >
      <v-card
        color="amber darken-3"
        dark
      >
        <v-card-title class="headline">
          <v-icon
            large
            left
          >
            mdi-alert
          </v-icon>Are you sure?
        </v-card-title>

        <v-card-text>
          This trigger has already deactivated simcards this month. If you change this setting now, these simcards will not be automatically reactivated next month. You can of course always reactivate them manually.
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1"></div>

          <v-btn
            text
            @click.native="dialog_deactivate = false"
          >
            I understand
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <h1 class="mb-4">Triggers</h1>
    <v-toolbar
      dense
      dark
    >
      <v-text-field
        name="searchfield"
        prepend-icon="mdi-file-search"
        v-model="search"
        clearable
        hide-details
      >
      </v-text-field>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
          text
          @click="showEditDialog(true)"
        >
          <v-icon left>mdi-alarm-plus</v-icon>New Trigger
        </v-btn>
        <v-menu offset-y>
          <template v-slot:[`activator`]="{ on }">
            <v-btn
              text
              v-on="on"
              :disabled="toggleActionsButton"
            >
              <v-icon left>mdi-ballot-outline</v-icon>
              Actions
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item-group color="primary">
              <v-list-item @click="showEditDialog()">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon class="mr-1">mdi-pencil</v-icon>
                    Edit Trigger
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="showDeleteDialog()">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon class="mr-1">mdi-alarm-off</v-icon>
                    Delete Trigger
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

        </v-menu>
      </v-toolbar-items>
    </v-toolbar>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="payload"
      :search="search"
      class="elevation-1"
      :mobile-breakpoint="0"
      :loading="pending"
      :footer-props="{ itemsPerPageOptions: [10, 30, 50] }"
      show-select
      single-select
    >
      <template v-slot:[`item.iccids`]="{ item }">

        <v-chip
          v-if="item.all_iccids == true"
          color="primary"
          text-color="white"
          small
        >
          <v-avatar left>
            <v-icon>mdi-account</v-icon>
          </v-avatar>
          All
        </v-chip>

        <span v-else>
          {{ item.iccids.length }} <v-icon
            color="primary"
            @click="showMonitored(item)"
          >mdi-text-box</v-icon>
        </span>
      </template>

      <template v-slot:[`item.triggered`]="{ item }">
        {{ item.triggered.length }} <v-icon
          color="primary"
          @click="showTriggered(item)"
        >mdi-text-box</v-icon>
      </template>

      <template v-slot:[`item.usage`]="{ item }">
        {{ item.usage }}
      </template>

      <template v-slot:[`item.deactivate`]="{ item }">
        <v-icon v-if="item.deactivate == 1">mdi-check-circle</v-icon>
      </template>

      <template v-slot:[`item.email`]="{ item }">
        {{ item.email}}
      </template>

      <v-alert
        slot="no-results"
        :value="true"
        color="info"
        icon="mdi-magnify-close"
        class="mt-4"
        dark
      >
        There are no results that match your search.
      </v-alert>
    </v-data-table>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import axios from 'axios'
import XLSX from 'xlsx'
import jsPDF from 'jspdf'

export default {
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
            total: (state) => state.calls.default.total,
        }),
        toggleActionsButton() {
            return this.selected.length ? false : true
        },
        email() {
            return this.$store.getters['session/email']
        },
    },
    data() {
        return {
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Monitored', value: 'iccids' },
                { text: 'Triggered', value: 'triggered' },
                { text: 'Data Usage (MB)', value: 'usage' },
                { text: 'Deactivate', value: 'deactivate' },
                { text: 'Email', value: 'email' },
            ],
            valid: true,
            selected: [],
            editedItem: {
                name: 'My Trigger',
                all_iccids: false,
                iccids: [],
                usage: 100,
                deactivate: false,
                email: this.$store.getters['session/email'],
            },
            new_item: false,
            search: null,
            dialog_iccids: { show: false, title: 'Monitored', list: [] },
            dialog_edit: false,
            dialog_delete: false,
            dialog_deactivate: false,
            rules: {
                required: (value) => !!value || 'Required.',
                // prettier-ignore
                required_if: (value) =>
                    (((value == null || value.length === 0) && this.editedItem.all_iccids == true) || value.length > 0) || 'Required.',
                min: (value) => value >= 0.001 || 'Minimum usage of 0.001 MB.',
                email: (value) => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            },
        }
    },
    methods: {
        getTriggers() {
            this.$store.dispatch('api/call', {
                url: '/triggers',
            })
        },
        validateForm() {
            this.$refs.form.validate()
        },
        checkDeactivate() {
            if (
                this.editedItem['deactivate'] == false &&
                this.selected[0].deactivate == true &&
                this.selected[0].triggered.length > 0
            ) {
                this.dialog_deactivate = true
            }
        },
        showMonitored(item) {
            this.dialog_iccids = {
                show: true,
                title: 'Monitored',
                list: item.iccids.join(', '),
            }
        },
        showTriggered(item) {
            this.dialog_iccids = {
                show: true,
                title: 'Triggered',
                list: item.triggered.join(', '),
            }
        },
        showEditDialog(new_item = false) {
            if (new_item) {
                this.new_item = true
                this.selected = []
                this.dialog_edit = true
                this.$refs.form.resetValidation()
            } else if (this.selected.length > 0) {
                this.new_item = false

                this.editedItem = {
                    name: this.selected[0]['name'],
                    all_iccids: this.selected[0]['all_iccids'],
                    iccids: this.selected[0]['iccids'].join(', '),
                    email: this.selected[0]['email'],
                    usage: this.selected[0]['usage'],
                    deactivate: this.selected[0]['deactivate'],
                }

                this.dialog_edit = true
            }
        },
        showDeleteDialog() {
            this.dialog_delete = true
        },
        close() {
            this.dialog_edit = false
            setTimeout(() => {
                this.new_item = false
                this.editedItem = {
                    name: 'My Trigger',
                    all_iccids: false,
                    iccids: [],
                    usage: 100,
                    deactivate: false,
                    email: this.$store.getters['session/email'],
                }
            }, 300)
        },
        save_trigger() {
            let data = {
                name: this.editedItem['name'],
                all_iccids: this.editedItem['all_iccids'],
                usage: this.editedItem['usage'],
                email: this.editedItem['email'],
                deactivate: this.editedItem['deactivate'],
            }

            if (data['all_iccids'] == false) {
                data['iccids'] = this.editedItem['iccids'].split(/\s*[\n, ]+\s*/)
            }

            if (this.new_item) {
                this.$store
                    .dispatch('api/call', {
                        name: 'trigger_create',
                        method: 'post',
                        url: '/triggers',
                        data: { ...data },
                    })
                    .then((response) => {
                        this.getTriggers()
                    })
            } else if (this.selected.length > 0) {
                this.$store
                    .dispatch('api/call', {
                        name: 'trigger_edit',
                        method: 'put',
                        url: '/triggers',
                        data: {
                            id: this.selected[0]['id'],
                            ...data,
                        },
                    })
                    .then((response) => {
                        this.selected = []
                        this.getTriggers()
                    })
            }
            this.close()
        },
        delete_trigger() {
            if (this.selected.length > 0) {
                this.$store
                    .dispatch('api/call', {
                        name: 'trigger_delete',
                        method: 'delete',
                        url: '/triggers',
                        data: {
                            id: this.selected[0]['id'],
                        },
                    })
                    .then((response) => {
                        this.selected = []
                        this.getTriggers()
                    })
            }
            this.dialog_delete = false
        },
    },
    created() {
        this.getTriggers()
    },
}
</script>