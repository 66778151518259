<template>
  <div>
    <v-snackbar v-model="snackbar">
      {{ snackbar_message }}
      <v-btn
        color="primary"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <h1 class="mb-4">Scan</h1>
    <v-toolbar
      dense
      dark
    >
      <v-text-field
        placeholder="ICCID number to scan"
        prepend-icon="mdi-magnify-scan"
        v-model="iccid"
        v-on:input="debounceFilter(iccid)"
        mask="####################"
        :loading="scanning"
        :readonly="scanning"
        clearable
        hide-details
        autofocus
      >
      </v-text-field>
      <v-spacer></v-spacer>
      <v-btn
        text
        :disabled="scanning"
        @click="refreshScan()"
      >
        <v-icon left>mdi-refresh</v-icon>
        Refresh
      </v-btn>
      <v-menu offset-y>
        <template v-slot:[`activator`]="{ on }">
          <v-btn
            text
            v-on="on"
            :disabled="scanning"
          >
            <v-icon left>mdi-ballot-outline</v-icon>
            Actions
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group color="primary">
            <v-list-item @click="sendCancelLocation()">
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon class="mr-1">mdi-crosshairs-off</v-icon>
                  Send Cancel Location
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-toolbar>

    <grid-layout
      v-if="show_grid"
      :layout.sync="items"
      :col-num="3"
      :row-height="12"
      :is-draggable="true"
      :is-resizable="true"
      :is-mirrored="false"
      :responsive="true"
      :breakpoints="{ lg: 1264, md: 960, sm: 840, xs: 600, xxs: 0 }"
      :cols="{ lg: 4, md: 3, sm: 3, xs: 2, xxs: 1 }"
      :vertical-compact="true"
      :margin="[10, 10]"
      :use-css-transforms="true"
    >
      <grid-item
        v-for="item in items"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :minH="item.minH"
        :maxH="item.maxH"
        :i="item.i"
        :key="item.i"
        :isResizable="item.isResizable"
        style="background-color:#D6D6D6"
        dragAllowFrom=".handle"
      >
        <v-card
          outlined
          tile
          height="100%"
          style="overflow: hidden;"
        >
          <v-toolbar
            dense
            dark
            color="primary"
          >
            <v-toolbar-title class="subtitle-1">
              <v-icon left>{{item.icon}}</v-icon>{{item.title}}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
              icon
              @click="refreshScan(item.i)"
            >
              <v-icon>mdi-refresh-circle</v-icon>
            </v-btn>

            <v-icon class="handle">mdi-drag-variant</v-icon>
          </v-toolbar>

          <v-card-text class="font-weight-light">
            <v-progress-linear
              height="10"
              indeterminate
              v-if="item.loading"
            ></v-progress-linear>
            <template v-else-if="isNotEmpty(item.content)">
              <template v-for="(show, key) in item.show">
                <div
                  v-if="show.template == 'location'"
                  :key="key"
                >
                  <iframe
                    style="border:0; position:absolute; height: calc(100% - 80px); width: calc(100% - 32px);"
                    :src="`https://www.google.com/maps/embed/v1/search?key=AIzaSyCl6HPOay5bXNFHFrIcXBwlig3-wKL-YNU&q=${item.content.lat},${item.content.lon}&zoom=13`"
                  ></iframe>
                </div>
                <div
                  v-else-if="show.template == 'ping'"
                  :key="key"
                >
                  <v-sheet
                    class="mx-auto"
                    color="secondary"
                  >
                    <v-sparkline
                      :value="item.content.responses"
                      color="white"
                      line-width="2"
                      padding="26"
                      label-size="8"
                      auto-draw
                    >
                      <template v-slot:label="item">
                        {{item.value}} ms
                      </template>
                    </v-sparkline>
                  </v-sheet>
                  <v-row
                    justify="space-around"
                    class="mt-5"
                  >
                    <v-sheet
                      align="center"
                      height="60"
                      width="80"
                      tile
                    >
                      <v-chip
                        color="primary"
                        class="white--text font-weight-medium"
                        small
                      >
                        {{item.content.avg}} ms
                      </v-chip>
                      <span class="font-weight-medium">Average</span>
                    </v-sheet>
                    <v-sheet
                      align="center"
                      height="60"
                      width="80"
                      tile
                    >
                      <v-chip
                        color="primary"
                        class="white--text font-weight-medium"
                        small
                      >
                        {{item.content.min}} ms
                      </v-chip>
                      <span class="font-weight-medium">Minimum</span>
                    </v-sheet>
                    <v-sheet
                      align="center"
                      height="60"
                      width="80"
                      tile
                    >
                      <v-chip
                        color="primary"
                        class="white--text font-weight-medium"
                        small
                      >
                        {{item.content.max}} ms
                      </v-chip>
                      <span class="font-weight-medium">Maximum</span>
                    </v-sheet>
                  </v-row>
                </div>
                <v-list
                  dense
                  v-else
                  :key="key"
                >
                  <v-list-item
                    v-if="typeof item.content[key] !== 'object'"
                    :key="key"
                  >
                    <v-list-item-content
                      class="font-weight-regular"
                      style="max-width:94px;"
                    >{{ show.label ? show.label : key | capitalize }}</v-list-item-content>
                    <v-list-item-content class="align-end">
                      <span v-if="item.content[key] == 'unknown'">
                        Unknown
                      </span>
                      <span v-else-if="show.type == 'bool'">
                        <v-chip
                          x-small
                          text-color="white"
                          :color="item.content[key] ? 'green darken-1' : 'grey darken-1'"
                        >
                          <span class="font-weight-bold">{{ item.content[key] ? 'Yes': 'No' }}</span>
                        </v-chip>
                      </span>
                      <span v-else-if="show.type == 'image'">
                        <img
                          :src="getImgUrl(item.content[key])"
                          style="max-height:18px;"
                        />
                      </span>
                      <span v-else-if="show.type == 'button'">
                        <v-btn
                          x-small
                          color="primary"
                          @click="openWebsite(show.button.url, item.content)"
                        >
                          {{ show.button.label }}
                        </v-btn>
                      </span>
                      <span v-else-if="show.type == 'uppercase'">
                        {{ item.content[key].toUpperCase() }}{{ show.append }}
                      </span>
                      <span v-else-if="show.type == 'datetime'">
                        {{ item.content[key] | datetime }}{{ show.append }}
                      </span>
                      <span v-else-if="show.math">
                        {{ item.content[key] | do_math(show.math) }}{{ show.append }}
                      </span>
                      <span v-else>{{ item.content[key] }}{{ show.append }}</span>
                    </v-list-item-content>
                  </v-list-item>
                  <div
                    v-if="show.spacer"
                    class="mb-5"
                  ></div>
                </v-list>
              </template>
            </template>
            <template v-else>
              {{ item.empty }}
            </template>
          </v-card-text>

        </v-card>
      </grid-item>
    </grid-layout>

  </div>

</template>


<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import VueGridLayout from 'vue-grid-layout'

export default {
    components: {
        GridLayout: VueGridLayout.GridLayout,
        GridItem: VueGridLayout.GridItem,
    },
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
        }),
        ...mapState('session', {
            stored(state) {
                return state.storage.scan || { headers: [] }
            },
        }),
        binding() {
            const binding = {}
            if (this.$vuetify.breakpoint.smAndDown) binding.column = true
            return binding
        },
    },
    data() {
        return {
            iccid: '',
            scanning: false,
            show_grid: false,
            columnsDivided: [2, 3, 1],
            snackbar: false,
            snackbar_message: '',
            items: [
                {
                    x: 0,
                    y: 0,
                    w: 1,
                    h: 5,
                    minH: 5,
                    i: 'customer',
                    title: 'Customer',
                    icon: 'mdi-account',
                    loading: true,
                    content: {},
                    show: { name: {} },
                    empty: 'No customer was found.',
                },
                {
                    x: 0,
                    y: 1,
                    w: 1,
                    h: 15,
                    minH: 15,
                    i: 'general',
                    title: 'General',
                    icon: 'mdi-sim',
                    loading: true,
                    content: {},
                    show: {
                        operator: { type: 'image' },
                        status: {},
                        apn: { label: 'APN', spacer: true },
                        iccid: { label: 'ICCID' },
                        imsi: { label: 'IMSI' },
                        msisdn: { label: 'MSISDN', spacer: true },
                        data_usage: { label: 'Data Usage', append: ' MB', math: ['/', 1048576, 3] },
                        sms_usage: { label: 'SMS Usage', append: ' messages' },
                        voice_usage: { label: 'Voice Usage', append: ' minutes', math: [':'] },
                    },
                    empty: 'General information could not be found.',
                },
                {
                    x: 1,
                    y: 0,
                    w: 1,
                    h: 15,
                    minH: 15,
                    i: 'network',
                    title: 'Network',
                    icon: 'mdi-satellite-uplink',
                    loading: true,
                    content: {},
                    show: {
                        name: {},
                        country: { spacer: true },
                        mcc: { label: 'MCC' },
                        mnc: { label: 'MNC' },
                        lac: { label: 'LAC' },
                        cid: { label: 'CID' },
                        radio_type: { label: 'Radio Type', type: 'uppercase' },
                        lat: { label: 'Latitude' },
                        lon: { label: 'Longitude' },
                        button: {
                            label: 'OpenCelliD',
                            type: 'button',
                            button: {
                                label: 'Show on website',
                                url: 'https://opencellid.org/#zoom=16&lat=${content.lat}&lon=${content.lon}',
                            },
                        },
                    },
                    empty: 'Network could not be found.',
                },
                {
                    x: 0,
                    y: 2,
                    w: 1,
                    h: 9,
                    minH: 9,
                    i: 'complan',
                    title: 'Communication Plan',
                    icon: 'mdi-cellphone-information',
                    loading: true,
                    content: {},
                    show: {
                        name: {},
                        region: {},
                        data: { type: 'bool' },
                        sms: { type: 'bool' },
                        voice: { type: 'bool' },
                    },
                    empty: 'Communication plan could not be found.',
                },
                {
                    x: 1,
                    y: 1,
                    w: 1,
                    h: 12,
                    minH: 12,
                    i: 'last_session',
                    title: 'Last Session',
                    icon: 'mdi-history',
                    loading: true,
                    content: {},
                    show: {
                        session_start: { label: 'Start (UTC)' },
                        session_stop: { label: 'Stop (UTC)' },
                        intended_ip: { label: 'Intended IP' },
                        actual_ip: { label: 'Actual IP' },
                        upload: { label: 'Upload' },
                        download: { label: 'Download', spacer: true },
                        in_session: { label: 'In Session', type: 'bool' },
                    },
                    empty: 'Last session could not be found.',
                },
                {
                    x: 2,
                    y: 0,
                    w: 1,
                    h: 15,
                    i: 'location',
                    title: 'Location',
                    icon: 'mdi-map-marker-radius',
                    loading: true,
                    content: {},
                    show: { custom: { template: 'location' } },
                    empty: 'Location could not be found.',
                },
                {
                    x: 2,
                    y: 1,
                    w: 1,
                    h: 12,
                    i: 'ping',
                    title: 'Ping',
                    icon: 'mdi-rotate-3d-variant',
                    loading: true,
                    content: {},
                    show: { custom: { template: 'ping' } },
                    empty: 'Request timeout.',
                },
            ],
        }
    },
    timers: {
        checkScanStatus: { time: 1000, repeat: true },
    },
    filters: {
        capitalize: function (value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
    },
    methods: {
        itemCountInRow: function (index) {
            var from = this.columnsDivided.slice(0, index - 1).reduce((a, b) => a + b, 0)
            var to = from + this.columnsDivided[index - 1]
            return Object.values(this.items).slice(from, to)
        },
        isNotEmpty(obj) {
            return !_isEmpty(obj)
        },
        resetItems() {
            for (let item of Object.values(this.items)) {
                item.loading = true
                item.content = {}
            }
        },
        getImgUrl(img) {
            const operators = { KPOM: 'kpn', TPOM: 'telenor', '2POM': 'tele2', TMPOM: 't-mobile', arkessa: 'arkessa' }
            return '/img/' + img + '.svg'
        },
        openWebsite(url, content) {
            var compiled = _.template(url)
            window.open(compiled({ content: content }), '_blank')
        },
        refreshScan(category = null) {
            if (category) {
                this.startScan(this.iccid, [category])
            } else {
                this.startScan(this.iccid, 'all')
            }
        },
        startScan(iccid, refresh = null) {
            this.show_grid = true
            this.iccid = iccid

            let data = { iccid: iccid }
            if (refresh) {
                data.refresh = refresh

                if (Array.isArray(refresh)) {
                    let item = this.items.find((x) => x.i === refresh[0])
                    item.loading = true
                    item.content = {}
                } else {
                    this.resetItems()
                }
            } else {
                this.resetItems()
            }

            this.$store
                .dispatch('api/call', {
                    method: 'post',
                    url: '/diagnostics/scan',
                    data: data,
                })
                .then(
                    (response) => {
                        this.scanning = true
                        this.$timer.start('checkScanStatus')
                    },
                    (error) => {
                        console.log('error')
                        this.scanning = false
                    }
                )
        },
        checkScanStatus() {
            this.$store
                .dispatch('api/call', {
                    method: 'get',
                    url: '/diagnostics/scan/' + this.iccid,
                })
                .then(
                    (response) => {
                        if (typeof response.data.payload !== 'undefined') {
                            for (var key in response.data.payload) {
                                let item = this.items.find((x) => x.i === key)

                                if (Object.entries(item.content).length === 0 && item.content.constructor === Object) {
                                    let index = this.items.indexOf(item)
                                    this.items[index].loading = false
                                    this.items[index].content = response.data.payload[key]
                                }
                            }

                            if (this.items.length == Object.keys(response.data.payload).length) {
                                this.scanning = false
                                this.$timer.stop('checkScanStatus')
                            }
                        }
                    },
                    (error) => {
                        this.$timer.stop('checkScanStatus')
                    }
                )
        },
        sendCancelLocation() {
            this.$store
                .dispatch('api/call', {
                    name: 'cancel_location',
                    method: 'post',
                    url: '/actions/cancel_location',
                    data: {
                        iccids: [this.iccid],
                    },
                })
                .then(
                    (response) => {
                        this.snackbar = true
                        this.snackbar_message = 'Cancel location has successfully been send.'
                    },
                    (error) => {}
                )
        },
        debounceFilter: _debounce(function (value) {
            if (value) {
                this.startScan(this.iccid)
            }
        }, 400),
    },

    created() {
        if (typeof this.$route.params.iccid !== 'undefined') {
            this.startScan(this.$route.params.iccid)
        }
    },
}
</script>

<style scoped>
.v-list--dense {
    padding: 0;
    margin: 0;
    background: rgba(255, 255, 255, 0.5);
}
.v-list--dense .v-list-item,
.v-list--dense .v-list-item .v-list-item__content {
    padding: 0;
    margin: 0;
}
.v-list--dense .v-list-item {
    min-height: 22px;
}
</style>
