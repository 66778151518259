<template>
    <div>
        <v-dialog v-model="dialog" max-width="800" persistent>
            <v-card>
                <v-toolbar color="primary" dark>
                    <v-card-title>Order Details</v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-card-text>
                        <v-list-item-group>
                            <v-list-item inactive v-for="(item, i) in selected" :key="i">
                                <v-list-item-content>
                                    <v-list-item-title
                                        v-text="`Customer: ${item.product.customer.customer_name}`"
                                    ></v-list-item-title>
                                    <v-list-item-subtitle
                                        v-text="`Customer ID: ${item.product.customer.id}`"
                                    ></v-list-item-subtitle>
                                    <v-list-item-subtitle
                                        v-text="`Product Name: ${item.product.name}`"
                                    ></v-list-item-subtitle>
                                    <v-list-item-subtitle
                                        v-text="`Creation Date: ${item.created}`"
                                    ></v-list-item-subtitle>
                                    <v-list-item-subtitle
                                        v-text="`Product Price: €${item.product.price}`"
                                    ></v-list-item-subtitle>
                                    <v-list-item-subtitle
                                        v-text="`Total Price: €${item.product.price * item.quantity}`"
                                    ></v-list-item-subtitle>
                                    <v-list-item-subtitle
                                        v-text="`Ordered Quantity: ${item.quantity}`"
                                    ></v-list-item-subtitle>
                                    <v-list-item-subtitle
                                        v-text="`Charge Type: ${getRecurring(item.product.product_type.recuring)}`"
                                    ></v-list-item-subtitle>
                                    <v-list-item-subtitle
                                        v-text="`Order processed on: ${item.processed}`"
                                    ></v-list-item-subtitle>
                                    <v-list-item-subtitle
                                        v-text="`Order processed by: ${item.user.profile.name}`"
                                    ></v-list-item-subtitle>
                                    <v-list-item-subtitle v-text="`Remark: ${item.remark}`"></v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-card-text>
                </v-card-text>
            </v-card>
        </v-dialog>
        <h1 class="mb-4">Orders Overview</h1>
        <v-toolbar dense dark>
            <v-text-field
                label="Search Order..."
                name="searchfield"
                prepend-icon="mdi-text-box-search"
                v-model="search"
                clearable
                hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-menu offset-y>
                    <template v-slot:[`activator`]="{ on }">
                        <v-btn text v-on="on" :disabled="toggleActionsButton">
                            <v-icon left>mdi-ballot-outline</v-icon>
                            Actions
                        </v-btn>
                    </template>
                    <v-list desne>
                        <v-list-item-group>
                            <v-list-item v-if="selected.length == 1" @click="showEditDialog()">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-note-text-outline</v-icon>
                                        Order Details
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table
            v-model="selected"
            :loading="pending"
            :headers="headers"
            :items="orders.payload"
            :search="search"
            :mobile-breakpoint="0"
            :footer-props="{ itemsPerPageOptions: [50, 100, 500] }"
            no-results-text="Loading data..."
            class="elevation-1"
            item-key="id"
            sort-by="created"
            sort-desc
            show-select
            single-select
            @click:row="clickSelect"
        >
            <template v-slot:[`item.product.product_type.recurring`]="{ item }">
                {{ getRecurring(item.product.product_type.recurring) }}
            </template>
            <template v-slot:[`item.created`]="{ item }">
                {{ item.created | date }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
            orders: (state) => state.calls.orders_list,
        }),
        toggleActionsButton() {
            return this.selected.length ? false : true
        },
    },
    data() {
        return {
            search: null,
            selected: [],
            new_item: false,
            dialog: false,
            headers: [
                { text: 'Customer', value: 'product.customer.customer_name' },
                { text: 'Created', value: 'created' },
                { text: 'Product', value: 'product.name' },
                { text: 'Product Type', value: 'product.product_type.recurring' },
                { text: 'Quantity', value: 'quantity' },
                { text: 'Price', value: 'product.price' },
                { text: 'Processed By', value: 'user.profile.name' },
            ],
        }
    },
    methods: {
        close() {
            this.selected = []
            this.dialog = false
        },
        clickSelect(item) {
            Vue.set(this.selected, 0, item)
        },
        getRecurring(recurring) {
            return recurring ? 'Recurring' : 'One-Off (Setup)'
        },
        showEditDialog(new_item = false) {
            if (new_item) {
                this.new_item = true
                this.dialog = true
            } else {
                this.new_item = false
                this.dialog = true
            }
        },
    },
    created() {
        this.$store
            .dispatch('api/call', {
                name: 'orders_list',
                url: '/root/products/order',
            })
            .then((respone) => {
                this.orders.payload.sort((a, b) =>
                    a.product.customer.customer_name.localeCompare(b.product.customer.customer_name)
                )
            })
    },
}
</script>
