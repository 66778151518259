<template>
    <v-app>
        <v-navigation-drawer app clipped v-model="drawer" fixed width="230" mobile-breakpoint="1100">
            <v-list dense nav class="mb-n4">
                <v-list-item dense>
                    <v-list-item-avatar>
                        <img :src="avatar" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-list dense nav>
                <template v-for="(item, i) in menu">
                    <v-list-group
                        :key="i"
                        :prepend-icon="item.meta.icon"
                        no-action
                        v-if="has(item, 'children')"
                        :group="item.path | strip_path"
                    >
                        <template v-slot:activator>
                            <div class="d-flex justify-space-between align-start" style="width: 100%;">
                                <div v-if="item.children.some(subitem => subitem.meta.message > 0)">
                                    <v-badge color="red" dot>
                                        <v-list-item-title v-text="item.meta.text"></v-list-item-title>
                                    </v-badge>
                                </div>
                                <div v-else>
                                    <v-list-item-title v-text="item.meta.text"></v-list-item-title>
                                </div>
                            </div>
                        </template>
                        <template v-for="(subitem, i) in item.children">
                            <v-list-group :key="i" no-action sub-group v-if="has(subitem, 'children')">
                                <template v-slot:activator>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="subitem.meta.text"></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                                <v-list-item
                                    v-for="(subsubitem, i) in subitem.children"
                                    :key="i"
                                    :to="subsubitem.path | strip_path"
                                >
                                    <v-list-item-title v-text="subsubitem.meta.text"></v-list-item-title>
                                    
                                    <v-list-item-icon>
                                        <v-icon v-text="subsubitem.meta.icon"></v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list-group>
                            <v-list-item :key="i" :to="subitem.path | strip_path" v-else>
                                
                                <v-list-item-title v-text="subitem.meta.text"></v-list-item-title>
                                <v-list-item-icon v-if="subitem.meta.message > 0">
                                    <v-badge color="red" dot>
                                        <v-icon v-text="subitem.meta.icon"></v-icon>
                                    </v-badge>
                                </v-list-item-icon>
                                <v-list-item-icon v-else>
                                        <v-icon v-text="subitem.meta.icon"></v-icon>
                                </v-list-item-icon>
                            </v-list-item>
                        </template>
                    </v-list-group>
                    
                    <v-list-item color="primary" exact :to="item.path | strip_path" :key="i" v-else>
                        <v-list-item-icon>
                            <v-icon v-text="item.meta.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-title v-text="item.meta.text"></v-list-item-title>
                    </v-list-item>

                    <v-divider class="mb-2 mt-2" v-if="item.meta.divider" :key="i + menu.length"></v-divider>
                </template>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar :color="$cfg.theme.app_bar.background" app fixed clipped-left dense>
            <v-app-bar-nav-icon :class="$cfg.theme.app_bar.text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

            <v-card class="d-flex align-center ml-1" :color="$cfg.theme.app_bar.background" outlined tile>
                <img :src="logo" :width="$cfg.theme.logo.width" :height="$cfg.theme.logo.height" />
                <span :class="'ml-2 subtitle-1 font-weight-medium text-uppercase ' + $cfg.theme.app_bar.text">{{
                    $cfg.bar_title
                }}</span>
            </v-card>
            <div class="flex-grow-1"></div>
            <v-btn :class="$cfg.theme.app_bar.text" text to="/support">
                <v-icon left dark>mdi-lifebuoy</v-icon>Support
            </v-btn>
            <v-btn :class="$cfg.theme.app_bar.text" text to="/logout">
                <v-icon left dark>mdi-exit-to-app</v-icon>Logout
            </v-btn>
        </v-app-bar>
        <main>
            <v-main>
                <v-container fluid>
                    <router-view></router-view>
                </v-container>
            </v-main>
        </main>
    </v-app>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState('api', {
                pending: (state) => state.calls.default.pending,
                success: (state) => state.calls.default.success,
                message: (state) => state.calls.default.message,
                payload: (state) => state.calls.default.payload,
                // This is for the new Web Orders menu item
                // weborders: (state) => state.calls.weborders_list,
            }),
        fullAccess() {
            return this.$store.getters['session/rules'].views == 'all'
        },
        menu() {
            let items = this.$router.options.routes
                .find((item) => item.path === '/')
                .children.filter((item) => !item.meta.hidden)
            
            // This is for the new Web Orders menu item
            // const updateWebOrdersCount = (menuItems) => {
            //     menuItems.forEach(item => {
            //         if (item.name === 'web_orders') {
            //             item.meta.message = this.webOrdersCount;
            //         }
            //         if (item.children) {
            //             updateWebOrdersCount(item.children);
            //         }
            //     });
            // };

            // updateWebOrdersCount(items);

            let views = this.$store.getters['session/rules'].views

            if (typeof views !== 'undefined') {
                if (views == 'all') {
                    return items
                } else {
                    return this.recursiveMenu(items, views)
                }
            }
            return {}
        },
        name() {
            return this.$store.getters['session/name']
        },
        email() {
            return this.$store.getters['session/email']
        },
        avatar() {
            if (this.$store.getters['session/avatar']) {
                return '/img/avatars/' + this.$store.getters['session/avatar']
            } else {
                return '/img/avatars/avatar_45.svg'
            }
        },
        logo() {
            return `/themes/${this.$cfg.theme.folder}/${this.$cfg.theme.logo.filename}`
        },
    },
    filters: {
        strip_path: function (value) {
            return value.split('/:')[0]
        },
    },
    methods: {
        recursiveMenu(items, views) {
            let menu = []
            for (let [key, value] of Object.entries(items)) {
                if (value.hasOwnProperty('children')) {
                    value.children = this.recursiveMenu(value.children, views)
                }

                if (views.includes(value.name)) {
                    menu.push(value)
                }
            }
            return menu
        },
        // This is for the new Web Orders menu item
        // getWebOrders() {
        //     if (!this.fullAccess) {
        //         return;
        //     }
        //     this.$store
        //         .dispatch('api/call', {
        //             name: 'weborders_list',
        //             url: '/root/weborder',
        //         })
        //         .then((response) => {
        //             this.webOrdersCount = this.weborders.payload.length;
        //         })
        //         .catch((error) => {
        //             this.webOrdersCount = 0;
        //         })
        // }
    },
    mounted() {
        // This is for the new Web Orders menu item
        // this.getWebOrders();
    },
    data() {
        return {
            has: _has,
            drawer: true,
            // This is for the new Web Orders menu item
            // webOrdersCount: 0,
        }
    },
}
</script>
