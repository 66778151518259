<template>
  <div>
    <v-dialog
      v-model="dialog_json"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Message</span>
        </v-card-title>
        <v-card-text>
          <json-view
            :data="message_json"
            rootKey="Message"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="dialog_json = false"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <h1 class="mb-4">Messages</h1>

    <v-toolbar
      dense
      dark
    >
      <v-text-field
        name="searchfield"
        prepend-icon="mdi-table-search"
        v-model="search_global"
        v-on:input="debounceFilter(search_global)"
        :disabled="header_filters"
        clearable
        hide-details
      >
        <template v-slot:append-outer>
          <v-dialog
            v-model="dialog_help"
            width="500"
          >
            <template v-slot:[`activator`]="{ on }">
              <v-icon v-on="on">mdi-help-circle-outline</v-icon>
            </template>
            <v-card>
              <v-card-title>
                Filter Help
              </v-card-title>

              <v-card-text>
                <div class="font-weight-black">Contains <code>1234</code></div>
                <div class="font-weight-thin mb-2">
                  Default search method. Find values that include your search string.
                </div>
                <div class="font-weight-black">Starts With <code>1234*</code></div>
                <div class="font-weight-thin mb-2">
                  Use when you know what your value starts with, but not the exact text.
                </div>
                <div class="font-weight-black">Ends With <code>*1234</code></div>
                <div class="font-weight-thin mb-2">
                  Use when you know what your value ends with, but not the exact text.
                </div>
                <div class="font-weight-black">Equals <code>=1234</code></div>
                <div class="font-weight-thin mb-2">
                  Use for an exact match.
                </div>
                <div class="font-weight-black">Not Equal To <code>!=1234</code></div>
                <div class="font-weight-thin mb-2">
                  Shows results that don’t have the value you enter.
                </div>
                <div class="font-weight-black">In <code>(123, 456, 789, 1011)</code></div>
                <div class="font-weight-thin mb-2">
                  Find results that include one or more of the values you enter.
                </div>
                <div class="font-weight-black">Not In <code>!(123, 456, 789, 1011)</code></div>
                <div class="font-weight-thin mb-2">
                  Find results that do not contain any values that match the ones entered.
                </div>
                <div class="font-weight-black">Between <code>[1, 100]</code></div>
                <div class="font-weight-thin mb-2">
                  Filter on a range of values.
                </div>
                <div class="font-weight-black">Less Than <code>&lt;100</code></div>
                <div class="font-weight-thin mb-2">
                  Find entries that are less than the value you enter.
                </div>
                <div class="font-weight-black">Greater Than <code>&gt;1234</code></div>
                <div class="font-weight-thin mb-2">
                  Find entries that exceed the value you enter.
                </div>
                <div class="font-weight-black">Less Or Equal <code>&lt;=100</code></div>
                <div class="font-weight-thin mb-2">
                  Use for results that match or are less than the value you enter.
                </div>
                <div class="font-weight-black">Greater Or Equal <code>&gt;=1234</code></div>
                <div class="font-weight-thin mb-2">
                  Use for results that match or exceed the value you enter.
                </div>
                <div class="font-weight-black">Equals or Not Equal To <code>=null / !=null</code></div>
                <div class="font-weight-thin mb-2">
                  Find entries where the value is equal or not equal to null.
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="dialog_help = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-text-field>
      <v-spacer></v-spacer>

      <v-toolbar-items>
        <v-btn
          text
          @click="header_filters = !header_filters"
          :disabled="!!search_global"
        >
          <v-icon left>mdi-filter</v-icon>Filters
        </v-btn>
        <v-dialog
          v-model="dialog_columns"
          scrollable
          max-width="350px"
          @input="v => v || dirtyStorage()"
        >
          <template v-slot:[`activator`]="{ on }">
            <v-btn
              text
              v-on="on"
            >
              <v-icon left>mdi-view-column</v-icon>Columns
            </v-btn>
          </template>
          <v-card flat>
            <v-card-title>Show & Hide Columns</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-switch
                v-for="column in selectableColumns"
                :key="column.value"
                v-model="column.selected"
                color="primary"
                :label="column.text"
                hide-details
              ></v-switch>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary darken-1"
                text
                @click.native="
                                    dialog_columns = false
                                    dirtyStorage()
                                "
              >Done</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar-items>

      <template v-if="$vuetify.breakpoint.smAndUp">
        <v-btn
          icon
          @click="resetSearch()"
        >
          <v-icon>mdi-delete-circle</v-icon>
        </v-btn>
      </template>
    </v-toolbar>

    <v-data-table
      v-model="selected"
      :headers="filteredHeaders"
      :items="filteredItems"
      :options.sync="options"
      :server-items-length="total"
      :footer-props="{ itemsPerPageOptions: [10, 30, 50, 100, 500] }"
      :loading="pending"
      no-results-text="Loading data..."
      class="elevation-1 mytable"
      :mobile-breakpoint="0"
    >
      <template v-slot:top>
        <v-expand-transition>
          <div
            v-show="header_filters"
            class="elevation-3"
          >
            <v-toolbar
              v-for="(search, index) in filteredSearch"
              :key="index"
              dense
              flat
            >
              <v-select
                :items="selectableColumns"
                v-model="filteredSearch[index].column"
                hide-details
                class="mr-4"
                style="max-width:220px"
              ></v-select>
              <v-text-field
                v-model="filteredSearch[index].value"
                hide-details
                v-on:input="debounceFilter(filteredSearch[index].value)"
                single-line
                clearable
              ></v-text-field>

              <v-btn
                v-if="index == 0"
                icon
                color="primary"
                @click="addFilter()"
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
              <v-btn
                v-else
                icon
                color="red"
                @click="removeFilter(index)"
              >
                <v-icon>mdi-minus-circle</v-icon>
              </v-btn>
            </v-toolbar>
          </div>
        </v-expand-transition>
      </template>

      <template v-slot:[`item.message`]="{ item }">

        <v-btn
          x-small
          @click="showJsonDialog(item)"
        >
          <v-icon left>
            mdi-code-json
          </v-icon>
          Show
        </v-btn>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ item.date | datetime }}
      </template>

      <v-alert
        slot="no-data"
        :value="true"
        color="info"
        icon="mdi-magnify-close"
        class="mt-4"
        dark
      >
        There are no results that match your search.
      </v-alert>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { generateFilter } from '../../helpers'
import { JSONView } from 'vue-json-component'

export default {
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
        }),
        ...mapState('session', {
            stored(state) {
                return state.storage.messages || { headers: [], options: {}, search_global: '', search_fields: [] }
            },
            advanced_view: (state) => state.rules.advanced_view || false,
        }),
        filteredHeaders() {
            var selected_headers = this.headers.filter((h) => h.selected)
            var selected_values = selected_headers.map((x) => x.value)

            if (selected_values.length > 0) {
                this.$store.dispatch('session/storage', { name: 'messages', headers: selected_values })
            }

            return selected_headers
        },
        filteredItems() {
            return this.payload.map((item) => {
                return Object.assign({}, item)
            })
        },
        searchColumns() {
            var selected_headers = this.headers.filter((h) => h.selected && h.searchable != false)
            var selected_values = selected_headers.map((x) => x.value)

            return selected_headers
        },
        selectableColumns() {
            if (this.advanced_view) {
                return this.headers.filter((h) => h.toggleable != false)
            } else {
                return this.headers.filter((h) => h.toggleable != false && h.advanced != true)
            }
        },
        filteredSearch() {
            if (this.advanced_view) {
                return this.search_fields
            } else {
                return this.search_fields.filter((s) =>
                    this.headers.some((h) => h.value == s.column && h.advanced != true)
                )
            }
        },
        toggleActionsButton() {
            return this.selected.length ? false : true
        },
    },
    data() {
        return {
            headers: [
                { text: 'Device EUI', value: 'deveui', selected: true },
                { text: 'Date', value: 'date', selected: true },
                { text: 'Category', value: 'category', selected: true },
                { text: 'Message', value: 'message', selected: true },
                { text: 'Payload', value: 'payload', selected: true },
            ],
            selected: [],
            search_global: '',
            search_fields: [{ column: 'deveui', value: '' }],
            dialog_columns: false,
            dialog_help: false,
            dialog_json: false,
            header_filters: false,
            date_picker: false,
            options: {},
            total: -1,
            message_json: {},
        }
    },
    methods: {
        getDataFromApi(reset_total = false, total_only = false) {
            this.selected = []

            const { sortBy, sortDesc, page, itemsPerPage } = this.options

            if (!!this.search_global) {
                const search_fields = this.headers
                    .filter((h) => h.selected)
                    .map((h) => {
                        h = { column: h.value, value: this.search_global }
                        return h
                    })
                var api_filter = this.createApiFilter(search_fields, true)
            } else {
                var api_filter = this.createApiFilter(this.search_fields)
            }

            this.$store
                .dispatch('api/call', {
                    name: total_only ? 'messages_total' : 'default',
                    url: '/lora/messages',
                    params: {
                        count: itemsPerPage,
                        skip: (page - 1) * itemsPerPage,
                        only: 'date,deveui,payload,category,message',
                        category: 'uplink,downlink',
                        sort_by: sortBy[0],
                        descending: sortDesc[0],
                        extra: 'id',
                        filter: api_filter,
                        total: total_only ? 'only' : '',
                    },
                })
                .then((response) => {
                    if (response.data.total) {
                        this.total = response.data.total
                    } else {
                        // Store in local storage
                        this.$store.dispatch('session/storage', {
                            name: 'messages',
                            options: this.options,
                            search_global: this.search_global,
                            search_fields: this.search_fields,
                        })

                        if (!total_only) {
                            this.dirtyStorage()
                        }

                        if (this.total == -1 || reset_total) {
                            // Fetch only the items total
                            this.getDataFromApi(false, true)
                        }
                    }
                })
        },
        createApiFilter(filter, or_clause = false) {
            const conversions = {
                category: { uplink: '1', downlink: '2', location: '3' },
            }
            return generateFilter(filter, or_clause, conversions)
        },
        showColumn(col) {
            return this.headers.find((h) => h.value === col).selected
        },
        showJsonDialog(data) {
            this.message_json = data.message
            this.dialog_json = true
        },
        clickSelect(item) {
            const index = this.selected.findIndex((v) => v.id === item.id)
            if (index !== -1) {
                this.selected.splice(index, 1)
            } else {
                this.selected.push(item)
            }
        },
        resetFromStorage() {
            if ('headers' in this.stored) {
                if (this.stored.headers.length) {
                    for (let header of this.headers) {
                        header.selected = this.stored.headers.includes(header.value) ? true : false
                    }
                }
            }
            if ('options' in this.stored) {
                if ('sortBy' in this.stored.options) {
                    this.options = this.stored.options
                } else {
                    this.options = { ...this.stored.options, ...{ sortBy: ['status'], sortDesc: ['true'] } }
                }
            }
            if ('search_global' in this.stored) {
                this.search_global = this.stored['search_global']
            }
            if ('search_fields' in this.stored) {
                if (this.stored.search_fields.length) {
                    this.search_fields = this.stored['search_fields']
                    for (let field of this.search_fields) {
                        if (field['value']) {
                            this.header_filters = true
                        }
                    }
                }
            }
        },
        dirtyStorage() {
            this.$store.dispatch('api/call', {
                name: 'dirty_storage',
                method: 'put',
                url: '/account/storage',
                data: {
                    storage: { messages: this.stored },
                },
            })
        },
        resetSearch() {
            for (let field in this.search_fields) {
                if (this.search_fields.hasOwnProperty(field)) {
                    this.search_fields[field] = ''
                }
            }
            this.search_global = ''
            this.search_fields = [{ column: 'iccid', value: '' }]

            this.getDataFromApi(true)
            this.dirtyStorage()
        },
        addFilter() {
            this.search_fields.push({ column: 'iccid', value: '' })
        },
        removeFilter(index) {
            this.search_fields.splice(index, 1)
            this.getDataFromApi(true)
        },
        debounceFilter: _debounce(function (value) {
            if (value) {
                const operators = value.match(/(!=|>=|<=|!\(|\(|\)|\[|\]|\*|!|=|>|<)/g)

                if (operators) {
                    if (operators.includes('(') | operators.includes('!(') | operators.includes('[')) {
                        if (operators.includes(')') | operators.includes(']')) {
                            this.getDataFromApi(true)
                        }
                    } else if (value.length > operators[0].length) {
                        this.getDataFromApi(true)
                    }
                } else {
                    this.getDataFromApi(true)
                }
            } else {
                this.getDataFromApi(true)
            }
        }, 800),
    },
    watch: {
        options: {
            handler() {
                this.getDataFromApi()
            },
            deep: true,
        },
    },
    components: { 'json-view': JSONView },
    created() {
        this.resetFromStorage()

        if (typeof this.$route.query.sort_by !== 'undefined') {
            this.options.sortBy[0] = this.$route.query.sort_by
        }

        if (typeof this.$route.query.descending !== 'undefined') {
            this.options.sortDesc[0] = this.$route.query.descending
        }

        if (typeof this.$route.query.filter !== 'undefined') {
            this.search_global = ''
            this.search_fields = []
            this.header_filters = true
            let filters = this.$route.query.filter.split(',')
            for (let filter of filters) {
                let args = filter.split(':')
                this.search_fields.push({ column: args[0], value: args[1] })
            }
        }
    },
}
</script>

<style scoped>
.payload {
    max-width: 200px;
    word-wrap: break-word;
}
</style>