<template>
  <div>
    <h1 class="mb-4">Send Test SMS</h1>
    <v-stepper
      v-model="sms_stepper"
      vertical
    >
      <v-stepper-step
        :complete="sms_stepper > 1"
        step="1"
      >
        Please supply ICCID and message
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            filled
            auto-grow
            rounded
            name="iccid"
            v-model="iccid_input"
            :rules="[rules.required, rules.number]"
            placeholder="ICCID of the simcard you want to message."
            required
          ></v-text-field>
          <v-textarea
            filled
            auto-grow
            rounded
            name="message"
            v-model="message_input"
            rows="3"
            :counter="160"
            :rules="[rules.required, rules.max]"
            placeholder="Please supply a message of no more than 160 characters."
            required
          ></v-textarea>
          <v-btn
            color="primary"
            class="mt-2"
            :disabled="!valid"
            @click="diagnose_send_sms()"
          >Continue</v-btn>
        </v-form>
      </v-stepper-content>

      <v-stepper-step
        :complete="sms_stepper > 2"
        step="2"
      >Diagnosing SMS delivery</v-stepper-step>

      <v-stepper-content step="2">
        <div v-if="processing">

          <v-alert
            text
            prominent
            type="info"
            icon="mdi-cellphone-information"
          >
            Requesting SMS delivery confirmation...<br />
            Please note, it might take more than a minute before the operator can confirm the SMS is received by your device.
          </v-alert>
          <v-progress-linear
            indeterminate
            rounded
            striped
            height="25"
          >
          </v-progress-linear>
        </div>
        <div v-else>
          <v-alert
            type="success"
            text
            v-if="status == 'delivered'"
          >
            SMS message has been successfully delivered on {{result.date_received}}.
          </v-alert>
          <v-alert
            type="warning"
            text
            v-else-if="status == 'timeout'"
          >
            Timeout reached while checking the delivery status of the SMS message. Unfortunately we can't determine if the SMS message has been delivered.
          </v-alert>
          <v-alert
            type="error"
            text
            v-else-if="status == 'error'"
          >
            Server error while trying to send request, please try again later.
          </v-alert>
        </div>

        <v-btn
          class="mt-8"
          dark
          @click="reset(true)"
        >Back</v-btn>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            valid: true,
            sms_stepper: 1,
            search: null,
            iccid_input: '',
            message_input: '',
            processing: false,
            counter_max: 0,
            timeout: 0,
            result: {},
            status: 'pending',
            rules: {
                required: (v) => !!v || 'Required.',
                max: (v) => !v || v.length <= 160 || 'Maximum of 160 characters.',
                number: (v) => !isNaN(v) || 'ICCID can only contain numbers.',
            },
        }
    },
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
        }),
    },
    timers: {
        checkSmsStatus: { time: 1000, repeat: true },
    },
    methods: {
        diagnose_send_sms() {
            this.processing = true
            this.status = 'pending'
            this.sms_stepper = 2
            this.result = {}

            this.$store
                .dispatch('api/call', {
                    method: 'post',
                    url: '/diagnostics/sms',
                    data: {
                        iccid: this.iccid_input,
                        message: this.message_input,
                    },
                })
                .then(
                    (response) => {
                        this.$timer.start('checkSmsStatus')
                        this.status = 'pending'
                    },
                    (error) => {
                        this.status = 'error'
                        this.reset()
                    }
                )
        },
        reset(stepper = false) {
            //this.iccid_input = ''
            //this.message_input = ''
            this.processing = false
            this.timeout = 0
            this.$timer.stop('checkSmsStatus')

            if (stepper) {
                this.sms_stepper = 1
            }
        },
        checkSmsStatus() {
            this.$store
                .dispatch('api/call', {
                    name: 'check_sms_status',
                    method: 'get',
                    url: '/diagnostics/sms',
                    params: {
                        id: this.payload.id,
                        operator: this.payload.operator,
                    },
                })
                .then(
                    (response) => {
                        this.timeout++

                        if (response.data.payload.status == 'Delivered') {
                            this.result = response.data.payload
                            this.status = 'delivered'
                            this.reset()
                        } else if (this.timeout > 300) {
                            console.log('checkSmsStatus timeout reached.')
                            this.status = 'timeout'
                            this.reset()
                        }
                    },
                    (error) => {
                        this.status = 'error'
                        this.reset()
                    }
                )
        },
    },
}
</script>
