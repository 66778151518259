<template>
  <v-app>
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12">
              <v-toolbar
                :color="$cfg.theme.app_bar.background"
                flat
              >
                <img
                  :src="logo"
                  :width="$cfg.theme.logo.width"
                  :height="$cfg.theme.logo.height"
                />
                <v-toolbar-title :class="'ml-3 ' + $cfg.theme.app_bar.text">Account Validation</v-toolbar-title>
              </v-toolbar>
              <v-card-text v-if="show_alert=='success'">
                <v-alert
                  prominent
                  type="success"
                  transition="fade-transition"
                >
                  Your account has been activated successfully.<br /><br />
                  Go to the Dashboard login page to log in with your new account.
                </v-alert>
                <div class="text-center">
                  <v-btn
                    color="primary"
                    @click="gotoLogin"
                  >Go to Dashboard Login</v-btn>
                </div>
              </v-card-text>
              <v-card-text v-if="show_alert=='failure'">
                <v-alert
                  prominent
                  type="error"
                  transition="fade-transition"
                >
                  Account validation failed. Your account has not been activated.
                </v-alert>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue'

export default {
    data() {
        return {
            show_alert: '',
        }
    },
    computed: {
        logo() {
            return `/themes/${this.$cfg.theme.folder}/${this.$cfg.theme.logo.filename}`
        },
    },
    methods: {
        check() {
            this.$store
                .dispatch('api/call', {
                    url: '/account/activate',
                    method: 'post',
                    data: {
                        activate_token: this.$route.query.token,
                    },
                })
                .then(
                    (response) => {
                        this.show_alert = 'success'
                    },
                    (error) => {
                        this.show_alert = 'failure'
                    }
                )
        },
        gotoLogin() {
            this.$router.push({ path: `/login` })
        },
    },
    created() {
        this.check()
    },
}
</script>