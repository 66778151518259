<template>
  <v-app>
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12">
              <v-toolbar
                :color="$cfg.theme.app_bar.background"
                flat
              >
                <img
                  :src="logo"
                  :width="$cfg.theme.logo.width"
                  :height="$cfg.theme.logo.height"
                />
                <v-toolbar-title :class="'ml-3 ' + $cfg.theme.app_bar.text">Password Reset</v-toolbar-title>
              </v-toolbar>
              <v-card-text v-if="success">
                <v-alert
                  prominent
                  type="success"
                  transition="fade-transition"
                >
                  Your password has successfully been reset.<br /><br />
                  Go to the Dashboard login page to log in with your new password.
                </v-alert>
                <div class="text-center">
                  <v-btn
                    color="primary"
                    @click="gotoLogin"
                  >Go to Dashboard Login</v-btn>
                </div>
              </v-card-text>

              <v-form
                v-model="valid"
                v-else
              >
                <v-card-text>
                  <v-alert
                    prominent
                    type="error"
                    transition="fade-transition"
                    :value="show_alert"
                  >
                    {{alert_message}}
                  </v-alert>

                  <v-text-field
                    v-model="new_password"
                    :append-icon="show_new_password ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show_new_password ? 'text' : 'password'"
                    prepend-icon="mdi-key"
                    name="password"
                    label="New password"
                    counter
                    @click:append="show_new_password = !show_new_password"
                  ></v-text-field>
                  <v-text-field
                    v-model="confirm_password"
                    :append-icon="show_confirm_password ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min, password_match]"
                    :type="show_confirm_password ? 'text' : 'password'"
                    prepend-icon="mdi-key"
                    name="password"
                    label="Re-enter new password"
                    counter
                    @click:append="show_confirm_password= !show_confirm_password"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    v-if="show_alert"
                    text
                    @click="gotoLogin"
                  >Return to login</v-btn>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    :loading="pending"
                    :disabled="!valid"
                    @click="reset"
                  >Change password</v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(VueReCaptcha, { siteKey: '6LdvLdQUAAAAAHmmZYw2ckUx6Kvok2nx3w2bMh5F' })

export default {
    data() {
        return {
            valid: true,
            new_password: '',
            confirm_password: '',
            show_new_password: false,
            show_confirm_password: false,
            show_alert: false,
            alert_message: '',
            rules: {
                required: (value) => !!value || 'Required.',
                min: (v) => v.length >= 8 || 'Minimum of 8 characters.',
            },
        }
    },
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
        }),
        password_match() {
            return () => this.new_password === this.confirm_password || 'Passwords must match.'
        },
        logo() {
            return `/themes/${this.$cfg.theme.folder}/${this.$cfg.theme.logo.filename}`
        },
    },
    methods: {
        async reset() {
            this.is_submitting = true
            const token = await this.$recaptcha('login')

            this.$store
                .dispatch('api/call', {
                    url: '/reset',
                    method: 'put',
                    data: {
                        reset_token: this.$route.query.token,
                        password: this.new_password,
                        recaptcha: token,
                    },
                })
                .then(
                    (response) => {},
                    (error) => {
                        this.show_alert = true
                        switch (this.payload.name) {
                            case 'token_invalid':
                                this.alert_message = 'Password reset token is invalid.'
                                break
                            case 'token_expired':
                                this.alert_message = 'Password reset token has expired.'
                                break
                        }
                    }
                )
        },
        gotoLogin() {
            this.$router.push({ path: `/login` })
        },
    },
}
</script>