<template>
  <div>
    <h1 class="mb-4">Network Switch</h1>

    <v-dialog
      v-model="dialog_consent"
      max-width="400"
    >
      <v-card
        color="amber darken-3"
        dark
      >
        <v-card-title class="headline">
          <v-icon
            large
            left
          >
            mdi-alert
          </v-icon>WARNING
        </v-card-title>

        <v-card-text>
          If you force your device to another network, SMS and Voice services will be activated.<br /><br />
          Every single provider profile will allow connections on one specific operator in multiple countries, so if your device moves to a different country without changing this profile, it could connect on a more expensive operator.<br />
          <br />
          By clicking <strong>PROCEED</strong> you agree with the costs that may occur from unexpected SMS and/or Voice usage, or data usage with an operator with different pricing if the device moves to another country.
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1"></div>

          <v-btn
            text
            @click.native="dialog_consent = false"
          >
            Cancel
          </v-btn>

          <v-btn @click.native="proceed">
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="580px"
      persistent
    >
      <v-card>
        <v-card-title class="headline primary white--text">
          Network Switch Result
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout
              wrap
              v-if="switching"
            >
              <div class="font-weight-bold mb-2">Processing request. Please stand by...</div>
              <v-progress-linear
                indeterminate
                rounded
                height="10"
              ></v-progress-linear>
            </v-layout>
            <v-layout
              wrap
              v-else
            >
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="item in filteredItems"
                  :key="item.x"
                  :class="item.class"
                >
                  <v-expansion-panel-header>
                    <div class="font-weight-bold">
                      {{ item.title }} <span> &nbsp;({{ item.count }}) </span>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-alert
                      :value="true"
                      :type="item.type"
                      outlined
                      class="mb-2 white"
                    >
                      {{ item.info }}
                    </v-alert>
                    <div class="text-center">
                      <kbd
                        v-for="(iccid, index) in item.iccids"
                        :key="index"
                        :class="[{ 'ml-2': index !== 0 }, 'mt-2']"
                      >{{ iccid }}</kbd>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-alert
      :value="alert"
      type="error"
      transition="scale-transition"
    >
      You have entered both KPN and Tele2 ICCID's. Only one operator is allowed for the network switch.
    </v-alert>
    <v-layout>
      <v-flex>
        <v-textarea
          name="iccids"
          v-model="iccids_input"
          v-on:input="debounceInput(iccids_input)"
          solo
          rows="10"
          placeholder="8931234567891234567, 8931234567891234568, 8931234567891234569, ..."
        ></v-textarea>
      </v-flex>
    </v-layout>
    <v-layout
      mx-auto
      wrap
    >
      <v-flex
        xs12
        md4
      >
        <v-autocomplete
          v-model="selected_country"
          :items="operatorCountries"
          :loading="providers.pending"
          label="Select country"
          placeholder="Search for country"
          prepend-icon="mdi-earth"
          return-object
          solo
          item-text="country"
          item-value="id"
          clearable
        >
          <template v-slot:[`selection`]="data">
            <v-avatar left>
              <div style="font-size:24px">
                <flag :iso="data.item.country_iso" />
              </div>
            </v-avatar>
            {{ data.item.country }}
          </template>
          <template
            slot="item"
            slot-scope="data"
          >
            <v-list-item-avatar>
              <div style="font-size:24px">
                <flag :iso="data.item.country_iso" />
              </div>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.country"></v-list-item-title>
            </v-list-item-content>
          </template>

        </v-autocomplete>
      </v-flex>
      <v-flex
        xs12
        md4
        :pl-4="$vuetify.breakpoint.mdAndUp"
      >
        <v-select
          label="Select network"
          :items="countryNetworks"
          v-model="selected_provider"
          prepend-icon="mdi-satellite-uplink"
          solo
          item-text="provider"
          item-value="id"
        >
          <template
            slot="selection"
            slot-scope="data"
          >
            <img
              :src="getImgUrl(data.item.provider)"
              class="pr-3"
              style="width:72px;max-height:24px;"
            />
            <span>{{ data.item.provider }}</span>
          </template>
          <template
            slot="item"
            slot-scope="data"
          >
            <img
              :src="getImgUrl(data.item.provider)"
              class="pr-3"
              style="width:72px;max-height:24px;"
            />

            <v-list-item-content>
              <v-list-item-title v-html="data.item.provider"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
      </v-flex>
      <v-flex
        xs12
        md4
        :pl-4="$vuetify.breakpoint.mdAndUp"
      >
        <v-select
          label="Select duration"
          :items="periods"
          v-model="selected_period"
          prepend-icon="mdi-clock"
          solo
        ></v-select>
      </v-flex>
      <v-flex>
        <v-btn
          large
          color="primary"
          :disabled="switchButton"
          :loading="switching"
          @click="dialog_consent = true"
        >
          <v-icon
            left
            dark
          >mdi-arrow-decision</v-icon>
          Switch Networks
        </v-btn>
      </v-flex>
    </v-layout>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
            providers: (state) => state.calls.provider_list,
        }),
        ...mapState('session', {
            stored(state) {
                return state.storage.switch || {}
            },
        }),

        operatorCountries: function () {
            if (typeof this.providers !== 'undefined') {
                let obj = this.providers.payload.filter((v) => v.operator === this.operator)
                return _sortedUniqBy(_sortBy(obj, ['country', 'provider']), 'country')
            }
            return []
        },
        countryNetworks: function () {
            if (typeof this.selected_country !== 'undefined') {
                let obj = this.providers.payload.filter(
                    (v) => v.operator === this.operator && v.country_iso === this.selected_country.country_iso
                )
                if (obj) {
                    return _sortBy(obj, ['provider'])
                }
            }

            return []
        },
        filteredItems() {
            let filtered = Object.assign({}, this.result)
            if (typeof this.payload !== 'undefined') {
                Object.entries(filtered).forEach(([key, val]) => {
                    if (key in this.payload) {
                        if (Object.keys(this.payload[key]).length > 0) {
                            val.iccids = this.payload[key]
                            val.count = this.payload[key].length
                        } else {
                            delete filtered[key]
                        }
                    } else {
                        delete filtered[key]
                    }
                })
            }
            return filtered
        },
        switchButton() {
            if (
                this.selected_country &&
                this.selected_provider &&
                this.selected_period &&
                this.switching == false &&
                this.alert == false
            ) {
                return false
            } else {
                return true
            }
        },
    },
    data() {
        return {
            alert: false,
            switching: false,
            dialog: false,
            dialog_consent: false,
            iccids_input: '',
            iccids: [],
            operator: '',
            selected_country: '',
            selected_provider: '',
            selected_period: '15',
            token: '',
            progress: 0,
            periods: [
                { text: '15 minutes', value: '15' },
                { text: '30 minutes', value: '30' },
                { text: '60 minutes', value: '60' },
                { text: 'Permanent', value: '999' },
            ],
            result: {
                success: {
                    title: 'Success',
                    info: 'Simcards where processed successfully',
                    type: 'success',
                    class: 'green darken-1 white--text',
                },
                invalid: {
                    title: 'Invalid',
                    info: 'Simcard ICCID number incorrect or not managed by you',
                    type: 'error',
                    class: 'red darken-1 white--text',
                },
                not_switchable: {
                    title: 'Not Switchable',
                    info: 'Simcard profile not allowed to be switched',
                    type: 'error',
                    class: 'red darken-1 white--text',
                },
                no_complan: {
                    title: 'No Valid Profile',
                    info: 'Simcard profile unknown or not allowed to be switched',
                    type: 'error',
                    class: 'red darken-1 white--text',
                },
                no_base_complan: {
                    title: 'Unique Profile',
                    info: 'Simcard profile is unique, no network switch possible',
                    type: 'error',
                    class: 'red darken-1 white--text',
                },
                no_switch_complan: {
                    title: 'No Network Alternative',
                    info: 'Sim card profile has no network switch alternative',
                    type: 'error',
                    class: 'red darken-1 white--text',
                },
                api_error: {
                    title: 'System Error',
                    info: 'Provider switch temporarily unresponsive',
                    type: 'error',
                    class: 'red darken-1 white--text',
                },
            },
        }
    },
    timers: {
        checkNetworkSwitchStatus: { time: 1000, repeat: true },
    },
    methods: {
        getImgUrl(img) {
            const telecoms = [
                'arkessa',
                'airtel',
                'kpn',
                't-mobile',
                'orange',
                'tele2',
                'vodafone',
                'base',
                'o2',
                'proximus',
                'telefonica',
                'telenor',
                'three',
                'mobistar',
                'telekom',
                'cable',
                'digicel',
            ]

            const i = telecoms.findIndex((v) => img.toLowerCase().includes(v))
            if (i !== -1) {
                return '/img/' + telecoms[i] + '.svg'
            }

            return '/img/mobilenetwork.svg'
        },
        determineOperator(iccids_input) {
            this.iccids = iccids_input.split(/\s*[\n,]+\s*/)
            this.operator = ''

            for (let i = 0; i < this.iccids.length; i++) {
                if (this.iccids[i].startsWith('893108')) {
                    this.operator = this.operator != '2POM' ? 'KPOM' : 'multiple'
                } else if (this.iccids[i].startsWith('893142') || this.iccids[i].startsWith('894620')) {
                    this.operator = this.operator != 'KPOM' ? '2POM' : 'multiple'
                }

                if (this.operator == 'multiple') {
                    this.alert = true
                    break
                } else {
                    this.alert = false
                }
            }
        },
        requestNetworkSwitch() {
            this.switching = true
            this.dialog = true
            this.$store
                .dispatch('api/call', {
                    method: 'post',
                    url: '/switch',
                    data: {
                        iccids: this.iccids,
                        provider_id: this.selected_provider,
                        period: this.selected_period,
                    },
                })
                .then(
                    (response) => {
                        if ('token' in response.data.payload) {
                            this.token = response.data.payload.token
                            this.$timer.start('checkNetworkSwitchStatus')
                        }
                    },
                    (error) => {
                        console.log('error')
                    }
                )
        },
        checkNetworkSwitchStatus() {
            this.$store
                .dispatch('api/call', {
                    method: 'get',
                    url: '/switch/' + this.token,
                })
                .then(
                    (response) => {
                        this.progress = response.data.progress

                        if (response.data.progress == 100) {
                            this.$timer.stop('checkNetworkSwitchStatus')
                            this.reset()
                        }
                    },
                    (error) => {
                        this.$timer.stop('checkNetworkSwitchStatus')
                    }
                )
        },
        proceed() {
            this.dialog_consent = false
            this.requestNetworkSwitch()
        },
        reset() {
            this.switching = false
            this.iccids_input = ''
            this.iccids = []
            this.operator = ''
            this.selected_country = ''
            this.selected_provider = ''
            this.selected_period = '15'
            this.token = ''
            this.progress = 0
        },
        debounceInput: _debounce(function (value) {
            this.determineOperator(value)
        }, 400),
    },
    created() {
        this.$store.dispatch('api/call', {
            name: 'provider_list',
            url: '/providers',
        })

        if (typeof this.$route.params.iccids !== 'undefined') {
            this.iccids_input = this.$route.params.iccids
            this.determineOperator(this.iccids_input)
        }
    },
}
</script>

<style>
</style>
