<template>
  <div>
    <h1 class="mb-4">Activate SmartSim</h1>
    <v-row justify="center">
      <v-card
        class="mx-auto"
        max-width="500"
      >
        <v-card-text class="body-1">
          <p>Here you can add a SmartSim card to your account, using a unique activation code. This code has been emailed to you, or is printed on a label if you have received a flyer with your sim card.</p>
          <p>After entering this 12-digit code, the SmartSim card will become active and visible in your <a
              href="#"
              @click="gotoOverview()"
            >SmartSims Overview</a> page.</p>

          <p>If you have any further questions, please contact support.</p>
          <v-form v-model="valid">

            <v-text-field
              v-model="activation_code"
              label="Activation Code"
              single-line
              solo
              :rules="activationRules"
              :counter="12"
              required
            ></v-text-field>

            <v-row justify="center">
              <v-btn
                x-large
                color="success"
                :disabled="!valid"
                @click="activateSmartSim"
              >
                Activate
                <v-icon
                  right
                  dark
                >mdi-check</v-icon>
              </v-btn>
            </v-row>

          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title
          class="headline green white--text"
          primary-title
        >
          SmartSim Activation Succes!
        </v-card-title>

        <v-card-text>
          You can manage your new SmartSim in the overview page!
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="gotoOverview"
          >
            Go to Overview
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="error_dialog"
      width="500"
    >
      <v-card>
        <v-card-title
          class="headline red white--text"
          primary-title
        >
          Whoops, something went wrong!
        </v-card-title>

        <v-card-text>
          Please check you're activation code, if you keep receiving this error message please contact <a href="mailto:support@simservices.com">support@simservices.com</a>!
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="error_dialog = false"
          >
            Ok!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState('api', {
            pending: state => state.calls.default.pending,
            success: state => state.calls.default.success,
            message: state => state.calls.default.message,
            payload: state => state.calls.default.payload
        }),
        ...mapState('session', {
            stored(state) {
                return state.storage.diagnostics || { headers: [] }
            }
        }),
        binding() {
            const binding = {}
            if (this.$vuetify.breakpoint.smAndDown) binding.column = true
            return binding
        }
    },
    data() {
        return {
            dialog: false,
            error_dialog: false,
            valid: true,
            activation_code: [],
            activationRules: [
                v => !!v || 'Activation code is required',
                v => v.length == 12 || 'Activation Code must be exactly 12 characters.'
            ]
        }
    },
    methods: {
        activateSmartSim() {
            this.$store
                .dispatch('api/call', {
                    method: 'put',
                    url: '/smartsims',
                    data: {
                        activation_code: this.activation_code
                    }
                })
                .then(
                    response => {
                        this.dialog = true
                    },
                    error => {
                        this.error_dialog = true
                    }
                )
        },
        gotoOverview() {
            this.$router.push({ path: `/smartsims/overview` })
        }
    }
}
</script>