<template>
  <div>
    <h1 class="mb-4">Account</h1>
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-card
        class="mx-auto"
        style="max-width: 600px;"
      >
        <v-card-text>
          <v-alert
            prominent
            :type="alert_type"
            transition="fade-transition"
            :value="show_alert"
          >
            {{ alert_message }}
          </v-alert>
          <v-text-field
            v-model="current_password"
            :append-icon="show_current_password ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            :type="show_current_password ? 'text' : 'password'"
            prepend-icon="mdi-key"
            name="current_password"
            label="Current password"
            hint="Your current password is required to make any changes."
            persistent-hint
            counter
            @click:append="show_current_password = !show_current_password"
          ></v-text-field>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-card-text>
          <v-text-field
            v-model="name"
            name="name"
            label="Name"
            type="text"
            prepend-icon="mdi-account-edit"
            required
          ></v-text-field>
          <v-text-field
            v-model="email"
            :rules="[rules.email]"
            name="email"
            label="Email"
            type="email"
            prepend-icon="mdi-email"
            required
          ></v-text-field>

          <v-text-field
            v-model="new_password"
            :append-icon="show_new_password ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.min]"
            :type="show_new_password ? 'text' : 'password'"
            prepend-icon="mdi-key"
            name="new_password"
            label="New password"
            counter
            @click:append="show_new_password = !show_new_password"
          ></v-text-field>
          <v-text-field
            v-model="confirm_password"
            :append-icon="show_confirm_password ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.min, password_match]"
            :type="show_confirm_password ? 'text' : 'password'"
            prepend-icon="mdi-key"
            name="confirm_password"
            label="Re-enter new password"
            counter
            @click:append="show_confirm_password= !show_confirm_password"
          ></v-text-field>
          <v-switch
            v-model="otp.enabled"
            prepend-icon="mdi-security"
            name="otp"
            :label="otp_description"
          ></v-switch>
          <div class="mt-4 subtitle-1 font-weight-bold text-center">Your Avatar</div>

          <v-slide-group
            v-model="avatar"
            class="mb-4"
            active-class="success"
            show-arrows
            center-active
            mandatory
          >
            <v-slide-item
              v-for="(item,key) in avatars"
              :key="key"
              v-slot:default="{ active, toggle }"
            >
              <v-card
                :color="active ? undefined : 'grey lighten-1'"
                class="ma-2"
                height="64"
                width="64"
                :img="'/img/avatars/' + item"
                @click="toggle"
              >
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center"
                >
                  <v-scale-transition>

                  </v-scale-transition>
                </v-row>
              </v-card>

            </v-slide-item>
          </v-slide-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="pending"
            :disabled="!valid"
            color="primary"
            @click="change"
          >
            Change
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-form>
    <div
      div="flaticon"
      class="caption font-weight-thin text-center mt-4 grey--text"
    >Avatars made by <a
        href="https://www.flaticon.com/authors/freepik"
        target="_blank"
      >Freepik</a> from <a
        href="https://www.flaticon.com"
        target="_blank"
      >www.flaticon.com</a></div>
      <div>
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Two Factor Authentication</span>
              </v-card-title>
              <v-card-text
                v-if="!otp.valid && otp.state === 'disabling' || otp.state === 'pending_disable'"
              ><v-alert
                  prominent
                  type="error"
                  transition="fade-transition"
                  :value="show_dialog_alert"
                >{{ dialog_alert_message }}</v-alert>
                <v-text-field
                  v-model="otp.passcode"
                  prepend-icon="mdi-qrcode-scan"
                  name="otp"
                  label="Enter 6-digit code"
                  required
                  autocomplete="one-time-code"
                  :rules="[rules.passcode]"
                ></v-text-field>
              </v-card-text>
              <v-card-text v-if="!otp.valid && otp.state === 'enabling' || otp.state === 'pending_enable'">
                <v-alert
                  prominent
                  type="error"
                  transition="fade-transition"
                  :value="show_dialog_alert"
                >{{ dialog_alert_message }}</v-alert>
                <p class="text-subtitle-2 text-justify">For enhanced account security, activate Two-Factor Authentication (2FA) via a password manager or apps like Authy or Google Authenticator.</p>
                <v-container>
                  <v-row justify="center">
                    <ol>
                      <li>Use a password manager for optimal 2FA setup.</li>
                      <li>Alternatively, download Authy or Google Authenticator.</li>
                      <li>Scan the QR code below with your chosen app.            
                        <v-img
                          :src="otp.qrcode"
                          max-width="250"
                          max-height="250"
                          class="mx-auto"
                        ></v-img>
                      </li>
                      <li>Enter the 6-digit code generated by your password manager or app.</li>
                      <v-text-field
                        v-model="otp.passcode"
                        prepend-icon="mdi-qrcode-scan"
                        name="otp"
                        label="Enter 6-digit code"
                        required
                        autocomplete="one-time-code"
                        :rules="[rules.passcode]"
                      ></v-text-field>
                    </ol>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-text v-if="otp.valid && otp.state === 'pending'">
                <p class="text-subtitle-2 text-justify">Your Two-Factor Authentication setup is complete! Below are your recovery codes. It is crucial to keep them safe as they can be used to recover your account if you lose access to your authentication method.</p>
                <v-card elevation="2" outlined color="#424242">
                  <v-row justify="center">
                    <pre ref="recovery_codes" class="my-8" style="color: white;">{{ recovery_codes_string }}</pre>
                  </v-row>
                </v-card>
              </v-card-text> 
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="otp.state !== 'pending'"
                  color="secondary"
                  text
                  @click="dialog = false; otp.enabled = false; get_account();"
                >
                  Close
                </v-btn>
                <v-btn
                  :loading="pending"
                  :disabled="button.disabled"
                  color="primary"
                  @click="otp_setup(otp.state)"
                >
                {{ button.text }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Triggers from '../triggers.vue';

export default {
    data() {
        return {
            dialog: false,
            valid: true,
            avatar: '',
            name: '',
            email: '',
            current_password: '',
            new_password: '',
            confirm_password: '',
            otp: {
              enabled: false,
              valid: false,
              passcode: '',
              state: "init",
              recovery_codes: null,
              qrcode: null,
            },
            show_current_password: false,
            show_new_password: false,
            show_confirm_password: false,
            show_alert: false,
            alert_type: 'success',
            alert_message: '',
            show_dialog_alert: false,
            dialog_alert_type: 'error',
            dialog_alert_message: '',
            rules: {
                required: (value) => !!value || 'Required.',
                min: (v) => !v || v.length >= 8 || 'Minimum of 8 characters.',
                email: (value) => /.+@.+\..+/.test(value) || 'Email must be valid.',
                passcode: (value) => {
                  const otp_code_pattern = /^\d{6}$/;
                  const recovery_code_pattern = /^[a-zA-Z0-9]{8}$/;

                  return otp_code_pattern.test(value) || recovery_code_pattern.test(value) || 'Passcode must be 6 digits or a valid recovery code!';
              }
            },
        }
    },
    watch: {
      'otp.enabled': function(new_value, old_value) {
        // Check the state of otp.enabled to determine
        // if the user is trying to enable or disable
        // OTP.
        if (new_value && !old_value && this.otp.state !== 'enabled') {
          this.otp.state = "enabling";
        } else if (!new_value && old_value && this.otp.state !== 'disabled') {
          this.otp.state = "disabling";
        }
      },
    },
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
            account: (state) => state.calls.account_info.payload,
        }),
        password_match() {
            return () => this.new_password === this.confirm_password || 'Passwords must match.'
        },
        avatars() {
            return Array.from({ length: 50 }, (x, i) => `avatar_${i + 1}.svg`)
        },
        recovery_codes_string() {
          return this.otp.recovery_codes ? this.otp.recovery_codes.join("\n") : null;
        },
        otp_description() {
          return this.otp.enabled ? 'Two-Factor Authentication Enabled' : 'Two-Factor Authentication Disabled';
        },
        button() {
          switch (this.otp.state) {
            case 'pending_enable':
              return {
                text: 'Proceed',
                disabled: !this.otp.passcode,
              };
            case 'pending':
              return {
                text: 'Done',
                disabled: false,
              };
            case 'disabling':
              return {
                text: 'Disable',
                disabled: !this.otp.passcode,
              };
            case 'pending_disable':
              return {
                text: 'Disable',
                disabled: !this.otp.passcode,
              };
            default:
              return {
                text: 'Invalid State',
                disabled: true,
              };
          }
        },
    },
    methods: {
        async verify_otp() {
          // This function is used to wait for the user to enter their OTP code
          return new Promise((resolve, reject) => {
            // Watch for changes in the obp object
            const unwatch = this.$watch('otp', (new_value, old_value) => {
              if (new_value.enabled === false && new_value.state === 'disabled') {
                // OTP has been disabled
                unwatch();
                resolve(true);
              } // If we need any special handling for other states, add them here
            }, { deep: true});
          })
        },
        otp_setup(state) {
          // This function handles all OTP related actions
          this.otp.valid = false;
          let call = {
            dispatch: false,
            url: '',
            method: '',
            data: null,
            on_success: '',
          };

          switch(state) {
            case 'enabling':
              // This wil get the qrcode
              call.url = '/authorize/otp/qrcode'
              call.method = 'get'
              call.dispatch = true;
              break;
            case 'pending_enable':
              // This wil actually enable the OTP
              // and return the recovery codes
              call.url = '/authorize/otp/enable'
              call.method = 'post'
              call.dispatch = true;
              call.data = { otp_code: this.otp.passcode };
              break;
            case 'pending':
              // At this point, we've received the OTP recovery
              // code and the user has verified their OTP code.
              // So we show them the recovery codes and
              // give them the option to close the dialog.
              this.get_account();
              this.otp.state = "enabled";
            case 'enabled':
              // We assume the user has saved the recovery codes
              // and we don't need to do anything else then close
              // the dialog and message the user.
              this.dialog = false;
              this.alert('success', 'Account profile successfully changed and Two-Factor Authentication enabled.', true);
              break;
            case 'disabling':
              // Disable OTP
              call.dispatch = false;
              this.dialog = true;
              this.otp.state = "pending_disable";
              break;
            case 'pending_disable':
              // Verify OTP
              call.url = '/authorize/otp/disable'
              call.method = 'post'
              call.dispatch = true;
              call.data = { otp_code: this.otp.passcode };
              break;
          }

          if (call.dispatch) {
            let api_call = {
              url: call.url,
              method: call.method,
              ...(call.data ? { data: call.data } : {}),
            }

            this.$store
            .dispatch('api/call', {
              ...api_call,
            })
            .then(
              (response) => {
                this.otp.valid = true;

                switch (state) {
                  case 'enabling':
                    this.otp.state = "pending_enable";
                    this.otp.qrcode = 'data:image/svg+xml;base64,' + response.data.payload.qr_code_svg;
                    this.dialog = true;
                    break;
                  case 'pending_enable':
                    this.$refs.form.resetValidation()
                    this.otp.state = "pending";
                    this.otp.valid = true;
                    this.otp.recovery_codes = response.data.payload.recovery_codes;
                    this.alert('success', 'Account profile successfully changed and Two-Factor Authentication enabled.', true);
                    break;
                  case 'disabling':
                    this.dialog = true;
                    this.otp.state = "pending_disable";
                    break;
                  case 'pending_disable':
                    this.$refs.form.resetValidation()
                    this.otp.state = "disabled";
                    this.dialog = false;
                    this.otp.valid = false;
                    this.alert('success', 'Account profile successfully changed and Two-Factor Authentication disabled.', true);
                    this.get_account();
                    break;
                }
              },
              (error) => {
                this.show_dialog_alert = true
                this.dialog_alert_type = 'error'
                this.dialog_alert_message = this.payload.description
              }
            )
          }
          this.otp.valid = false;
        },
        async change() {
          // We've made this function async only to check
          // if the user is disabling OTP and needs to
          // enter their OTP code first.

          let data = {
            password: this.current_password,
            name: this.name,
            email: this.email,
            new_password: this.new_password,
            avatar: this.avatars[this.avatar],
            is_otp_enabled: this.otp.enabled
          }
          
          // The user is trying to disable OTP
          // We need to request the user for their
          // OTP code first.
          if (this.otp.state === 'disabling' && !this.otp.enabled) {
            this.otp_setup(this.otp.state);
            
            // We wait untill the user has entered their OTP code
            try {
              await this.verify_otp();
            } catch (error) {
              this.show_dialog_alert = true
              this.dialog_alert_type = 'error'
              this.dialog_alert_message = 'OTP code is invalid. Rejecting changes.'
              return;
            }
          }

          this.$store
            .dispatch('api/call', {
                url: '/account',
                method: 'put',
                data: data,
            })
            .then(
                (response) => {
                    this.$refs.form.resetValidation()

                    // If OTP has been changed from false to true 
                    // and the response contains a QRCode,
                    // then we need to set the dialog to true.
                    if (this.otp.state === "enabling" && this.otp.enabled) {
                      this.otp_setup(this.otp.state);
                    }
                    
                    // don't fetch new acount info if the user
                    // is enabling OTP so the otp.state isn't refreshed.
                    if (this.otp.state !== "enabling") {
                      this.get_account();
                    }
                    
                    this.alert('success', 'Account profile successfully changed.', true)
                    setTimeout(() => {
                        this.show_alert = false
                    }, 3000)
                },
                (error) => {
                    this.show_alert = true
                    switch (this.payload.name) {
                        case 'password_invalid':
                            this.alert('error', 'Password is invalid. Rejecting changes.', true)
                            this.alert_type = 'error'
                            this.alert_message = 'Password is invalid. Rejecting changes.'
                            this.current_password = ''
                            this.new_password = ''
                            this.confirm_password = ''
                            break
                        default:
                            this.alert('error', this.payload.description, true)
                    }
                    setTimeout(() => {
                        this.show_alert = false
                    }, 3000)
                }
            )
        },
        alert(type, message, reset=false) {
            this.show_alert = true
            this.alert_type = type
            this.alert_message = message
            if (reset) {
                this.current_password = ''
                this.new_password = ''
                this.confirm_password = ''
                this.otp.passcode = ''
            }
            setTimeout(() => {
                this.show_alert = false
            }, 3000)
        },
        get_account() {
          this.avatar = this.avatars.indexOf(this.$store.getters['session/avatar'])

          // Get the account details from the API
          this.$store
            .dispatch('api/call', {
              name: 'account_info',
              url: '/account',
            })
            .then(
              (response) => {
                this.name = this.account.name
                this.email = this.account.email

                // We need to set the correct OTP state while
                // we fetch the account info.
                this.otp.enabled = !!this.account.is_otp_enabled
                this.otp.state = this.otp.enabled ? 'enabled' : 'disabled'
              }
            )
        },
        copy_to_clipboard() {
          // This function is called when the user clicks the copy button in the dialog.
          // It should copy the recovery codes to the clipboard.
          if (!navigator.clipboard) {
            console.log("Clipboard API not available");
            return;
          }

          navigator.clipboard.writeText(this.recovery_codes_string)
          .then(() => {
            console.log('Recovery codes copied to clipboard');
          })
          .catch(error => {
            console.error('Could not copy recovery codes: ', error);
          })
        }
    },
    created() {
        this.get_account();
    },
}
</script>

<style scoped>
a {
    color: #bdbdbd !important;
    caret-color: #bdbdbd !important;
    text-decoration: none;
}
</style>