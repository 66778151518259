<template>
    <div>
        <v-dialog v-model="dialog_edit" max-width="800" persistent>
            <v-card>
                <v-card-title>
                    <span class="headline" v-if="new_item">Create New Subscription</span>
                    <span class="headline" v-else>Edit Subscription</span>
                </v-card-title>
                <v-card-text>
                    <v-stepper v-if="new_item" v-model="stepper" vertical>
                        <v-stepper-step :rules="[() => step_1_valid]" :complete="stepper > 1" step="1">
                            Select Customer and Operator
                        </v-stepper-step>

                        <v-stepper-content step="1">
                            <v-card dark color="secondary" class="mb-6">
                                <v-card-text>
                                    <v-form ref="step_1">
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-autocomplete
                                                    v-model="editedItem.customer.id"
                                                    :items="customerItems"
                                                    label="Select the Customer"
                                                    item-value="id"
                                                    item-text="customer_name"
                                                    :rules="[rules.required]"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-select
                                                    v-model="editedItem.operator"
                                                    :items="operators"
                                                    :rules="[rules.required]"
                                                    label="Select the operator"
                                                    name="operator"
                                                    item-text="name"
                                                    item-value="id"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                >
                                                    <template slot="selection" slot-scope="data">
                                                        <img
                                                            :src="getImgPath(data.item.name)"
                                                            class="pr-3"
                                                            style="width: 72px; max-height: 24px"
                                                        />
                                                        <span>{{ data.item.name }}</span>
                                                    </template>
                                                    <template slot="item" slot-scope="data">
                                                        <img
                                                            :src="getImgPath(data.item.name)"
                                                            class="pr-3"
                                                            style="width: 72px; max-height: 24px"
                                                        />

                                                        <v-list-item-content>
                                                            <v-list-item-title
                                                                v-html="data.item.name"
                                                            ></v-list-item-title>
                                                        </v-list-item-content>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                            <v-btn color="primary" @click="validate_step_1(2)"> Continue </v-btn>
                        </v-stepper-content>

                        <v-stepper-step :rules="[() => step_2_valid]" :complete="stepper > 2" step="2">
                            Select Type
                        </v-stepper-step>

                        <v-stepper-content step="2">
                            <v-card dark color="secondary" class="mb-12">
                                <v-card-text>
                                    <v-form ref="step_2">
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-select
                                                    v-model="editedItem.bundle_type"
                                                    :items="generate_bundle_items"
                                                    label="Select bundle type"
                                                    :rules="[rules.required]"
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                ></v-select>
                                            </v-col>
                                            <v-col v-if="editedItem.bundle_type != 'SMART'" cols="12" sm="6">
                                                <v-select
                                                    v-model="editedItem.zone"
                                                    :items="generate_zones"
                                                    label="Select roaming zone"
                                                    :rules="[rules.required]"
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                ></v-select>
                                            </v-col>
                                            <v-col v-if="editedItem.bundle_type == 'SMART'" cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.amount"
                                                    label="Fil the Default SmartSim Credits amount"
                                                    :rules="[rules.required]"
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-select
                                                    v-model="editedItem.simcard_type"
                                                    :items="simcard_types"
                                                    label="Select the SIM card type"
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                >
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.setup_fee"
                                                    label="Fill the Setup-Fee Cost"
                                                    hint="Price per SIM"
                                                    persistent-hint
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                    :rules="[rules.required, rules.currency]"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-select
                                                    v-if="editedItem.bundle_type != 'SMART'"
                                                    v-model="selected_services"
                                                    :items="services"
                                                    label="Select services"
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                >
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="editedItem.bundle_type != 'SMART'">
                                            <v-col cols="12" sm="6">
                                                <v-switch
                                                    v-model="editedItem.economy"
                                                    inset
                                                    label="Enable Economy"
                                                    dark
                                                >
                                                </v-switch>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-switch
                                                    v-model="editedItem.flex"
                                                    inset
                                                    label="Enable Flex Usage"
                                                    dark
                                                    :disabled="editedItem.bundle_type != 'PAYG'"
                                                >
                                                </v-switch>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="editedItem.bundle_type == 'SMART'">
                                            <v-col cols="12" sm="4">
                                                <v-select
                                                    v-model="editedItem.prepaid_data"
                                                    :items="prepaid.data"
                                                    item-text="name"
                                                    item-value="id"
                                                    label="Select DATA amount"
                                                    hint="Data per month"
                                                    persistent-hint
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                >
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="4">
                                                <v-select
                                                    v-model="editedItem.prepaid_sms"
                                                    :items="prepaid.sms"
                                                    item-text="name"
                                                    item-value="id"
                                                    label="Select SMS amount"
                                                    hint="SMS per month"
                                                    persistent-hint
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                >
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="4">
                                                <v-select
                                                    v-model="editedItem.prepaid_voice"
                                                    :items="prepaid.voice"
                                                    item-text="name"
                                                    item-value="id"
                                                    label="Select VOICE amount"
                                                    hint="Voice per month"
                                                    persistent-hint
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                >
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                            <v-btn color="primary" @click="validate_step_2(3)"> Continue </v-btn>
                            <v-btn tex @click="stepper--"> Back </v-btn>
                        </v-stepper-content>

                        <v-stepper-step :rules="[() => step_3_valid]" :complete="stepper > 3" step="3">
                            Select Amounts
                        </v-stepper-step>

                        <v-stepper-content step="3">
                            <v-card dark color="secondary" class="mb-12">
                                <v-card-title
                                    class="white--text"
                                    v-if="this.editedItem.bundle_type == 'PAYG' && this.editedItem.flex == false"
                                    >No bundle size needed</v-card-title
                                >
                                <v-card-text
                                    class="white--text"
                                    v-if="this.editedItem.bundle_type == 'PAYG' && this.editedItem.flex == false"
                                >
                                    Proceed to the next step.
                                </v-card-text>
                                <v-card-title
                                    class="white--text"
                                    v-if="this.editedItem.bundle_type == 'PAYG' && this.editedItem.flex == true"
                                    >Select included data</v-card-title
                                >
                                <v-card-title class="white--text" v-if="this.editedItem.bundle_type == 'POOLED'">
                                    Select the bundle size</v-card-title
                                >
                                <v-card-title class="white--text" v-if="this.editedItem.bundle_type == 'AUTO_SELECT'">
                                    Select the highest bundle size</v-card-title
                                >
                                <v-card-title
                                    class="white--text"
                                    v-if="
                                        this.editedItem.bundle_type == 'AUTO_RATED' ||
                                        this.editedItem.bundle_type == 'SMART'
                                    "
                                    >No bundle size needed</v-card-title
                                >
                                <v-card-text
                                    class="white--text"
                                    v-if="
                                        this.editedItem.bundle_type == 'AUTO_RATED' ||
                                        this.editedItem.bundle_type == 'SMART'
                                    "
                                >
                                    Proceed to the next step.
                                </v-card-text>
                                <v-card-text>
                                    <v-form ref="step_3">
                                        <v-row v-if="editedItem.flex == true">
                                            <v-col cols="12" sm="12">
                                                <v-select
                                                    v-model="flex_zone"
                                                    :items="flex_zones"
                                                    label="Select the included data zone"
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                >
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="this.show_amounts == true">
                                            <v-col cols="12" sm="8">
                                                <v-text-field
                                                    v-model="editedItem.amount"
                                                    label="Fill bundle size in MB (1 GB is 1000 MB)"
                                                    counter="9"
                                                    :rules="[rules.required, rules.max, rules.number]"
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                    type="number"
                                                    hide-spin-buttons
                                                    @wheel="$event.target.blur()"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                            <v-btn color="primary" @click="validate_step_3(4)"> Continue </v-btn>
                            <v-btn text @click="stepper--"> Back </v-btn>
                        </v-stepper-content>

                        <v-stepper-step :rules="[() => step_4_valid]" :complete="stepper > 4" step="4">
                            Select APN
                        </v-stepper-step>
                        <v-stepper-content step="4">
                            <v-card dark color="secondary" class="mb-12">
                                <v-card-title class="wihte--text">Select the correct APN</v-card-title>
                                <v-card-text class="white--text">
                                    <v-form ref="step_4">
                                        <v-row>
                                            <v-col cols="12" sm="12">
                                                <v-autocomplete
                                                    v-model="editedItem.apn"
                                                    :items="apnItems"
                                                    label="Select the correct APN"
                                                    item-text="name"
                                                    return-object
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                    :rules="[rules.required_object]"
                                                ></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                            <v-btn color="primary" @click="validate_step_4(5)"> Continue </v-btn>
                            <v-btn text @click="stepper--"> Back </v-btn>
                        </v-stepper-content>

                        <v-stepper-step :rules="[() => step_5_valid]" step="5"> Summary </v-stepper-step>
                        <v-stepper-content step="5">
                            <v-card dark color="secondary" class="mb-12">
                                <v-card-title class="white--text">New subscription summary</v-card-title>
                                <v-card-text class="white--text">
                                    <ul>
                                        <li>
                                            Customer: <strong>{{ editedItem.customer_name }}</strong>
                                        </li>
                                        <li>
                                            Operator: <strong>{{ editedItem.operator }}</strong>
                                        </li>
                                        <li>
                                            Type: <strong>{{ editedItem.bundle_type }}</strong>
                                        </li>
                                        <li>
                                            Setup-Fee: <strong>€{{ editedItem.setup_fee }} Per/SIM</strong>
                                        </li>
                                        <li>
                                            Zone: <strong>{{ editedItem.zone }}</strong>
                                        </li>
                                        <li>
                                            Economy: <strong>{{ editedItem.economy }}</strong>
                                        </li>
                                        <li>
                                            SMS: <strong>{{ editedItem.sms }}</strong>
                                        </li>
                                        <li>
                                            Voice: <strong>{{ editedItem.voice }}</strong>
                                        </li>
                                        <li>
                                            APN: <strong>{{ editedItem.apn.name }}</strong>
                                        </li>
                                        <li v-if="editedItem.bundle_type == 'PAYG' && editedItem.flex == true">
                                            Flex: <strong>{{ editedItem.flex }}</strong>
                                        </li>
                                        <li
                                            v-if="
                                                this.editedItem.bundle_type != 'AUTO_RATED' &&
                                                this.editedItem.bundle_type != 'SMART'
                                            "
                                        >
                                            Data Amout: <strong>{{ editedItem.amount }}MB</strong>
                                        </li>
                                        <li v-if="this.editedItem.bundle_type == 'SMART'">
                                            Prepaid Rateplan:
                                            <strong>{{
                                                editedItem.prepaid_data +
                                                editedItem.prepaid_sms +
                                                editedItem.prepaid_voice
                                            }}</strong>
                                        </li>
                                    </ul>
                                </v-card-text>
                            </v-card>
                            <v-btn color="primary" @click="save"> Continue </v-btn>
                            <v-btn text @click="stepper--"> Back </v-btn>
                        </v-stepper-content>
                    </v-stepper>
                    <div v-else>
                        <v-card dark color="secondary" class="mb-6">
                            <v-card-title>
                                Change subscription for customer: {{ editedItem.customer.customer_name }}
                            </v-card-title>
                            <v-form ref="edit_subscription">
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-select
                                                v-model="editedItem.operator"
                                                :items="operators"
                                                :rules="[rules.required]"
                                                label="Select the operator"
                                                name="operator"
                                                item-text="name"
                                                item-value="id"
                                                dense
                                                outlined
                                                hide-details="auto"
                                            >
                                                <template slot="selection" slot-scope="data">
                                                    <img
                                                        :src="getImgPath(data.item.name)"
                                                        class="pr-3"
                                                        style="width: 72px; max-height: 24px"
                                                    />
                                                    <span>{{ data.item.name }}</span>
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    <img
                                                        :src="getImgPath(data.item.name)"
                                                        class="pr-3"
                                                        style="width: 72px; max-height: 24px"
                                                    />

                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                    </v-list-item-content>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-select
                                                v-model="selected_services"
                                                :items="services"
                                                label="Select services"
                                                outlined
                                                dense
                                                hide-details="auto"
                                            >
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-select
                                                v-model="editedItem.bundle_type"
                                                :items="generate_bundle_items"
                                                label="Select bundle type"
                                                :rules="[rules.required]"
                                                outlined
                                                dense
                                                hide-details="auto"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-select
                                                v-model="editedItem.zone"
                                                :items="generate_zones"
                                                label="Select roaming zone"
                                                :rules="[rules.required]"
                                                outlined
                                                dense
                                                hide-details="auto"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="editedItem.bundle_type == 'SMART'">
                                        <v-col cols="12" sm="4">
                                            <v-select
                                                v-model="editedItem.prepaid_data"
                                                :items="prepaid.data"
                                                item-text="name"
                                                item-value="id"
                                                label="Select DATA amount"
                                                hint="Data per month"
                                                persistent-hint
                                                outlined
                                                dense
                                                hide-details="auto"
                                            >
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-select
                                                v-model="editedItem.prepaid_sms"
                                                :items="prepaid.sms"
                                                item-text="name"
                                                item-value="id"
                                                label="Select SMS amount"
                                                hint="SMS per month"
                                                persistent-hint
                                                outlined
                                                dense
                                                hide-details="auto"
                                            >
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-select
                                                v-model="editedItem.prepaid_voice"
                                                :items="prepaid.voice"
                                                item-text="name"
                                                item-value="id"
                                                label="Select VOICE amount"
                                                hint="Voice per month"
                                                persistent-hint
                                                outlined
                                                dense
                                                hide-details="auto"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field
                                                v-model="editedItem.setup_fee"
                                                label="Fill the Setup-Fee Cost"
                                                hint="Price per SIM"
                                                persistent-hint
                                                outlined
                                                dense
                                                hide-details="auto"
                                                :rules="[rules.required, rules.currency]"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-select
                                                v-model="editedItem.simcard_type"
                                                :items="simcard_types"
                                                label="Select the SIM card type"
                                                outlined
                                                dense
                                                hide-details="auto"
                                            >
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="4" v-if="editedItem.flex == true">
                                            <v-select
                                                v-model="flex_zone"
                                                :items="flex_zones"
                                                label="Select the included data zone"
                                                outlined
                                                dense
                                                hide-details="auto"
                                            >
                                            </v-select>
                                        </v-col>
                                        <v-col v-if="this.editedItem.bundle_type == 'SMART'" cols="12" sm="4">
                                            <v-text-field
                                                v-model="editedItem.amount"
                                                label="Fill the amount of SmartSim Credits"
                                                outlined
                                                dense
                                                hide-details="auto"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col v-if="this.show_amounts == true" cols="12" sm="6">
                                            <v-text-field
                                                v-model="editedItem.amount"
                                                label="Fill bundle size in MB (1 GB is 1000 MB)"
                                                :rules="[rules.required, rules.max, rules.number]"
                                                counter="9"
                                                outlined
                                                dense
                                                type="number"
                                                hide-spin-buttons
                                                class="mb-4"
                                                @wheel="$event.target.blur()"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="12">
                                            <v-autocomplete
                                                v-model="editedItem.apn"
                                                :items="apnItems"
                                                item-text="name"
                                                return-object
                                                label="Select the correct APN"
                                                outlined
                                                dense
                                                hide-details="auto"
                                                :rules="[rules.required_object]"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row class="ma-2">
                                        <v-col cols="12" sm="6">
                                            <v-switch
                                                v-model="editedItem.economy"
                                                inset
                                                label="Enable Economy usage"
                                            ></v-switch>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-switch
                                                v-model="editedItem.flex"
                                                inset
                                                label="Enable Flex usage"
                                                :disabled="editedItem.bundle_type != 'PAYG'"
                                            ></v-switch>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-form>
                        </v-card>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary darken-1" text @click.native="close">Close</v-btn>
                    <v-btn
                        v-if="!new_item"
                        :disabled="saveButtonState"
                        color="primary darken-1"
                        text
                        @click.native="save"
                        >Save</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <h1 class="mb-4">Subscriptions</h1>

        <v-toolbar dense dark>
            <v-text-field
                label="Search subscription..."
                name="searchfield"
                prepend-icon="mdi-text-box-search"
                v-model="search"
                clearable
                hide-details
            >
            </v-text-field>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn text @click="showEditDialog(true)">
                    <v-icon left>mdi-text-box-plus</v-icon>New Subscription
                </v-btn>
                <v-menu offset-y>
                    <template v-slot:[`activator`]="{ on }">
                        <v-btn text v-on="on" :disabled="toggleActionsButton">
                            <v-icon left>mdi-ballot-outline</v-icon>
                            Actions
                        </v-btn>
                    </template>
                    <v-list v-if="selected.length == 1" dense>
                        <v-list-item-group color="primary">
                            <v-list-item @click="showEditDialog()">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-pencil</v-icon>
                                        Edit Subscription
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table
            v-model="selected"
            :loading="pending"
            :headers="headers"
            :items="subscriptionItems"
            :search="search"
            :mobile-breakpoint="0"
            :footer-props="{ itemsPerPageOptions: [50, 100, 500] }"
            no-results-text="Loading data..."
            class="elevation-1"
            item-key="id"
            sort-by="customer_name"
            sort-desc
            show-select
            single-select
            @click:row="clickSelect"
        >
            <template v-slot:[`item.operator`]="{ item }">
                <img :src="getImgUrl(item.operator)" class="pr-3" style="width: 72px; max-height: 24px" />
            </template>
            <template v-slot:[`item.sms`]="{ item }">
                <v-icon :color="getIconColor(item.sms)">{{ getIcon(item.sms) }}</v-icon>
            </template>
            <template v-slot:[`item.voice`]="{ item }">
                <v-icon :color="getIconColor(item.voice)">{{ getIcon(item.voice) }}</v-icon>
            </template>
            <template v-slot:[`item.zone`]="{ item }">
                <span v-if="item.flex == true"> {{ item.flex_zone }}</span>
                <span v-else>{{ item.zone }}</span>
            </template>
            <template v-slot:[`item.economy`]="{ item }">
                <v-icon :color="getIconColor(item.economy)">{{ getIcon(item.economy) }}</v-icon>
            </template>
            <template v-slot:[`item.flex`]="{ item }">
                <v-icon :color="getIconColor(item.flex)">{{ getIcon(item.flex) }}</v-icon>
            </template>
            <template v-slot:[`item.setup_fee`]="{ item }">
                <span>€{{ item.setup_fee }} p/sim</span>
            </template>
        </v-data-table>
        <v-snackbar
            centered
            top
            :color="snackbar.color"
            v-model="snackbar.state"
            :timeout="snackbar.timeout"
            :multi-line="snackbar.multiLine"
        >
            {{ snackbar.message }}
            <template v-if="snackbar.persistent" v-slot:action="{ attrs }">
                <v-btn icon v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
export default {
    data() {
        return {
            search: null,
            color: 'error',
            disabled: true,
            step_1_valid: true,
            step_2_valid: true,
            step_3_valid: true,
            step_4_valid: true,
            step_5_valid: true,
            edit_subscription_valid: false,
            snackbar: {
                state: false,
                color: '',
                message: '',
                timeout: 5000,
                multiline: true,
                persistent: false,
            },
            headers: [
                { text: 'Customer', value: 'customer.customer_name' },
                { text: 'Name', value: 'name' },
                { text: 'Type', value: 'bundle_type' },
                { text: 'Operator', value: 'operator' },
                { text: 'SIM Type', value: 'simcard_type' },
                { text: 'SMS', value: 'sms' },
                { text: 'Voice', value: 'voice' },
                { text: 'Zone', value: 'zone' },
                { text: 'Economy', value: 'economy' },
                { text: 'Flex', value: 'flex' },
                { text: 'APN', value: 'apn.name' },
                { text: 'Setup-Fee', value: 'setup_fee' },
            ],
            stepper: 1,
            selected: [],
            dialog_edit: false,
            new_item: false,
            support_24x7: ['Yes', 'No'],
            operators: [
                { name: 'Tele2', id: 'tele2' },
                { name: 'KPN Roosendaal', id: 'kpn_rd' },
                { name: 'KPN Eindhoven', id: 'kpn_eh' },
                { name: 'Telenor', id: 'telenor' },
                { name: 'T-mobile', id: 't_mobile' },
                { name: 'Vodafone', id: 'vodafone' },
                { name: 'Conexa London', id: 'conexa_ld' },
                { name: 'Conexa Oslo', id: 'conexa_os' },
            ],
            access_point_names: {
                kpn_eh: ['wlbenelux.iot', 'simpoint.m2m'],
                kpn_rd: ['m2mc.simservices', 'wlbenelux.iot', 'm2m.nuon(Feenstra)'],
                tele2: ['m2mc.simservices', 'wocozon.tele2.m2m', 'simpoint.m2m'],
                t_mobile: ['m2mu.simservices'],
                telenor: ['m2mc.simservices'],
                vodafone: ['m2mc.simservices'],
                conexa_ld: ['wlbenelux.iot'],
                conexa_os: ['wlbenelux.iot'],
            },
            simcard_types: ['REGULAR', 'CHIP', 'INDUSTRIAL'],
            services: ['DATA', 'DATA SMS', 'DATA SMS VOICE'],
            selected_services: 'DATA',
            flex_zones: ['NO DATA INCLUDED', 'NL DATA INCLUDED', 'EU DATA INCLUDED'],
            flex_zone: 'NO DATA INCLUDED',
            show_amounts: false,
            prepaid: {
                data: [
                    { name: 'Small (0-2 MB)', id: 'S' },
                    { name: 'Medium (3-69 MB)', id: 'M' },
                    { name: 'Large (70+ MB)', id: 'L' },
                ],
                sms: [
                    { name: 'Small (0-8)', id: 'S' },
                    { name: 'Medium (9-160)', id: 'M' },
                    { name: 'Large (161+)', id: 'L' },
                ],
                voice: [
                    { name: 'Small (0-15)', id: 'S' },
                    { name: 'Medium (16-55)', id: 'M' },
                    { name: 'Large(56+', id: 'L' },
                ],
            },
            editedItem: {
                amount: 1,
                customer: {},
                operator: '',
                bundle_type: '',
                zone: '',
                economy: false,
                flex: false,
                flex_zone: '',
                sms: false,
                voice: false,
                prepaid_credits: '',
                prepaid_data: 'S',
                prepaid_sms: 'S',
                prepaid_voice: 'S',
                prepaid_credits: '',
                setup_fee: '4.00',
                simcard_type: 'REGULAR',
                apn: {},
            },
            rules: {
                required: (value) => !!value || 'Required.',
                required_object: (value) => !_.isEmpty(value) || 'Required',
                min: (v) => v.length >= 8 || 'Minimum of 8 characters.',
                max: (v) => v.toString().length <= 9 || 'Maximum of 9 characters.',
                number: (v) => {
                    const pattern = /^\d*(\.\d{1,2})?$/
                    return pattern.test(v) || 'Can only contain numbers'
                },
                phone: (v) => {
                    const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
                    return pattern.test(v) || 'Invalid phone nubmer'
                },
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
                currency: (value) => {
                    const pattern = /^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/
                    return pattern.test(value) || 'Invalid currency format!'
                },
            },
        }
    },
    watch: {
        editedItem() {
            console.log(this.editedItem)
        },
        selected_services() {
            if (this.selected_services == 'DATA') {
                this.editedItem.sms = false
                this.editedItem.voice = false
            }
            if (this.selected_services == 'DATA SMS') {
                this.editedItem.sms = true
                this.editedItem.voice = false
            }
            if (this.selected_services == 'DATA SMS VOICE') {
                this.editedItem.sms = true
                this.editedItem.voice = true
            }
        },
        flex_zone() {
            if (this.flex_zone == 'NO DATA INCLUDED') {
                this.show_amounts = false
                this.editedItem.flex_zone = ''
            }
            if (this.flex_zone == 'NL DATA INCLUDED') {
                this.show_amounts = true
                this.editedItem.zone = 'NL'
            }
            if (this.flex_zone == 'EU DATA INCLUDED') {
                this.show_amounts = true
                this.editedItem.zone = 'EU'
            }
        },
        selected() {
            if (this.selected.length == 1) {
                if (this.selected[0].sms == 1 && this.selected[0].voice == 0) {
                    this.selected_services = 'DATA SMS'
                }
                if (this.selected[0].sms == 1 && this.selected[0].voice == 1) {
                    this.selected_services = 'DATA SMS VOICE'
                }
                if (this.selected[0].bundle_type == 'SMART') {
                    if (this.selected[0].name.length == 3) {
                        const chars = this.selected[0].name.split('')
                        const data = chars[0]
                        const sms = chars[1]
                        const voice = chars[2]

                        this.selected[0].prepaid_data = data
                        this.selected[0].prepaid_sms = sms
                        this.selected[0].prepaid_voice = voice
                    }
                    if (this.selected[0].name.length == 5) {
                        const chars = this.selected[0].name.split('')
                        const data = 'Lnl'
                        const sms = chars[3]
                        const voice = chars[4]

                        this.selected[0].prepaid_data = data
                        this.selected[0].prepaid_sms = sms
                        this.selected[0].prepaid_voice = voice
                    }
                    if (this.selected[0].name.length == 8) {
                        const chars = this.selected[0].name.split('')
                        const data = 'Leueco'
                        const sms = chars[6]
                        const voice = chars[7]

                        this.selected[0].prepaid_data = data
                        this.selected[0].prepaid_sms = sms
                        this.selected[0].prepaid_voice = voice
                    }
                }
            }
        },
        editedItem: {
            deep: true,
            handler() {
                if (this.editedItem.bundle_type == 'PAYG') {
                    this.show_amounts = false
                    if (this.editedItem.flex == true) {
                        if (this.flex_zone == 'NL DATA INCLUDED') {
                            this.show_amounts = true
                        }
                        if (this.flex_zone == 'EU DATA INCLUDED') {
                            this.show_amounts = true
                        }
                    }
                }
                if (this.editedItem.bundle_type == 'POOLED') {
                    this.editedItem.flex = false
                    this.show_amounts = true
                }
                if (this.editedItem.bundle_type == 'AUTO_SELECT') {
                    this.editedItem.flex = false
                    this.show_amounts = true
                }
                if (this.editedItem.bundle_type == 'AUTO_RATED') {
                    this.editedItem.flex = false
                    this.show_amounts = false
                }
                if (this.editedItem.bundle_type == 'SMART') {
                    this.editedItem.sms = true
                    this.editedItem.voice = true
                    if (this.editedItem.operator == 'kpn_rd') {
                        const object_lnl = { name: 'Large NL', id: 'Lnl' }
                        const object_lec = { name: 'Large EU Economy', id: 'Leueco' }

                        this.prepaid.data.push(object_lnl)
                        this.prepaid.data.push(object_lec)
                        if (
                            this.editedItem.prepaid_data == 'S' ||
                            this.editedItem.prepaid_data == 'M' ||
                            this.editedItem.prepaid_data == 'L'
                        ) {
                            this.editedItem.zone = 'EU'
                        }
                        if (this.editedItem.prepaid_data == 'Leueco') {
                            this.editedItem.zone = 'EU'
                            this.editedItem.economy = true
                        }
                        if (this.editedItem.prepaid_data == 'Lnl') {
                            this.editedItem.zone = 'NL'
                        }
                    }
                    if (this.editedItem.operator == 'tele2') {
                        this.editedItem.zone = 'GL'
                        this.prepaid.data = this.prepaid.data.filter((item) => item.id !== 'Lnl')
                        this.prepaid.data = this.prepaid.data.filter((item) => item.id !== 'Leueco')
                    }
                }
            },
        },
    },
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
            customers: (state) => state.calls.customer_list,
            subscriptions: (state) => state.calls.subscription_list,
            apns: (state) => state.calls.apn_list,
        }),
        toggleActionsButton() {
            return this.selected.length ? false : true
        },
        customerLoading() {
            return this.customers ? this.customers.pending : true
        },
        customerItems() {
            return this.customers ? this.customers.payload : []
        },
        subscriptionItems() {
            return this.subscriptions ? this.subscriptions.payload : []
        },
        apnItems() {
            return this.apns ? this.apns.payload : []
        },
        saveButtonState() {
            if (this.selected.length <= 1) {
                return this.editedItem.operator &&
                    this.editedItem.bundle_type &&
                    this.editedItem.zone &&
                    this.editedItem.amount
                    ? false
                    : true
            }
        },
        generate_zones() {
            const available_zones = [{text: 'Netherlands', value: 'NL'}, {text: 'Europe', value: 'EU'}, {text: 'Europe Zone 1', value: 'EU1'}, {text: 'Europe Zone 2', value: 'EU2'}, {text: 'Europe Zone 3', value: 'EU3'}, {text: 'Europe Zone 4', value: 'EU4'}, {text: 'Europe Zone 5', value: 'EU5'}, {text: 'Europe Zone 6', value: 'EU6'}, {text: 'Europe Zone 7', value: 'EU7'}, {text: 'Europe Zone 8', value: 'EU8'}, {text: 'Global', value: 'GL'}, {text: 'Global Select', value: 'GS'}, {text: 'Global Extended', value: 'GE'}, {text: 'Global Zone 1', value: 'G1'}, {text: 'Global Zone 2', value: 'G2'}, {text: 'Global Zone 3', value: 'G3'}, {text: 'Global Zone 4', value: 'G4'}, {text: 'Global Zone 5', value: 'G5'}, {text: 'Global Zone 6', value: 'G6'}, {text: 'Global Zone 7', value: 'G7'}, {text: 'Global Zone 8', value: 'G8'}]

            if (this.editedItem.operator == 'kpn_rd') {
                const exclude = ['EU1', 'EU2', 'EU3', 'EU4', 'EU5', 'EU6', 'EU7', 'EU8', 'G7', 'G8']
                const zones = available_zones.filter((item) => !exclude.includes(item.value))
                return (this.zones = zones)
            }
            if (this.editedItem.operator == 'kpn_eh') {
                const exclude = ['EU1', 'EU2', 'EU3', 'EU4', 'EU5', 'EU6', 'EU7', 'EU8', 'G7', 'G8']
                const zones = available_zones.filter((item) => !exclude.includes(item.value))
                return (this.zones = zones)
            }
            if (this.editedItem.operator == 'tele2') {
                const exclude = ['NL', 'EU1', 'EU2', 'EU3', 'EU4', 'EU5', 'EU6', 'EU7', 'EU8', 'G1', 'G6', 'G7', 'G8']
                const zones = available_zones.filter((item) => !exclude.includes(item.value))
                return (this.zones = zones)
            }
            if (this.editedItem.operator == 'telenor') {
                const exclude = ['NL', 'EU1', 'EU2', 'EU3', 'EU4', 'EU5', 'EU6', 'EU7', 'EU8', 'GL', 'GE', 'G1', 'G2', 'G3', 'G4', 'G5', 'G6', 'G7', 'G8']
                const zones = available_zones.filter((item) => !exclude.includes(item.value))
                return (this.zones = zones)
            }
            if (this.editedItem.operator == 't_mobile') {
                if (this.editedItem.flex == true) {
                    const exlude = ['EU1', 'EU2', 'EU3', 'EU4', 'EU5', 'EU6', 'EU7', 'EU8', 'GE', 'G1', 'G2', 'G3', 'G4', 'G5', 'G6', 'G7', 'G8']
                    const zones = available_zones.filter((item) => !exlude.includes(item.value))
                    return (this.zones = zones)
                } else {
                    const exlude = ['EU1', 'EU2', 'EU3', 'EU4', 'EU5', 'EU6', 'EU7', 'EU8', 'GE', 'GL', 'G1', 'G2', 'G3', 'G4', 'G5', 'G6', 'G7', 'G8']
                    return (this.zones = [{text: 'NL', value: 'NL'}, {text: 'EU', value: 'EU'}, {text: 'GS', value: 'GS'}])
                }
            }
            if (this.editedItem.operator == 'vodafone') {
                const exclude = ['NL', 'EU1', 'EU2', 'EU3', 'EU4', 'EU5', 'EU6', 'EU7', 'EU8', 'GL', 'GE', 'GS', 'G1', 'G2', 'G3', 'G4', 'G5', 'G6', 'G7', 'G8']
                const zones = available_zones.filter((item) => !exclude.includes(item.value))
                this.editedItem.zone = 'EU'
                return (this.zones = zones)
            }
            if (this.editedItem.operator == 'conexa_ld' || this.editedItem.operator == 'conexa_os') {
                const exclude = ['NL', 'EU', 'GL', 'GE', 'GS']
                const zones = available_zones.filter((item) => !exclude.includes(item.value))
                return (this.zones = zones)
            }
        },
        generate_bundle_items() {
            if (this.editedItem.operator == 'kpn_rd' || this.editedItem.operator == 'tele2') {
                return ['PAYG', 'POOLED', 'AUTO_SELECT', 'AUTO_RATED', 'SMART']
            } else if (this.editedItem.operator == 'conexa_os' || this.editedItem.operator == 'conexa_ld') {
                return ['PAYG', 'POOLED', 'AUTO_SELECT', 'AUTO_RATED']
            } else {
                return ['PAYG', 'POOLED', 'AUTO_SELECT', 'AUTO_RATED']
            }
        },
    },
    methods: {
        toggleSnackbar(persistent, color, message) {
            this.snackbar.state = true
            this.snackbar.color = color
            this.snackbar.message = message

            if (persistent == true) {
                this.snackbar.persistent = true
                this.snackbar.timeout = 0
            }
        },
        getImgPath(img) {
            const telecoms = [
                'arkessa',
                'airtel',
                'kpn',
                't-mobile',
                'orange',
                'tele2',
                'vodafone',
                'base',
                'o2',
                'proximus',
                'telefonica',
                'telenor',
                'three',
                'mobistar',
                'telekom',
                'cable',
                'digicel',
            ]

            const i = telecoms.findIndex((v) => img.toLowerCase().includes(v))
            if (i !== -1) {
                return '/img/' + telecoms[i] + '.svg'
            }

            return '/img/mobilenetwork.svg'
        },
        showEditDialog(new_item = false) {
            if (new_item) {
                this.new_item = true
                this.dialog_edit = true
            } else if (this.selected.length > 0) {
                this.new_item = false
                if (this.selected.length == 1) {
                    this.editedItem = Object.assign({}, this.selected[0])
                    this.getApns()
                }

                switch (this.editedItem.flex_zone) {
                    case 'EU':
                        this.flex_zone = 'EU DATA INCLUDED'
                        break
                    case 'NL':
                        this.flex_zone = 'NL DATA INCLUDED'
                        break
                    default:
                        this.flex_zone = 'NO DATA INCLUDED'
                }
                console.log(this.editedItem)
                this.dialog_edit = true
            }
        },
        clickSelect(item) {
            Vue.set(this.selected, 0, item)
        },
        validate_step_1(step = false) {
            if (this.$refs.step_1.validate()) {
                this.step_1_valid = true

                // Also activate stepper
                if (step) {
                    this.stepper = step
                }
                this.editedItem.customer_name = this.customers.payload.filter((obj) => {
                    return obj.id === this.editedItem.customer.id
                })[0].customer_name
            } else {
                this.step_1_valid = false
            }
        },
        validate_step_2(step = false) {
            if (this.$refs.step_2.validate()) {
                this.step_2_valid = true

                // Also activate stepper
                if (step) {
                    this.stepper = step
                }
                if (
                    this.editedItem.bundle_type == 'AUTO_RATED' ||
                    (this.editedItem.bundle_type == 'PAYG' && this.editedItem.flex == false) ||
                    this.editedItem.bundle_type == 'SMART'
                ) {
                    this.stepper++
                    this.getApns()
                }
            } else {
                this.step_2_valid = false
            }
        },
        validate_step_3(step = false) {
            if (this.$refs.step_3.validate()) {
                this.step_3_valid = true

                // If valid get APN list
                this.getApns()

                // Also activate stepper
                if (step) {
                    this.stepper = step
                }
            } else {
                this.step_3_valid = false
            }
        },
        validate_step_4(step = false) {
            if (this.$refs.step_4.validate()) {
                this.step_4_valid = true

                // Also activate stepper
                if (step) {
                    this.stepper = step
                }
            } else {
                this.step_4_valid = false
            }
        },
        save() {
            var setup_fee = this.editedItem.setup_fee
            if (typeof setup_fee == 'string') {
                setup_fee = parseFloat(setup_fee).toFixed(2)
            } else {
                setup_fee.toFixed(2)
            }

            var amount = this.editedItem.amount
            if (typeof amount == 'number') {
                amount = amount.toString()
            }

            const data = {
                id: this.editedItem.id,
                customer: {
                    id: this.editedItem.customer.id,
                },
                amount: amount,
                bundle_type: this.editedItem.bundle_type,
                operator: this.editedItem.operator,
                zone: this.editedItem.zone,
                sms: this.editedItem.sms,
                voice: this.editedItem.voice,
                economy: this.editedItem.economy,
                flex: this.editedItem.flex,
                setup_fee: setup_fee,
                simcard_type: this.editedItem.simcard_type,
                apn: {
                    id: this.editedItem.apn.id,
                },
            }
            if (data.bundle_type == 'SMART') {
                data.name = this.editedItem.prepaid_data + this.editedItem.prepaid_sms + this.editedItem.prepaid_voice
            }
            console.log(data)
            if (this.new_item) {
                this.$store
                    .dispatch('api/call', {
                        method: 'post',
                        url: '/root/subscriptions',
                        data: { ...data },
                    })
                    .then(
                        (response) => {
                            this.toggleSnackbar(false, 'success', response.data.description)
                            this.get_subscriptions()
                            this.close()
                        },
                        (error) => {
                            console.log(this.payload)
                            this.toggleSnackbar(true, 'error', this.payload.description)
                        }
                    )
            } else if (this.selected.length == 1) {
                if (this.$refs.edit_subscription.validate()) {
                    this.$store
                        .dispatch('api/call', {
                            method: 'put',
                            url: '/root/subscriptions',
                            data: { ...data },
                        })
                        .then(
                            (response) => {
                                this.toggleSnackbar(false, 'success', response.data.description)
                                this.get_subscriptions()
                                this.close()
                            },
                            (error) => {
                                this.toggleSnackbar(true, 'error', this.payload.description)
                            }
                        )
                }
            }
            this.close()
        },
        getImgUrl(img) {
            const operators = {
                kpn_rd: 'kpn',
                kpn_eh: 'kpn',
                telenor: 'telenor',
                tele2: 'tele2',
                t_mobile: 't-mobile',
                arkessa: 'arkessa',
                vodafone: 'vodafone',
                conexa_ld: 'mobilenetwork',
                conexa_os: 'mobilenetwork',
            }
            return '/img/' + operators[img] + '.svg'
        },
        getIcon(state) {
            if (state == 0) {
                return 'mdi-close-circle'
            } else {
                return 'mdi-check-circle'
            }
        },
        getIconColor(state) {
            if (state == 0) {
                return 'error'
            } else {
                return 'success'
            }
        },
        close() {
            this.dialog_edit = false
            this.stepper = 1
            this.new_item = false
            this.selected = []
            this.selected_services = 'DATA'
            this.editedItem = {
                customer: {},
                economy: false,
                flex: false,
                sms: false,
                voice: false,
                prepaid_data: 'S',
                prepaid_sms: 'S',
                prepaid_voice: 'S',
                setup_fee: '4.00',
                simcard_type: 'REGULAR',
                amount: 1,
                apn: {},
            }
        },
        get_subscriptions() {
            this.$store
                .dispatch('api/call', {
                    name: 'subscription_list',
                    url: '/root/subscriptions',
                })
                .then((response) => {
                    this.subscriptions.payload.sort((a, b) =>
                        a.customer.customer_name.localeCompare(b.customer.customer_name)
                    )
                })
        },
        getCustomers() {
            this.$store
                .dispatch('api/call', {
                    name: 'customer_list',
                    url: '/root/customers',
                    params: {
                        only: 'id, customer_name',
                    },
                })
                .then((response) => {
                    this.customers.payload.sort((a, b) => a.customer_name.localeCompare(b.customer_name))

                    for (var i = 0; i < this.customers.payload.length; i++) {
                        this.customers.payload[i]['customer_name'] += ` (${this.customers.payload[i]['id']})`
                    }
                })
        },
        getApns() {
            const data = {
                customer_id: this.editedItem.customer.id,
                operator: this.editedItem.operator,
                // zone: this.editedItem.zone,
                sms: this.editedItem.sms,
                voice: this.editedItem.voice,
                economy: this.editedItem.economy,
            }
            this.$store
                .dispatch('api/call', {
                    name: 'apn_list',
                    url: '/root/apn',
                    params: {
                        customer_id: this.editedItem.customer.id,
                        operator: this.editedItem.operator,
                        sms: this.editedItem.sms,
                        voice: this.editedItem.voice,
                        economy: this.editedItem.economy,
                    },
                })
                .then((response) => {})
        },
    },
    created() {
        this.$store
            .dispatch('api/call', {
                name: 'customer_list',
                url: '/root/customers',
            })
            .then((response) => {
                this.customers.payload.sort((a, b) => a.customer_name.localeCompare(b.customer_name))

                for (var i = 0; i < this.customers.payload.length; i++) {
                    this.customers.payload[i]['customer_name'] += ` (${this.customers.payload[i]['id']})`
                }
            })
        this.$store
            .dispatch('api/call', {
                name: 'subscription_list',
                url: '/root/subscriptions',
                params: { bundle_type_skip: 'TEST,NEW' },
            })
            .then((response) => {
                this.subscriptions.payload.sort((a, b) =>
                    a.customer.customer_name.localeCompare(b.customer.customer_name)
                )
            })
    },
}
</script>
