<template>
  <v-app>
    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>Help</v-card-title>
        <v-card-text>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Nederlands
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>
                  Hier kunnen klanten van Sim Services inloggen in het nieuwe Dashboard. Voor 28
                  januari 2020 kon dit zonder gebruik te maken van een wachtwoord. Dit is vanaf deze
                  datum veranderd.
                </p>

                <p>
                  Als u nu in wenst te loggen met uw email adres zonder dat u een password had
                  ingesteld voor uw account, kunt u een password instellen door te klikken op ‘Forgot
                  password?’.
                </p>

                <p>
                  Indien u een username en password heeft, maar toch problemen ervaart met het
                  inloggen, kunt u contact opnemen met Sim Services support op support@simservices.com
                  of tijdens kantooruren via +31 88035 0099.
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>English</v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>
                  Customers of Sim Services or SmartSim users can log in to the new Dashboard here.
                  Before January 28th 2020, it was possible to log in without using a password.
                  Starting from this day, this has been changed.
                </p>

                <p>
                  When you want to log into the Dashboard account when you never had to set a password
                  before, you can set a password by entering your email address and clicking the
                  ‘Forgot password?’ button.
                </p>

                <p>
                  When you have an account with a password and you are still experiencing issues
                  logging in, please contact Sim Services support on support@simservices.com or by
                  calling +31 88035 0099 during office hours.
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12">
              <v-toolbar
                :color="$cfg.theme.app_bar.background"
                flat
              >
                <img
                  :src="logo"
                  :width="$cfg.theme.logo.width"
                  :height="$cfg.theme.logo.height"
                />
                <v-toolbar-title :class="'ml-3 ' + $cfg.theme.app_bar.text">Dashboard</v-toolbar-title>
                <v-spacer />

                <v-tooltip right>
                  <template v-slot:[`activator`]="{ on }">
                    <v-btn
                      :class="$cfg.theme.app_bar.text"
                      icon
                      large
                      v-on="on"
                      @click.stop="dialog = true"
                    >
                      <v-icon>mdi-help-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Help</span>
                </v-tooltip>
              </v-toolbar>
              <div v-if="forms.create.visible">
                <v-card-text v-if="forms.create.success">
                  <v-alert
                    prominent
                    type="success"
                    transition="fade-transition"
                  >
                    Your account has been created, but hasn't been activated yet.<br /><br />
                    We've sent an activation link to:<br />
                    <span class="font-weight-bold">{{ forms.create.fields.email }}</span>
                  </v-alert>
                  <div class="text-center">
                    <v-btn
                      color="grey darken-2"
                      small
                      block
                      outlined
                      :disabled="forms.create.status.resend_disabled"
                      @click="create()"
                    >
                      Resend Activation Email
                    </v-btn>
                  </div>
                </v-card-text>
                <v-card-text v-else-if="forms.create.error">
                  <v-alert
                    prominent
                    type="error"
                    transition="fade-transition"
                  >
                    {{ forms.create.message }}
                  </v-alert>
                  <div class="text-center">
                    <v-btn
                      color="grey darken-2"
                      small
                      block
                      outlined
                      @click="show_form('login')"
                    >
                      Log in to Dashboard
                    </v-btn>
                  </div>
                </v-card-text>
                <v-form
                  v-model="forms.create.valid"
                  v-else
                >
                  <v-card-text>
                    <v-text-field
                      v-model="forms.create.fields.name"
                      :rules="[rules.required]"
                      name="name"
                      label="Name"
                      prepend-icon="mdi-account"
                      required
                    ></v-text-field>
                    <v-text-field
                      v-model="forms.create.fields.email"
                      :rules="[rules.required, rules.email]"
                      name="email"
                      label="Email"
                      type="email"
                      prepend-icon="mdi-email"
                      required
                    ></v-text-field>
                    <v-text-field
                      v-model="forms.create.fields.password"
                      :append-icon="forms.create.status.show_password ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.min]"
                      :type="forms.create.status.show_password ? 'text' : 'password'"
                      prepend-icon="mdi-key"
                      name="password"
                      label="Password"
                      counter
                      @click:append="forms.create.status.show_password = !forms.create.status.show_password"
                    ></v-text-field>
                    <v-text-field
                      v-model="forms.create.fields.confirm_password"
                      :append-icon="forms.create.status.show_confirm_password ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.min, password_match]"
                      :type="forms.create.status.show_confirm_password ? 'text' : 'password'"
                      prepend-icon="mdi-key"
                      name="password"
                      label="Re-enter password"
                      counter
                      @click:append="forms.create.status.show_confirm_password = !forms.create.status.show_confirm_password"
                    ></v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      @click="show_form('login')"
                    >Cancel</v-btn>

                    <v-spacer />
                    <v-btn
                      :loading="is_submitting"
                      :disabled="!forms.create.valid"
                      color="primary"
                      @click="create()"
                    >Create Account</v-btn>
                  </v-card-actions>
                </v-form>
              </div>
              <div v-else-if="forms.forgot.visible">
                <v-card-text v-if="forms.forgot.success">
                  <v-alert
                    prominent
                    type="success"
                    transition="fade-transition"
                  >
                    Password reset email has been sent, please check your mailbox for further
                    instructions.
                  </v-alert>
                </v-card-text>

                <v-form
                  v-model="forms.forgot.valid"
                  v-else
                >
                  <v-card-text>
                    <v-alert
                      type="info"
                      prominent
                    >
                      Forgot your password? No worries. Just enter your account email and we'll
                      send you a link to reset it.
                    </v-alert>

                    <v-alert
                      prominent
                      type="error"
                      transition="fade-transition"
                      :value="forms.forgot.error"
                    >
                      {{ forms.forgot.message }}
                    </v-alert>

                    <v-text-field
                      v-model="forms.forgot.fields.email"
                      :rules="[rules.required, rules.email]"
                      name="email"
                      label="Email"
                      type="email"
                      prepend-icon="mdi-email"
                      required
                    ></v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      @click="show_form('login')"
                    >Cancel</v-btn>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="reset"
                      :loading="is_submitting"
                      :disabled="!forms.forgot.valid"
                    >Send reset email</v-btn>
                  </v-card-actions>
                </v-form>
              </div>
              <v-form
                v-model="forms.login.valid"
                @submit.prevent="login"
                v-else
              >
                <v-card-text>
                  <v-alert
                    prominent
                    type="error"
                    transition="fade-transition"
                    :value="forms.login.error"
                  >
                    {{ forms.login.message }}
                  </v-alert>

                  <v-text-field
                    v-model="forms.login.fields.email"
                    :rules="[rules.required, rules.email]"
                    name="email"
                    label="Email"
                    type="email"
                    prepend-icon="mdi-email"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="forms.login.fields.password"
                    :append-icon="forms.login.states.show_password ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="forms.login.states.show_password ? 'text' : 'password'"
                    prepend-icon="mdi-key"
                    name="password"
                    label="Password"
                    counter
                    @click:append="forms.login.states.show_password = !forms.login.states.show_password"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    type="submit"
                    color="primary"
                    @click="login"
                    :loading="is_submitting"
                    :disabled="!forms.login.valid"
                  >Log in</v-btn>
                </v-card-actions>
                <v-divider></v-divider>
                <div
                  class="text-center pa-2"
                  v-if="!forms.create.visible"
                >
                  <v-btn
                    class="mb-2"
                    color="grey darken-2"
                    small
                    block
                    outlined
                    @click="show_form('forgot')"
                  >Forgot password?</v-btn>

                  <v-btn
                    color="grey darken-2"
                    small
                    block
                    outlined
                    @click="show_form('create')"
                  >
                    Don't have an account?
                  </v-btn>
                </div>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(VueReCaptcha, { siteKey: '6LdvLdQUAAAAAHmmZYw2ckUx6Kvok2nx3w2bMh5F' })

export default {
    data() {
        return {
            forms: {
                login: {
                    visible: true,
                    valid: true,
                    fields: { email: '', password: '' },
                    states: {
                        show_password: false,
                    },
                    error: false,
                    message: '',
                },
                forgot: {
                    visible: false,
                    valid: true,
                    fields: { email: '' },
                    error: false,
                    message: '',
                    success: '',
                },
                create: {
                    visible: false,
                    valid: true,
                    fields: {
                        smartsim: false,
                        email: '',
                        name: '',
                        password: '',
                        confirm_password: '',
                    },
                    status: {
                        show_password: false,
                        show_confirm_password: false,
                        resend_disabled: false,
                    },
                    error: false,
                    message: '',
                    success: '',
                },
            },
            dialog: false,
            is_submitting: false,
            rules: {
                required: (value) => !!value || 'Required.',
                min: (v) => v.length >= 8 || 'Minimum of 8 characters.',
                email: (value) => /.+@.+\..+/.test(value) || 'Email must be valid.',
            },
        }
    },
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
        }),
        ...mapState('api', ['calls']),
        password_match() {
            return () =>
                this.forms.create.fields.password === this.forms.create.fields.confirm_password ||
                'Passwords must match.'
        },
        logo() {
            return `/themes/${this.$cfg.theme.folder}/${this.$cfg.theme.logo.filename}`
        },
    },
    methods: {
        show_form(name) {
            for (let key of Object.keys(this.forms)) {
                this.forms[key].visible = key == name ? true : false
            }

            if (name == 'forgot') {
                this.forms.forgot.fields.email = this.forms.login.fields.email
            } else if (name == 'create') {
                this.forms.create.error = false
                this.forms.create.fields.email = this.forms.login.fields.email
            }
        },
        async login() {
            this.$store.dispatch('session/login')
            this.is_submitting = true

            const token = await this.$recaptcha('login')

            this.$store
                .dispatch('api/call', {
                    url: '/authorize/login',
                    method: 'post',
                    data: {
                        email: this.forms.login.fields.email,
                        password: this.forms.login.fields.password,
                        recaptcha: token,
                    },
                })
                .then(
                    (response) => {
                        let redirect = '/'
                        
                        if (response.data.payload.is_otp_enabled) {
                            redirect = '/otp'
                        }

                        this.$store.dispatch('session/login_success', response.data.payload).then((response) => {
                            
                          document.location.href = redirect
                            
                        })
                        
                    },
                    (error) => {
                        this.forms.login.error = true
                        this.forms.login.message = this.payload.description
                        this.is_submitting = false
                    }
                )
        },
        async reset() {
            this.is_submitting = true

            const token = await this.$recaptcha('reset')

            this.$store
                .dispatch('api/call', {
                    url: '/reset',
                    method: 'post',
                    data: {
                        email: this.forms.forgot.fields.email,
                        recaptcha: token,
                    },
                })
                .then(
                    (response) => {
                        this.forms.forgot.success = true
                        this.is_submitting = false
                    },
                    (error) => {
                        this.forms.forgot.error = true
                        this.forms.forgot.message = this.payload.description
                        this.is_submitting = false
                    }
                )
        },
        async create() {
            this.is_submitting = true
            this.forms.create.status.resend_disabled = true

            const token = await this.$recaptcha('create')

            this.$store
                .dispatch('api/call', {
                    url: '/account/request',
                    method: 'post',
                    data: {
                        name: this.forms.create.fields.name,
                        email: this.forms.create.fields.email,
                        password: this.forms.create.fields.password,
                        smartsim: this.forms.create.fields.smartsim,
                        recaptcha: token,
                        origin: this.$cfg.origin,
                    },
                })
                .then(
                    (response) => {
                        this.forms.create.success = true
                        this.is_submitting = false
                        setTimeout(() => {
                            this.forms.create.status.resend_disabled = false
                        }, 5000)
                    },
                    (error) => {
                        this.forms.create.error = true
                        this.is_submitting = false

                        switch (this.payload.name) {
                            case 'account_exists':
                                this.forms.create.message =
                                    'Could not create a new account, because this account already exists.'
                                break
                            default:
                                this.forms.create.message = 'Unknown error occurred. Could not create a new account.'
                                break
                        }
                    }
                )
        },
    },
    created() {
        if (typeof this.$route.params.segment !== 'undefined') {
            this.show_form(this.$route.params.segment)
            if (this.$route.query.type == 'smartsim') {
                this.forms.create.fields.smartsim = true
            }
        } else if (this.$cfg.new_account.smartsim) {
            this.forms.create.fields.smartsim = true
        }
    },
}
</script>
