<template>
    <div>
        <h1 class="mb-4">Wallet</h1>
        <v-alert icon="mdi-wallet" prominent text type="info">
            You have <span class="font-weight-bold">{{ wallet }}</span> credits in your wallet. You can buy more credits
            below.
        </v-alert>

        <v-stepper v-model="order_stepper" vertical>
            <v-stepper-step step="1"> How many credits would you like to buy? </v-stepper-step>

            <v-stepper-content step="1">
                <v-sheet>
                    <v-slide-group v-model="selected_credits" center-active mandatory>
                        <v-slide-item
                            v-for="(item, key) in filtered_credits"
                            :key="key"
                            v-slot:default="{ active, toggle }"
                        >
                            <v-card
                                class="ma-4"
                                height="200"
                                width="100"
                                :img="'/img/' + item.amount + '_credits.jpg'"
                                @click="toggle"
                            >
                                <v-row class="fill-height" align="center" justify="center">
                                    <v-scale-transition>
                                        <v-icon
                                            v-if="active"
                                            color="white"
                                            size="56"
                                            v-text="'mdi-check-circle'"
                                        ></v-icon>
                                    </v-scale-transition>
                                </v-row>
                            </v-card>
                        </v-slide-item>
                    </v-slide-group>
                </v-sheet>
                <div class="ma-4 mb-6">
                    <v-sheet class="pa-4" dark max-width="240" align="center" justify="center">
                        <div class="display-2">{{ price }}</div>
                        <div class="subtitle-2">(VAT excluded)</div>
                    </v-sheet>
                </div>
                <v-btn color="primary" @click="order_stepper = 2">Continue</v-btn>
            </v-stepper-content>

            <v-stepper-step step="2" :rules="[() => credentials_valid]">
                Billing Details
                <small v-if="!credentials_valid">Required fields missing!</small>
            </v-stepper-step>

            <v-stepper-content step="2">
                <v-form ref="credentials">
                    <v-card color="grey lighten-1" class="mb-6">
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="credentials_data.first_name"
                                        label="First Name"
                                        single-line
                                        solo
                                        :rules="[rules.required]"
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="credentials_data.last_name"
                                        label="Last Name"
                                        single-line
                                        solo
                                        :rules="[rules.required]"
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="credentials_data.company"
                                        label="Company"
                                        single-line
                                        solo
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-scale-transition>
                                        <v-text-field
                                            v-if="credentials_data.company"
                                            v-model="credentials_data.vat_number"
                                            label="VAT Identification Number"
                                            single-line
                                            solo
                                            :rules="[rules.required, rules.invalid_vat_format, rules.invalid_vat]"
                                            hide-details="auto"
                                            @focus="reset_vat_rule"
                                        ></v-text-field>
                                    </v-scale-transition>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="credentials_data.address"
                                        label="Address"
                                        single-line
                                        solo
                                        :rules="[rules.required]"
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="credentials_data.postal_code"
                                        label="Postal Code"
                                        single-line
                                        solo
                                        :rules="[rules.required]"
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="credentials_data.city"
                                        label="City"
                                        single-line
                                        solo
                                        :rules="[rules.required]"
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-select
                                        v-model="credentials_data.country"
                                        :items="country_list"
                                        value="NL"
                                        label="Country"
                                        solo
                                        :rules="[rules.required]"
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-btn color="primary" @click="validate_credentials(3)">Continue</v-btn>
                    <v-btn text @click="order_stepper = 1">Back</v-btn>
                </v-form>
            </v-stepper-content>

            <v-stepper-step step="3"> Confirm </v-stepper-step>

            <v-stepper-content step="3">
                <v-alert dark icon="mdi-cart">
                    You are about to order {{ filtered_credits[selected_credits].amount }} credits for
                    {{ final_price }}.
                </v-alert>

                <v-btn
                    class="mt-1"
                    color="primary"
                    @click="checkout()"
                    :disabled="is_submitting"
                    :loading="is_submitting"
                    >Checkout</v-btn
                >
                <v-btn text @click="order_stepper = 2">Back</v-btn>
            </v-stepper-content>
        </v-stepper>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { checkVAT, countries } from 'jsvat'
export default {
    data() {
        return {
            order_stepper: 1,
            selected_credits: 0,
            credentials_data: { country: 'NL' },
            credentials_valid: true,
            invalid_vat: false,
            is_submitting: false,
            rules: {
                required: (value) => !!value || 'Required.',
                invalid_vat: (value) => this.invalid_vat == false || 'VAT Identification Number is invalid!',
                invalid_vat_format: (value) =>
                    this.validate_vat_format(value) || 'VAT Identification Number is invalid!',
            },
            credits_list: [
                { amount: 1, price: this.price_index(0.2), advanced: true },
                { amount: 50, price: this.price_index(8.0) },
                { amount: 100, price: this.price_index(15.0) },
                { amount: 250, price: this.price_index(37.0) },
                { amount: 500, price: this.price_index(71.0) },
                { amount: 1000, price: this.price_index(135.0) },
                { amount: 2500, price: this.price_index(325.0) },
            ],
            country_list: [
                { value: 'AL', text: 'Albania' },
                { value: 'AD', text: 'Andorra' },
                { value: 'AT', text: 'Austria' },
                { value: 'BY', text: 'Belarus' },
                { value: 'BE', text: 'Belgium' },
                { value: 'BA', text: 'Bosnia and Herzegovina' },
                { value: 'BG', text: 'Bulgaria' },
                { value: 'HR', text: 'Croatia (Hrvatska)' },
                { value: 'CY', text: 'Cyprus' },
                { value: 'CZ', text: 'Czech Republic' },
                { value: 'DK', text: 'Denmark' },
                { value: 'EE', text: 'Estonia' },
                { value: 'FO', text: 'Faroe Islands' },
                { value: 'FI', text: 'Finland' },
                { value: 'FR', text: 'France' },
                { value: 'GE', text: 'Georgia' },
                { value: 'DE', text: 'Germany' },
                { value: 'GI', text: 'Gibraltar' },
                { value: 'GR', text: 'Greece' },
                { value: 'HU', text: 'Hungary' },
                { value: 'IS', text: 'Iceland' },
                { value: 'IE', text: 'Ireland' },
                { value: 'IT', text: 'Italy' },
                { value: 'KZ', text: 'Kazakhstan' },
                { value: 'LV', text: 'Latvia' },
                { value: 'LI', text: 'Liechtenstein' },
                { value: 'LT', text: 'Lithuania' },
                { value: 'LU', text: 'Luxembourg' },
                { value: 'MK', text: 'Macedonia' },
                { value: 'MT', text: 'Malta' },
                { value: 'MD', text: 'Moldova' },
                { value: 'MC', text: 'Monaco' },
                { value: 'NL', text: 'Netherlands' },
                { value: 'NO', text: 'Norway' },
                { value: 'PL', text: 'Poland' },
                { value: 'PT', text: 'Portugal' },
                { value: 'RO', text: 'Romania' },
                { value: 'SM', text: 'San Marino' },
                { value: 'SK', text: 'Slovak Republic' },
                { value: 'SI', text: 'Slovenia' },
                { value: 'ES', text: 'Spain' },
                { value: 'SE', text: 'Sweden' },
                { value: 'CH', text: 'Switzerland' },
                { value: 'TR', text: 'Turkey' },
                { value: 'UA', text: 'Ukraine' },
                { value: 'GB', text: 'United Kingdom' },
                { value: 'VA', text: 'Vatican City State (Holy See)' },
            ],
            vat_countries: [],
        }
    },
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
        }),
        ...mapState('session', {
            advanced_view: (state) => state.rules.advanced_view || false,
        }),
        wallet() {
            return this.$store.getters['session/credits']
        },
        filtered_credits() {
            if (this.advanced_view) {
                return this.credits_list
            } else {
                return this.credits_list.filter((h) => h.advanced != true)
            }
        },
        price() {
            const number_format = new Intl.NumberFormat('nl-NL', {
                style: 'currency',
                currency: 'EUR',
            })
            const price = parseFloat(this.filtered_credits[this.selected_credits].price)

            return number_format.format(price)
        },
        final_price() {
            const number_format = new Intl.NumberFormat('nl-NL', {
                style: 'currency',
                currency: 'EUR',
            })
            const price = parseFloat(this.filtered_credits[this.selected_credits].price)

            if (this.credentials_data.vat_number && this.credentials_data.country != 'NL') {
                return number_format.format(price) + ' (VAT excluded)'
            } else {
                return number_format.format(price + 0.21 * price) + ' (VAT included)'
            }
        },
    },
    methods: {
        reset_vat_rule() {
            this.invalid_vat = false
        },
        validate_vat_format(vat_number) {
            let result = checkVAT(vat_number, this.vat_countries)
            return result.isValid
        },
        validate_credentials(step = false) {
            if (this.$refs.credentials.validate()) {
                this.credentials_valid = true

                // Also activate stepper
                if (step) {
                    this.order_stepper = step
                }
            } else {
                this.credentials_valid = false
            }
        },
        checkout() {
            this.is_submitting = true
            this.$store
                .dispatch('api/call', {
                    method: 'post',
                    url: '/order',
                    data: {
                        credits: this.filtered_credits[this.selected_credits].amount.toString(),
                        ...this.credentials_data,
                    },
                })
                .then(
                    (response) => {
                        window.location = response.data.payload
                    },
                    (error) => {
                        this.is_submitting = false

                        if (this.payload.name == 'invalid_vat') {
                            this.invalid_vat = true
                            this.validate_credentials()
                        }
                    }
                )
        },
        price_index(price) {
            const price_index = 6.3
            const price_increase = price * (price_index / 100)
            return parseFloat(price + price_increase).toFixed(2)
        },
    },
    created() {
        // Remove Brazil, because this breaks the jsvat library in 2.50
        // TODO remove this when we update the jsvat library
        this.vat_countries = countries.filter((x) => x.name !== 'Brazil')

        this.$store
            .dispatch('api/call', {
                method: 'get',
                url: '/account',
            })
            .then((response) => {
                this.credentials_data = response.data.payload.credentials
            })
    },
}
</script>
<style></style>
