<template>
    <div>
        <v-dialog v-model="dialog" max-width="800" persistent>
            <v-card>
                <v-card-title v-if="new_item">
                    <span class="headline">Create New Subscription</span>
                    <v-spacer></v-spacer>
                    <v-icon @click="close">mdi-close</v-icon>
                </v-card-title>
                <v-card-title v-else>
                    <span class="headline">Edit Product</span>
                </v-card-title>
                <v-card-text>
                    <v-stepper v-if="new_item" v-model="stepper" vertical>
                        <v-stepper-step :rules="[() => step_1_valid]" :complete="stepper > 1" step="1">
                            Create New Subscription
                        </v-stepper-step>
                        <v-stepper-content step="1">
                            <v-card dark color="secondary" class="mb-6">
                                <v-card-text>
                                    <v-form ref="step_1">
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-autocomplete
                                                    v-model="editedItem.customer_id"
                                                    :items="customers.payload"
                                                    label="Select the customer"
                                                    item-value="id"
                                                    item-text="customer_name"
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                    :rules="[rules.required]"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-select
                                                    v-model="charge_type"
                                                    :items="charge_types"
                                                    Label="Select the correct charge type"
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                    :rules="[rules.required]"
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-autocomplete
                                                    v-model="editedItem.product_type_id"
                                                    :items="generate_products"
                                                    label="Select the product type"
                                                    item-value="id"
                                                    item-text="name"
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                    :rules="[rules.required]"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.name"
                                                    label="Reference on invoice"
                                                    :value="generate_name"
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                    :rules="[rules.required]"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="editedItem.price"
                                                    label="Fill the product price"
                                                    :value="generate_price"
                                                    outlined
                                                    dense
                                                    hide-details="auto"
                                                    :rules="[rules.required, rules.currency]"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                            <v-btn color="primary" @click="validate_step_1(2)">Continue</v-btn>
                        </v-stepper-content>
                        <v-stepper-step :rules="[() => step_2_valid]" step="2">Summary</v-stepper-step>
                        <v-stepper-content step="2">
                            <v-card tile dark color="secondary" class="mb-12">
                                <v-card-text>
                                    <v-card-title>New product for {{ customer_name }}</v-card-title>

                                    <v-list-item two-line>
                                        <v-list-item-title>Product name</v-list-item-title>
                                        <v-list-item-subtitle> {{ this.editedItem.name }} </v-list-item-subtitle>
                                    </v-list-item>
                                    <v-list-item two-line>
                                        <v-list-item-title>Product Charge Type</v-list-item-title>
                                        <v-list-item-subtitle> {{ this.charge_type }} </v-list-item-subtitle>
                                    </v-list-item>
                                    <v-list-item two-line>
                                        <v-list-item-title>Product Price</v-list-item-title>
                                        <v-list-item-subtitle> €{{ this.editedItem.price }} </v-list-item-subtitle>
                                    </v-list-item>
                                </v-card-text>
                            </v-card>
                            <v-btn color="primary" @click="submit">Submit</v-btn>
                            <v-btn text @click="stepper--">Back</v-btn>
                        </v-stepper-content>
                    </v-stepper>
                    <v-card v-else dark color="secondary" class="mb-6">
                        <v-card-text>
                            <v-form ref="step_1">
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            v-model="editedItem.customer.customer_name"
                                            label="Select the customer"
                                            item-value="id"
                                            item-text="customer_name"
                                            outlined
                                            dense
                                            hide-details="auto"
                                            :rules="[rules.required]"
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-select
                                            v-model="charge_type"
                                            :items="charge_types"
                                            :value="get_recurring(this.editedItem.product_type.recurring)"
                                            Label="Select the correct charge type"
                                            outlined
                                            dense
                                            hide-details="auto"
                                            :rules="[rules.required]"
                                            disabled
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-autocomplete
                                            v-model="editedItem.product_type_id"
                                            :items="generate_products"
                                            label="Select the product type"
                                            item-value="id"
                                            item-text="name"
                                            outlined
                                            dense
                                            hide-details="auto"
                                            :rules="[rules.required]"
                                            disabled
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            v-model="editedItem.name"
                                            label="Reference on invoice"
                                            outlined
                                            dense
                                            hide-details="auto"
                                            :rules="[rules.required]"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            v-model="editedItem.price"
                                            label="Fill the product price"
                                            outlined
                                            dense
                                            hide-details="auto"
                                            :rules="[rules.required, rules.currency]"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-card-text>
                <v-card-actions v-if="!new_item">
                    <v-spacer></v-spacer>
                    <v-btn text @click="close">Close</v-btn>
                    <v-btn :loading="is_loading" color="primary" @click="submit">Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <h1 class="mb-4">Subscriptions</h1>

        <v-toolbar dense dark>
            <v-text-field
                label="Search Subscription..."
                name="searchfield"
                prepend-icon="mdi-text-box-search"
                v-model="search"
                clearable
                hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn text @click="showEditDialog(true)">
                    <v-icon left>mdi-text-box-plus</v-icon> New Subscription
                </v-btn>
                <v-menu offset-y>
                    <template v-slot:[`activator`]="{ on }">
                        <v-btn text v-on="on" :disabled="toggleActionsButton">
                            <v-icon left>mdi-ballot-outline</v-icon>
                            Actions
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item-group color="primary">
                            <v-list-item v-if="selected.length == 1" @click="showEditDialog()">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-pencil</v-icon>
                                        Edit Subscription
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="selected.length == 1" @click="delete_dialog = true">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-delete</v-icon>
                                        Delete Subscription
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="selected.length > 1" @click="delete_dialog = true">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-delete</v-icon>
                                        Bulk Delete Subscriptions
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            </v-toolbar-items>
        </v-toolbar>
        <v-dialog v-model="delete_dialog" transition="dialog-top-transition" max-width="800">
            <v-card>
                <v-toolbar color="error" dark>
                    <v-card-title>Delete subscription</v-card-title>
                </v-toolbar>
                <v-card-title>Are you sure you want to delete this subscription?!</v-card-title>
                <v-card-text>
                    <v-list-item-group>
                        <v-list-item inactive v-for="(item, i) in selected" :key="i">
                            <v-list-item-content>
                                <v-list-item-title v-text="item.name"></v-list-item-title>
                                <v-list-item-subtitle
                                    v-text="`Customer: ${item.customer.customer_name}`"
                                ></v-list-item-subtitle>
                                <v-list-item-subtitle
                                    v-text="`Charge Type: ${get_recurring(item.product_type.recurring)}`"
                                ></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn text @click="delete_dialog = false">Close</v-btn>
                    <v-btn color="error" @click="deleteItem">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-data-table
            v-model="selected"
            :loading="pending"
            :headers="headers"
            :items="subscriptions.payload"
            :search="search"
            :mobile-breakpoint="0"
            :footer-props="{ itemsPerPageOptions: [50, 100, 500] }"
            no-results-text="Loading data..."
            class="elevation-1"
            item-key="id"
            sorb-by="customer_name"
            sort-desc
            show-select
            single-select
            @click:row="clickSelect"
        >
            <template v-slot:[`item.quantity`]="{ item }">
                <span v-if="item.product_type.recurring == 0">NA</span>
                <vspan v-else>{{ item.quantity }}</vspan>
            </template>
            <template v-slot:[`item.product_type.recurring`]="{ item }">
                {{ get_recurring(item.product_type.recurring) }}
            </template>
            <template v-slot:[`item.price`]="{ item }"> €{{ item.price }} </template>
            <template v-slot:[`item.created`]="{ item }">
                {{ item.created | date }}
            </template>
            <template v-slot:[`item.updated`]="{ item }">
                {{ item.updated | date }}
            </template>
        </v-data-table>
        <v-snackbar
            centered
            top
            :color="snackbar.color"
            v-model="snackbar.state"
            :timeout="snackbar.timeout"
            :multi-line="snackbar.multiLine"
        >
            {{ snackbar.message }}
            <template v-if="snackbar.persistent" v-slot:action="{ attrs }">
                <v-btn icon v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
            customers: (state) => state.calls.customer_list,
            subscriptions: (state) => state.calls.subscriptions_list,
            types: (state) => state.calls.types_list,
        }),
        toggleActionsButton() {
            return this.selected.length ? false : true
        },
        generate_products() {
            var recurring = false
            if (this.charge_type == 'One-Off (Setup)') {
                recurring = false
            } else {
                recurring = true
            }
            const product_list = this.types.payload.filter((object) => {
                return object.recurring === recurring
            })
            return product_list
        },
        generate_name() {
            if (this.editedItem.product_type_id !== '') {
                const products = this.types.payload
                const product_list = products.filter((object) => {
                    return object.id === this.editedItem.product_type_id
                })
                return (this.editedItem.name = product_list[0].name)
            }
        },
        generate_price() {
            if (this.editedItem.product_type_id !== '') {
                const products = this.types.payload
                const product_list = products.filter((object) => {
                    return object.id === this.editedItem.product_type_id
                })
                return (this.editedItem.price = product_list[0].default_price)
            }
        },
        customer_name() {
            if (this.editedItem.customer_id !== '') {
                const customers = this.customers.payload
                const customer = customers.filter((object) => {
                    return object.id === this.editedItem.customer_id
                })
                return customer[0].customer_name
            }
        },
        product_type() {
            return this.editedItem.product_type.recurring
        },
        get_charge_type() {
            return this.charge_type
        },
    },
    watch: {
        get_charge_type() {
            if (this.new_item == true) {
                this.editedItem.name = ''
                this.editedItem.price = ''
            }
        },
        product_type() {
            var charge_type = ''
            if (this.product_type == true) {
                charge_type = 'Recurring'
            } else {
                charge_type = 'One-Off (Setup)'
            }
            this.charge_type = charge_type
        },
    },
    data() {
        return {
            dialog: false,
            delete_dialog: false,
            is_loading: false,
            stepper: 1,
            step_1_valid: true,
            step_2_valid: true,
            new_item: false,
            search: null,
            selected: [],
            snackbar: {
                state: false,
                color: '',
                message: '',
                timeout: 5000,
                multiline: true,
                persistent: false,
            },
            headers: [
                { text: 'Customer', value: 'customer.customer_name' },
                { text: 'Name', value: 'name' },
                { text: 'Quantity', value: 'quantity' },
                { text: 'Charge Type', value: 'product_type.recurring' },
                { text: 'Price', value: 'price' },
                { text: 'Created', value: 'created' },
                { text: 'Updated', value: 'updated' },
            ],
            charge_types: ['One-Off (Setup)', 'Recurring'],
            charge_type: 'One-Off (Setup)',
            item: {},
            editItemIndex: -1,
            editedItem: {
                customer_id: '',
                customer: {
                    customer_name: '',
                },
                product_type_id: '',
                product_type: {
                    recurring: '',
                },
                item_type: '',
                name: '',
                price: '',
            },
            rules: {
                required: (value) => !!value || 'Required!',
                min: (value) => value > 0 || 'Quantity must be at least 1!',
                number: (value) => {
                    const pattern = /^\d*(\.\d{1,2})?$/
                    return pattern.test(value) || 'Can only contain numbers!'
                },
                currency: (value) => {
                    const pattern = /^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/
                    return pattern.test(value) || 'Invalid currency format!'
                },
            },
        }
    },
    methods: {
        toggleSnackbar(persistent, color, message) {
            this.snackbar.state = true
            this.snackbar.color = color
            this.snackbar.message = message

            if (persistent == true) {
                this.snackbar.persistent = true
                this.snackbar.timeout = 0
            }
        },
        get_subscriptions() {
            this.$store
                .dispatch('api/call', {
                    name: 'subscriptions_list',
                    url: '/root/products',
                })
                .then((response) => {
                    this.subscriptions.payload.sort((a, b) => a.name.localeCompare(b.name))
                })
        },
        get_recurring(recurring) {
            return recurring ? 'Recurring' : 'One-Off (Setup)'
        },
        showEditDialog(new_item = false) {
            if (new_item) {
                this.new_item = true
                this.dialog = true
            } else if (this.selected.length > 0) {
                this.new_item = false
                if (this.selected.length == 1) {
                    this.editedItem = Object.assign({}, this.selected[0])
                }
                this.dialog = true
            }
        },
        clickSelect(item) {
            Vue.set(this.selected, 0, item)
        },
        validate_step_1(step = false) {
            if (this.$refs.step_1.validate()) {
                this.step_1_valid = true

                // Also activate stepper
                if (step) {
                    this.stepper = step
                }
            } else {
                this.step_1_valid = false
            }
        },
        submit() {
            this.is_loading = true
            if (this.new_item == true) {
                const customers = this.customers.payload
                const customer = customers.filter((object) => {
                    return object.id === this.editedItem.customer_id
                })
                this.editedItem.customer_name = customer[0].customer_name
                const data = {
                    customer_id: this.editedItem.customer_id,
                    product_type_id: this.editedItem.product_type_id,
                    name: this.editedItem.name,
                    price: this.editedItem.price,
                }
                // API Call..
                this.$store
                    .dispatch('api/call', {
                        method: 'post',
                        url: '/root/products',
                        data: { ...data },
                    })
                    .then(
                        (response) => {
                            this.toggleSnackbar(false, 'success', 'New Subscription created successfully!')
                            this.get_subscriptions()
                            this.close()
                            this.is_loading = false
                            this.selected = []
                        },
                        (error) => {
                            console.log(this.payload)
                            this.toggleSnackbar(true, 'error', this.payload.description)
                        }
                    )
            } else {
                const data = {
                    id: this.editedItem.id,
                    product_type_id: this.editedItem.product_type_id,
                    name: this.editedItem.name,
                    price: this.editedItem.price,
                }
                // API Call..
                this.$store
                    .dispatch('api/call', {
                        method: 'put',
                        url: '/root/products',
                        data: { ...data },
                    })
                    .then(
                        (response) => {
                            this.toggleSnackbar(false, 'success', 'New Subscription edited succesfully!')
                            this.get_subscriptions()
                            this.close()
                            this.is_loading = false
                            this.selected = []
                        },
                        (error) => {
                            console.log(this.payload)
                            this.toggleSnackbar(true, 'error', this.payload.description)
                        }
                    )
            }
        },
        deleteItem() {
            if (this.selected.length == 1) {
                this.editedItem = Object.assign({}, this.selected[0])

                // API Call..
                const data = {
                    id: this.editedItem.id,
                    product_type_id: this.editedItem.product_type_id,
                }
                this.$store
                    .dispatch('api/call', {
                        method: 'delete',
                        url: '/root/products',
                        data: { ...data },
                    })
                    .then(
                        (response) => {
                            this.toggleSnackbar(false, 'success', 'Subscripton was deleted succesfully!')
                            this.selected = []
                            this.close()
                        },
                        (error) => {
                            console.log(this.payload)
                            this.toggleSnackbar(true, 'error', this.payload.description)
                        }
                    )
            }
        },
        close() {
            for (const key in this.editedItem) {
                this.editedItem[key] = ''
            }
            this.charge_type = 'One-Off (Setup)'
            this.selected = []
            if (this.dialog === true) {
                this.stepper = 1
                this.$refs.step_1.resetValidation()
                this.dialog = false
            } else {
                this.delete_dialog = false
            }
        },
    },
    created() {
        this.$store
            .dispatch('api/call', {
                name: 'customer_list',
                url: '/root/customers',
                params: {
                    only: 'id, customer_name',
                },
            })
            .then((response) => {
                this.customers.payload.sort((a, b) => a.customer_name.localeCompare(b.customer_name))

                for (var i = 0; i < this.customers.payload.length; i++) {
                    this.customers.payload[i]['customer_name'] += ` (${this.customers.payload[i]['id']})`
                }
            })
        this.$store
            .dispatch('api/call', {
                name: 'subscriptions_list',
                url: '/root/products',
            })
            .then((response) => {
                this.subscriptions.payload.sort((a, b) =>
                    a.customer.customer_name.localeCompare(b.customer.customer_name)
                )
                for (var i = 0; i < this.subscriptions.payload.length; i++) {
                    this.subscriptions.payload[i]['customer'][
                        'customer_name'
                    ] += ` (${this.subscriptions.payload[i]['customer']['id']})`
                }
            })
        this.$store
            .dispatch('api/call', {
                name: 'types_list',
                url: '/root/products/types',
            })
            .then((response) => {
                this.types.payload.sort((a, b) => a.name.localeCompare(b.name))
            })
    },
}
</script>
