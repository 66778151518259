<template>
    <div>
        <v-dialog v-model="dialog_edit" max-width="500">
            <v-card>
                <v-card-title>
                    <span class="headline" v-if="new_item">Create New Role</span>
                    <span class="headline" v-else>Edit Role</span>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field
                                    v-model="editedItem.name"
                                    prepend-icon="mdi-shield-key"
                                    label="Name"
                                    hint="Name of this role."
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field
                                    v-model="editedItem.description"
                                    prepend-icon="mdi-text-subject"
                                    label="Description"
                                    hint="Short description of this role."
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-expansion-panels focusable>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header disable-icon-rotate>
                                            App Route Access
                                            <template v-slot:actions>
                                                <v-icon>mdi-monitor-dashboard</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-switch
                                                v-model="editedItem.all_access.views"
                                                label="Access all App routes"
                                                append-icon="mdi-alert"
                                                color="red"
                                                hide-details
                                                class="mb-3"
                                            ></v-switch>
                                            <v-expand-transition>
                                                <v-treeview
                                                    v-if="!editedItem.all_access.views"
                                                    selectable
                                                    selection-type="independent"
                                                    v-model="editedItem.views"
                                                    :items="routes"
                                                    item-key="name"
                                                    item-text="meta.text"
                                                    dense
                                                    on-icon="mdi-eye"
                                                    off-icon="mdi-eye-off"
                                                >
                                                    <template v-slot:[`append`]="{ item }">
                                                        <v-icon v-if="item.meta.hidden"> mdi-file-hidden </v-icon>
                                                    </template>
                                                </v-treeview>
                                            </v-expand-transition>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header disable-icon-rotate>
                                            App Advanced View
                                            <template v-slot:actions>
                                                <v-icon>mdi-newspaper-variant-multiple</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-switch
                                                v-model="editedItem.advanced_view"
                                                label="Activate Advanced Viewing Mode"
                                            ></v-switch>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header disable-icon-rotate>
                                            App Home Tiles
                                            <template v-slot:actions>
                                                <v-icon>mdi-apps</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-select
                                                v-model="editedItem.home"
                                                :items="home_tiles"
                                                item-value="index"
                                                item-text="name"
                                                multiple
                                                small-chips
                                            ></v-select>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header disable-icon-rotate>
                                            App Session Idle Time (Hours)
                                            <template v-slot:actions>
                                                <v-icon>mdi-timer-sand</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-slider
                                                v-model="editedItem.session_idle_time"
                                                :tick-labels="session_idle_times"
                                                :max="3"
                                                step="1"
                                                ticks="always"
                                                tick-size="2"
                                                class="mt-5"
                                            ></v-slider>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header disable-icon-rotate>
                                            API Resource Access
                                            <template v-slot:actions>
                                                <v-icon>mdi-cloud-braces</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-switch
                                                v-model="editedItem.all_access.resources"
                                                label="Access all API resources"
                                                append-icon="mdi-alert"
                                                color="red"
                                                hide-details
                                                class="mb-3"
                                            ></v-switch>
                                            <v-expand-transition>
                                                <v-treeview
                                                    v-if="!editedItem.all_access.resources"
                                                    selectable
                                                    v-model="editedItem.resources"
                                                    :items="resource_access"
                                                    item-key="resource"
                                                    item-text="name"
                                                    dense
                                                ></v-treeview>
                                            </v-expand-transition>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" text @click.native="close">Close</v-btn>
                    <v-btn :disabled="saveButtonState" color="primary darken-1" text @click.native="save">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_delete" max-width="290">
            <v-card>
                <v-card-title class="headline">Are you sure?</v-card-title>

                <v-card-text>
                    You are about to delete role {{ getRoleName }}. Are you sure you want to do this?
                </v-card-text>

                <v-card-actions>
                    <div class="flex-grow-1"></div>

                    <v-btn color="primary darken-1" text @click.native="dialog_delete = false"> Cancel </v-btn>

                    <v-btn color="primary darken-1" text @click.native="delete_role"> Delete </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <h1 class="mb-4">Roles</h1>

        <v-toolbar dense dark>
            <v-text-field name="searchfield" prepend-icon="mdi-shield-search" v-model="search" clearable hide-details>
            </v-text-field>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn text @click="showEditDialog(true)"> <v-icon left>mdi-shield-plus</v-icon>New Role </v-btn>
                <v-menu offset-y>
                    <template v-slot:[`activator`]="{ on }">
                        <v-btn text v-on="on" :disabled="toggleActionsButton">
                            <v-icon left>mdi-ballot-outline</v-icon>
                            Actions
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item-group color="primary">
                            <v-list-item @click="showDeleteDialog()">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-shield-off</v-icon>
                                        Delete Role
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="showEditDialog()">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-pencil</v-icon>
                                        Edit Role
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table
            v-model="selected"
            :loading="pending"
            :headers="headers"
            :items="payload"
            :search="search"
            :mobile-breakpoint="0"
            :footer-props="{ itemsPerPageOptions: [50, 100, 500] }"
            no-results-text="Loading data..."
            class="elevation-1"
            item-key="id"
            sort-by="name"
            sort-desc
            show-select
            single-select
            @click:row="clickSelect"
        >
        </v-data-table>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { access } from 'fs'
export default {
    data() {
        return {
            search: null,
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Description', value: 'description' },
            ],
            options: {},
            selected: [],
            dialog_edit: false,
            dialog_delete: false,
            new_item: false,
            editedItem: { all_access: { views: false, resources: false }, views: [], home: [], resources: [] },
            session_idle_times: [1, 6, 12, 24],
            resource_access: [
                {
                    resource: 'system',
                    name: 'System',
                    children: [
                        {
                            resource: 'ping',
                            name: 'Ping',
                            children: [{ resource: 'ping:GET', name: 'GET' }],
                        },
                        {
                            resource: 'action',
                            name: 'Actions',
                            children: [{ resource: 'action:POST', name: 'POST' }],
                        },
                        {
                            resource: 'provider',
                            name: 'Providers',
                            children: [{ resource: 'provider:GET', name: 'GET' }],
                        },
                    ],
                },
                {
                    resource: 'root',
                    name: 'Root',
                    children: [
                        {
                            resource: 'root.user',
                            name: 'Users',
                            children: [
                                { resource: 'root.user:GET', name: 'GET' },
                                { resource: 'root.user:POST', name: 'POST' },
                                { resource: 'root.user:PUT', name: 'PUT' },
                                { resource: 'root.user:DELETE', name: 'DELETE' },
                            ],
                        },
                        {
                            resource: 'root.role',
                            name: 'Roles',
                            children: [
                                { resource: 'root.role:GET', name: 'GET' },
                                { resource: 'root.role:POST', name: 'POST' },
                                { resource: 'root.role:PUT', name: 'PUT' },
                                { resource: 'root.role:DELETE', name: 'DELETE' },
                            ],
                        },
                        {
                            resource: 'root.customer',
                            name: 'Customers',
                            children: [
                                { resource: 'root.customer:GET', name: 'GET' },
                                { resource: 'root.customer:POST', name: 'POST' },
                                { resource: 'root.customer:PUT', name: 'PUT' },
                                { resource: 'root.customer:DELETE', name: 'DELETE' },
                            ],
                        },
                        {
                            resource: 'root.order',
                            name: 'Orders',
                            children: [
                              { resource: 'root.order:GET', name: 'GET' },
                              { resource: 'root.order:POST', name: 'POST' }
                            ],
                        },
                        {
                            resource: 'root.view',
                            name: 'Views',
                            children: [{ resource: 'root.view:GET', name: 'GET' }],
                        },
                        {
                            resource: 'root.subscriptions',
                            name: 'Subscriptions',
                            children: [
                              { resource: 'root.subscriptions:GET', name: 'GET' },
                              { resource: 'root.subscriptions:POST', name: 'POST' },
                              { resource: 'root.subscriptions:PUT', name: 'PUT' },
                            ],
                        },
                       
                    ],
                },
                {
                    resource: 'general',
                    name: 'General',

                    children: [
                        {
                            resource: 'statistics',
                            name: 'Home',

                            children: [{ resource: 'statistics:GET', name: 'GET' }],
                        },
                        {
                            resource: 'account',
                            name: 'Account',
                            children: [
                                { resource: 'account:PUT', name: 'PUT' },
                                { resource: 'account:GET', name: 'GET' },
                                { resource: 'account.otp', name: 'OTP', children: [
                                    { resource: 'account.otp.POST', name: 'POST' },
                                    ], 
                                },
                            ],
                        },
                        {
                            resource: 'authorize',
                            name: 'Authorize',
                            children: [
                                {
                                    resource: 'otp',
                                    name: 'OTP',
                                    children: [
                                        { resource: 'authorize.otp:GET', name: 'GET'},
                                        { resource: 'authorize.otp:POST', name: 'POST'},
                                    ]
                                }
                            ]
                        },
                        {
                            resource: 'simcard',
                            name: 'Simcards',

                            children: [
                                { resource: 'simcard:GET', name: 'GET' },
                                { resource: 'simcard:POST', name: 'POST' },
                                { resource: 'simcard:PUT', name: 'PUT' },
                            ],
                        },
                        {
                            resource: 'smartsim',
                            name: 'Smartsims',

                            children: [
                                { resource: 'smartsim:GET', name: 'GET' },
                                { resource: 'smartsim:PUT', name: 'PUT' },
                            ],
                        },
                        {
                            resource: 'order',
                            name: 'Orders',

                            children: [
                                { resource: 'order:GET', name: 'GET' },
                                { resource: 'order:POST', name: 'POST' },
                            ],
                        },
                        {
                            resource: 'bundle',
                            name: 'Bundles',

                            children: [{ resource: 'bundle:GET', name: 'GET' }],
                        },
                        {
                            resource: 'switch',
                            name: 'Network Switches',

                            children: [
                                { resource: 'switch:GET', name: 'GET' },
                                { resource: 'switch:POST', name: 'POST' },
                            ],
                        },
                        {
                            resource: 'trigger',
                            name: 'Triggers',
                            children: [
                                { resource: 'trigger:GET', name: 'GET' },
                                { resource: 'trigger:POST', name: 'POST' },
                                { resource: 'trigger:PUT', name: 'PUT' },
                                { resource: 'trigger:DELETE', name: 'DELETE' },
                            ],
                        },
                        {
                            resource: 'storage',
                            name: 'Downloads',

                            children: [
                                { resource: 'storage:GET', name: 'GET' },
                                { resource: 'storage:PUT', name: 'PUT' },
                            ],
                        },
                    ],
                },
                {
                    resource: 'diagnostic',
                    name: 'Diagnostics',
                    children: [
                        {
                            resource: 'diagnostic.audit',
                            name: 'Audit Logs',

                            children: [{ resource: 'diagnostic.audit:GET', name: 'GET' }],
                        },
                        {
                            resource: 'diagnostic.scan',
                            name: 'Scan',

                            children: [
                                { resource: 'diagnostic.scan:GET', name: 'GET' },
                                { resource: 'diagnostic.scan:POST', name: 'POST' },
                            ],
                        },
                        {
                            resource: 'diagnostic.sms',
                            name: 'Send Test SMS',

                            children: [
                                { resource: 'diagnostic.sms:GET', name: 'GET' },
                                { resource: 'diagnostic.sms:POST', name: 'POST' },
                            ],
                        },
                        {
                            resource: 'diagnostic.country',
                            name: 'Country History',

                            children: [
                                { resource: 'diagnostic.country:GET', name: 'GET' },
                                { resource: 'diagnostic.country:POST', name: 'POST' },
                            ],
                        },
                    ],
                },
                {
                    resource: 'lora',
                    name: 'LoRa',
                    children: [
                        {
                            resource: 'lora.device',
                            name: 'Devices',
                            children: [
                                { resource: 'lora.device:GET', name: 'GET' },
                                { resource: 'lora.device:PUT', name: 'PUT' },
                            ],
                        },
                        {
                            resource: 'lora.message',
                            name: 'Messages',

                            children: [
                                { resource: 'lora.message:GET', name: 'GET' },
                                { resource: 'lora.message:POST', name: 'POST' },
                            ],
                        },
                    ],
                },
            ],
            home_tiles: [
                { index: 'help', name: 'Help' },
                { index: 'changelog', name: 'Changelog' },
                { index: 'pending', name: 'Validation Pending' },
                { index: 'active_subscriptions', name: 'Active subscriptions' },
                { index: 'simcard_stock', name: 'Simcard stock' },
                { index: 'data_usage_mtd', name: 'Total data usage' },
                { index: 'top_simcard_data_usage', name: 'Highest data usage' },
                { index: 'top_bundle_usage', name: 'Highest bundle usage' },
            ],
        }
    },
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
        }),
        toggleActionsButton() {
            return this.selected.length ? false : true
        },
        routes() {
            return this.$router.options.routes.find((item) => item.path === '/').children
        },
        getRoleName() {
            return this.selected[0] ? this.selected[0].name : ''
        },
        saveButtonState() {
            return this.editedItem['name'] && this.editedItem['description'] ? false : true
        },
    },
    methods: {
        showEditDialog(new_item = false) {
            if (new_item) {
                this.new_item = true
                this.selected = []
                this.editedItem = {
                    all_access: { users: false, views: false, resources: false },
                    views: ['home', 'simcards', 'bundles', 'switch', 'downloads', "otp"],
                    home: ['active_subscriptions', 'simcard_stock', 'changelog', 'help'],
                    resources: [
                        'account:GET',
                        'account:PUT',
                        'action:POST',
                        'bundle:GET',
                        'ping:GET',
                        'provider:GET',
                        'simcard:GET',
                        'simcard:POST',
                        'simcard:PUT',
                        'statistics:GET',
                        'storage:GET',
                        'storage:PUT',
                        'switch:GET',
                        'switch:POST',
                    ],
                    session_idle_time: this.session_idle_times.indexOf(6),
                    advanced_view: false,
                }
                this.dialog_edit = true
            } else if (this.selected.length > 0) {
                this.editedItem = {
                    all_access: { views: false, resources: false },
                    name: this.selected[0].name,
                    description: this.selected[0].description,
                    views: [],
                    home: [],
                    resources: [],
                }
                let access_rules = this.selected[0].access_rules

                if (access_rules.app.views) {
                    if (access_rules.app.views == 'all') {
                        this.editedItem.all_access.views = true
                    } else if (access_rules.app.views.length > 0) {
                        this.editedItem.views = access_rules.app.views
                    }
                }

                if (access_rules.app.home) {
                    this.editedItem.home = access_rules.app.home
                }

                if (access_rules.app.advanced_view) {
                    this.editedItem.advanced_view = access_rules.app.advanced_view
                }

                if (access_rules.app.session_idle_time) {
                    this.editedItem.session_idle_time = this.session_idle_times.indexOf(
                        access_rules.app.session_idle_time
                    )
                }

                if (access_rules.api.resources) {
                    if (access_rules.api.resources == 'all') {
                        this.editedItem.all_access.resources = true
                    } else {
                        let resources = []
                        const entries = Object.entries(access_rules.api.resources)
                        for (const entry of entries) {
                            for (const method of entry[1]) {
                                resources.push(`${entry[0]}:${method}`)
                            }
                        }
                        this.editedItem.resources = resources
                    }
                }

                this.dialog_edit = true
            }
        },
        showDeleteDialog() {
            this.dialog_delete = true
        },
        clickSelect(item) {
            Vue.set(this.selected, 0, item)
        },
        generateAccessRules() {
            let access_rules = {
                api: { resources: {} },
                app: { views: {} },
            }

            if (this.editedItem.all_access.resources) {
                access_rules.api.resources = 'all'
            } else if (this.editedItem.resources) {
                let resources = {}
                for (const resource of this.editedItem.resources) {
                    const pair = resource.split(':')
                    if (pair[0] in resources) {
                        resources[pair[0]].push(pair[1])
                    } else {
                        resources[pair[0]] = [pair[1]]
                    }
                }
                access_rules.api.resources = resources
            }

            if (this.editedItem.all_access.views) {
                access_rules.app.views = 'all'
            } else if (this.editedItem.views) {
                access_rules.app.views = this.editedItem.views
            }

            if (this.editedItem.home) {
                access_rules.app.home = this.editedItem.home
            }

            if (this.editedItem.advanced_view) {
                access_rules.app.advanced_view = this.editedItem.advanced_view
            }

            if (this.editedItem.session_idle_time) {
                access_rules.app.session_idle_time = this.session_idle_times[this.editedItem.session_idle_time]
            }

            return access_rules
        },
        save() {
            if (this.new_item) {
                this.$store
                    .dispatch('api/call', {
                        name: 'role_add',
                        method: 'post',
                        url: '/root/roles',
                        data: {
                            name: this.editedItem['name'],
                            description: this.editedItem['description'],
                            access_rules: this.generateAccessRules(),
                        },
                    })
                    .then((response) => {
                        this.$store.dispatch('api/call', {
                            url: '/root/roles',
                        })
                    })
            } else if (this.selected.length > 0) {
                this.$store
                    .dispatch('api/call', {
                        name: 'role_edit',
                        method: 'put',
                        url: '/root/roles',
                        data: {
                            id: this.selected[0].id,
                            name: this.editedItem['name'],
                            description: this.editedItem['description'],
                            access_rules: this.generateAccessRules(),
                        },
                    })
                    .then((response) => {
                        this.$store.dispatch('api/call', {
                            url: '/root/roles',
                        })
                    })
            }
            this.close()
        },
        delete_role() {
            if (this.selected.length > 0) {
                this.$store
                    .dispatch('api/call', {
                        name: 'role_delete',
                        method: 'delete',
                        url: '/root/roles',
                        data: {
                            id: this.selected[0].id,
                        },
                    })
                    .then((response) => {
                        this.$store.dispatch('api/call', {
                            url: '/root/roles',
                        })
                    })
            }
            this.dialog_delete = false
        },
        close() {
            this.dialog_edit = false
            setTimeout(() => {
                this.new_item = false
                this.selected = []
                this.editedItem = {
                    all_access: { views: false, resources: false },
                    views: [],
                    resources: [],
                }
            }, 300)
        },
    },
    created() {
        this.$store.dispatch('api/call', {
            url: '/root/roles',
        })
    },
}
</script>