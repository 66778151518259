var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"mb-4"},[_vm._v("Audit Logs")]),_c('v-toolbar',{attrs:{"dense":"","dark":""}},[_c('v-text-field',{attrs:{"name":"searchfield","prepend-icon":"mdi-text-box-search-outline","clearable":"","hide-details":""},on:{"input":function($event){return _vm.debounceFilter(_vm.search)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-toolbar-items',[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert")]),_vm._v(" Severity ")],1)]}}],null,true),model:{value:(_vm.menu_severity),callback:function ($$v) {_vm.menu_severity=$$v},expression:"menu_severity"}},[_c('v-card',[_c('v-list',[_c('v-list-item',{staticClass:"mt-n2 mb-n2 mr-n2"},[_c('v-list-item-action',[_c('v-switch',{model:{value:(_vm.filter_severity.info),callback:function ($$v) {_vm.$set(_vm.filter_severity, "info", $$v)},expression:"filter_severity.info"}})],1),_c('v-list-item-title',[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"info","label":"","small":""}},[_vm._v("Info")])],1)],1),_c('v-list-item',{staticClass:"mt-n2 mb-n2 mr-n2"},[_c('v-list-item-action',[_c('v-switch',{model:{value:(_vm.filter_severity.success),callback:function ($$v) {_vm.$set(_vm.filter_severity, "success", $$v)},expression:"filter_severity.success"}})],1),_c('v-list-item-title',[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"success","label":"","small":""}},[_vm._v("Success")])],1)],1),_c('v-list-item',{staticClass:"mt-n2 mb-n2 mr-n2"},[_c('v-list-item-action',[_c('v-switch',{model:{value:(_vm.filter_severity.warning),callback:function ($$v) {_vm.$set(_vm.filter_severity, "warning", $$v)},expression:"filter_severity.warning"}})],1),_c('v-list-item-title',[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"warning","label":"","small":""}},[_vm._v("Warning")])],1)],1),_c('v-list-item',{staticClass:"mt-n2 mb-n2 mr-n2"},[_c('v-list-item-action',[_c('v-switch',{model:{value:(_vm.filter_severity.error),callback:function ($$v) {_vm.$set(_vm.filter_severity, "error", $$v)},expression:"filter_severity.error"}})],1),_c('v-list-item-title',[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"error","label":"","small":""}},[_vm._v("Error")])],1)],1)],1)],1)],1),_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-flash-circle")]),_vm._v(" Event ")],1)]}}],null,true),model:{value:(_vm.menu_event),callback:function ($$v) {_vm.menu_event=$$v},expression:"menu_event"}},[_c('v-card',[_c('v-list',_vm._l((_vm.filter_event),function(filter,key){return _c('v-list-item',{key:key,staticClass:"mt-n2 mb-n2 mr-n2"},[_c('v-list-item-action',[_c('v-switch',{model:{value:(_vm.filter_event[key]),callback:function ($$v) {_vm.$set(_vm.filter_event, key, $$v)},expression:"filter_event[key]"}})],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm._f("capitalize")(key))+" ")])],1)}),1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"mobile-breakpoint":0,"loading":_vm.pending,"options":_vm.options,"server-items-length":_vm.total,"no-results-text":"Loading data...","sort-by":"date","sort-desc":true,"footer-props":{ itemsPerPageOptions: [10, 30, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.event",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":item.severity,"label":"","small":""}},[_vm._v(" "+_vm._s(item.event)+" ")])]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.message)+" "),(item.details)?_c('v-tooltip',{attrs:{"bottom":"","offset-overflow":"","max-width":"340"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v("mdi-text-box")])]}}],null,true)},_vm._l((item.details),function(detail,i){return _c('div',{key:i},[(isNaN(i))?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("unpython")(i))+": ")]):_vm._e(),(typeof detail == 'object')?[_c('ul',_vm._l((detail),function(item,c){return _c('li',{key:c},[(c)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("unpython")(c))+":")]):_vm._e(),_vm._v(" "+_vm._s(item)+" ")])}),0)]:_c('span',[_vm._v(_vm._s(detail))])],2)}),0):_vm._e()]}},{key:"item.by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.by)+" "),(item.ip)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.lookupIP(item.ip)}}},on),[_vm._v("mdi-ip")])]}}],null,true)},[_vm._v(" "+_vm._s(item.ip)+" ")]):_vm._e()]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datetime")(item.date))+" ")]}}],null,true)},[_c('v-alert',{staticClass:"mt-4",attrs:{"slot":"no-results","value":true,"color":"info","icon":"mdi-magnify-close","dark":""},slot:"no-results"},[_vm._v(" There are no results that match your search. ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }