<template>
    <div>
        <h1 class="mb-4">Audit Logs</h1>
        <v-toolbar dense dark>
            <v-text-field
                name="searchfield"
                prepend-icon="mdi-text-box-search-outline"
                v-model="search"
                v-on:input="debounceFilter(search)"
                clearable
                hide-details
            >
            </v-text-field>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-menu v-model="menu_severity" :close-on-content-click="false" offset-y>
                    <template v-slot:[`activator`]="{ on }">
                        <v-btn text v-on="on">
                            <v-icon left>mdi-alert</v-icon>
                            Severity
                        </v-btn>
                    </template>
                    <v-card>
                        <v-list>
                            <v-list-item class="mt-n2 mb-n2 mr-n2">
                                <v-list-item-action>
                                    <v-switch v-model="filter_severity.info"></v-switch>
                                </v-list-item-action>
                                <v-list-item-title>
                                    <v-chip class="font-weight-bold" color="info" label small>Info</v-chip>
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item class="mt-n2 mb-n2 mr-n2">
                                <v-list-item-action>
                                    <v-switch v-model="filter_severity.success"></v-switch>
                                </v-list-item-action>
                                <v-list-item-title>
                                    <v-chip class="font-weight-bold" color="success" label small>Success</v-chip>
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item class="mt-n2 mb-n2 mr-n2">
                                <v-list-item-action>
                                    <v-switch v-model="filter_severity.warning"></v-switch>
                                </v-list-item-action>
                                <v-list-item-title>
                                    <v-chip class="font-weight-bold" color="warning" label small>Warning</v-chip>
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item class="mt-n2 mb-n2 mr-n2">
                                <v-list-item-action>
                                    <v-switch v-model="filter_severity.error"></v-switch>
                                </v-list-item-action>
                                <v-list-item-title>
                                    <v-chip class="font-weight-bold" color="error" label small>Error</v-chip>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
                <v-menu v-model="menu_event" :close-on-content-click="false" offset-y>
                    <template v-slot:[`activator`]="{ on }">
                        <v-btn text v-on="on">
                            <v-icon left>mdi-flash-circle</v-icon>
                            Event
                        </v-btn>
                    </template>
                    <v-card>
                        <v-list>
                            <v-list-item v-for="(filter, key) in filter_event" :key="key" class="mt-n2 mb-n2 mr-n2">
                                <v-list-item-action>
                                    <v-switch v-model="filter_event[key]"></v-switch>
                                </v-list-item-action>
                                <v-list-item-title>
                                    {{ key | capitalize }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table
            :headers="headers"
            :items="filteredItems"
            class="elevation-1"
            :mobile-breakpoint="0"
            :loading="pending"
            :options.sync="options"
            :server-items-length="total"
            no-results-text="Loading data..."
            sort-by="date"
            :sort-desc="true"
            :footer-props="{ itemsPerPageOptions: [10, 30, 50, 100, 500] }"
        >
            <template v-slot:[`item.event`]="{ item }">
                <v-chip class="font-weight-bold" :color="item.severity" label small>
                    {{ item.event }}
                </v-chip>
            </template>
            <template v-slot:[`item.message`]="{ item }">
                {{ item.message }}
                <v-tooltip v-if="item.details" bottom offset-overflow max-width="340">
                    <template v-slot:[`activator`]="{ on }">
                        <v-icon v-on="on" color="primary">mdi-text-box</v-icon>
                    </template>
                    <div v-for="(detail, i) in item.details" :key="i">
                        <span class="font-weight-bold" v-if="isNaN(i)">{{ i | unpython }}: </span>
                        <template v-if="typeof detail == 'object'">
                            <ul>
                                <li v-for="(item, c) in detail" :key="c">
                                    <span class="font-weight-bold" v-if="c">{{ c | unpython }}:</span> {{ item }}
                                </li>
                            </ul>
                        </template>
                        <span v-else>{{ detail }}</span>
                    </div>
                </v-tooltip>
            </template>
            <template v-slot:[`item.by`]="{ item }">
                {{ item.by }}
                <v-tooltip v-if="item.ip" bottom>
                    <template v-slot:[`activator`]="{ on }">
                        <v-icon v-on="on" @click="lookupIP(item.ip)">mdi-ip</v-icon>
                    </template>
                    {{ item.ip }}
                </v-tooltip>
            </template>
            <template v-slot:[`item.date`]="{ item }">
                {{ item.date | datetime }}
            </template>
            <v-alert slot="no-results" :value="true" color="info" icon="mdi-magnify-close" class="mt-4" dark>
                There are no results that match your search.
            </v-alert>
        </v-data-table>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
        }),
        filteredItems() {
            if (!this.payload) return []
            return this.payload
        },
    },
    data() {
        return {
            headers: [
                { text: 'Event', value: 'event' },
                { text: 'Message', value: 'message' },
                { text: 'Details', value: 'details_string', align: ' d-none' },
                { text: 'By', value: 'by' },
                { text: 'Date', value: 'date' },
            ],
            search: '',
            menu_severity: false,
            menu_event: false,
            filter_severity: { info: true, success: true, warning: true, error: true },
            filter_event: {
                authorize: true,
                account: true,
                attribute: true,
                simcard: true,
                smartsim: true,
                switch: true,
                order: true,
                admin: true,
            },
            total: -1,
            options: {},
        }
    },
    methods: {
        getDataFromApi(reset_total = false, total_only = false) {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options

            const event = Object.keys(this.filter_event)
                .filter((e) => this.filter_event[e])
                .join()
            const severity = Object.keys(this.filter_severity)
                .filter((e) => this.filter_severity[e])
                .join()

            this.$store
                .dispatch('api/call', {
                    name: total_only ? 'simcards_total' : 'default',
                    url: '/diagnostics/audit',
                    params: {
                        count: itemsPerPage,
                        skip: (page - 1) * itemsPerPage,
                        sort_by: sortBy[0],
                        descending: sortDesc[0],
                        filter: this.search,
                        event: event,
                        severity: severity,
                        total: total_only ? 'only' : '',
                    },
                })
                .then((response) => {
                    if (response.data.total) {
                        this.total = response.data.total
                    } else {
                        if (this.total == -1 || reset_total) {
                            // Fetch only the items total
                            this.getDataFromApi(false, true)
                        }
                    }
                })
        },
        lookupIP(ip) {
            window.open(`https://iplocation.com/?ip=${ip}`, '_blank')
        },
        debounceFilter: _debounce(function (value) {
            this.getDataFromApi(true)
        }, 800),
    },
    watch: {
        options: {
            handler() {
                this.getDataFromApi()
            },
            deep: true,
        },
        filter_severity: {
            handler() {
                this.getDataFromApi(true)
            },
            deep: true,
        },
        filter_event: {
            handler() {
                this.getDataFromApi(true)
            },
            deep: true,
        },
    },
    created() {
        if (typeof this.$route.query.search !== 'undefined') {
            this.search = this.$route.query.search
        }
    },
}
</script>