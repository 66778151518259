<template>
    <div>
        <v-dialog v-model="dialog_location" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">Device Location</span>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md text-xs-center>
                        <v-layout wrap>
                            <v-flex xs12 v-show="location_progress">
                                <div class="text-center">
                                    <v-progress-circular
                                        class="mt-10"
                                        size="50"
                                        color="primary"
                                        indeterminate
                                    ></v-progress-circular>
                                </div>
                            </v-flex>
                            <v-flex xs12 v-show="location_map">
                                <iframe
                                    width="100%"
                                    height="330"
                                    frameborder="0"
                                    style="border: 0"
                                    :src="googlemapslink"
                                ></iframe>
                            </v-flex>
                            <v-flex xs12 v-show="location_failed">
                                <p>Last location of this device could not be found.</p>
                                <p>
                                    A device must have had some usage for the location information to be available.
                                    Please make sure this device is operating.
                                </p>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" text @click.native="dialog_location = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_device" max-width="500">
            <v-card>
                <v-card-title>
                    <span class="headline">Edit Device Notes</span>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex md12>
                                <v-text-field
                                    v-for="(note, index) in editedItem.notes"
                                    :key="index"
                                    v-model="editedItem.notes[index].content"
                                    outlined
                                    dense
                                    :label="editedItem.notes[index].label"
                                    placeholder=""
                                    class="mb-n4 mr-md-2"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" text @click.native="deviceClose">Close</v-btn>
                    <v-btn color="primary darken-1" text @click.native="deviceSave">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_message" max-width="500">
            <v-card>
                <v-card-title>
                    <span class="headline">Send Message</span>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex md12>
                                <v-text-field
                                    v-model="editedItem.payload"
                                    outlined
                                    dense
                                    label="Payload"
                                    class="mb-n4 mr-md-2"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" text @click.native="messageClose">Close</v-btn>
                    <v-btn color="primary darken-1" text @click.native="messageSend">Send</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <h1 class="mb-4">Devices</h1>

        <v-toolbar dense dark>
            <v-text-field
                name="searchfield"
                prepend-icon="mdi-table-search"
                v-model="search_global"
                v-on:input="debounceFilter(search_global)"
                :disabled="header_filters"
                clearable
                hide-details
            >
                <template v-slot:append-outer>
                    <v-dialog v-model="dialog_help" width="500">
                        <template v-slot:[`activator`]="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                        </template>
                        <v-card>
                            <v-card-title> Filter Help </v-card-title>

                            <v-card-text>
                                <div class="font-weight-black">Contains <code>1234</code></div>
                                <div class="font-weight-thin mb-2">
                                    Default search method. Find values that include your search string.
                                </div>
                                <div class="font-weight-black">Starts With <code>1234*</code></div>
                                <div class="font-weight-thin mb-2">
                                    Use when you know what your value starts with, but not the exact text.
                                </div>
                                <div class="font-weight-black">Ends With <code>*1234</code></div>
                                <div class="font-weight-thin mb-2">
                                    Use when you know what your value ends with, but not the exact text.
                                </div>
                                <div class="font-weight-black">Equals <code>=1234</code></div>
                                <div class="font-weight-thin mb-2">Use for an exact match.</div>
                                <div class="font-weight-black">Not Equal To <code>!=1234</code></div>
                                <div class="font-weight-thin mb-2">
                                    Shows results that don’t have the value you enter.
                                </div>
                                <div class="font-weight-black">In <code>(123, 456, 789, 1011)</code></div>
                                <div class="font-weight-thin mb-2">
                                    Find results that include one or more of the values you enter.
                                </div>
                                <div class="font-weight-black">Not In <code>!(123, 456, 789, 1011)</code></div>
                                <div class="font-weight-thin mb-2">
                                    Find results that do not contain any values that match the ones entered.
                                </div>
                                <div class="font-weight-black">Between <code>[1, 100]</code></div>
                                <div class="font-weight-thin mb-2">Filter on a range of values.</div>
                                <div class="font-weight-black">Less Than <code>&lt;100</code></div>
                                <div class="font-weight-thin mb-2">
                                    Find entries that are less than the value you enter.
                                </div>
                                <div class="font-weight-black">Greater Than <code>&gt;1234</code></div>
                                <div class="font-weight-thin mb-2">Find entries that exceed the value you enter.</div>
                                <div class="font-weight-black">Less Or Equal <code>&lt;=100</code></div>
                                <div class="font-weight-thin mb-2">
                                    Use for results that match or are less than the value you enter.
                                </div>
                                <div class="font-weight-black">Greater Or Equal <code>&gt;=1234</code></div>
                                <div class="font-weight-thin mb-2">
                                    Use for results that match or exceed the value you enter.
                                </div>
                                <div class="font-weight-black">Equals or Not Equal To <code>=null / !=null</code></div>
                                <div class="font-weight-thin mb-2">
                                    Find entries where the value is equal or not equal to null.
                                </div>
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="dialog_help = false"> Close </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
            </v-text-field>
            <v-spacer></v-spacer>

            <v-toolbar-items>
                <v-menu offset-y>
                    <template v-slot:[`activator`]="{ on }">
                        <v-btn text v-on="on" :disabled="toggleActionsButton">
                            <v-icon left>mdi-ballot-outline</v-icon>
                            Actions
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item-group color="primary">
                            <v-list-item @click="showDeviceDialog(selected[0])">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-notebook-edit</v-icon>
                                        Edit Notes
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="showMessageDialog(selected[0])">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon class="mr-1">mdi-message-arrow-right</v-icon>
                                        Send Message
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
                <v-btn text @click="header_filters = !header_filters" :disabled="!!search_global">
                    <v-icon left>mdi-filter</v-icon>Filters
                </v-btn>
                <v-dialog v-model="dialog_columns" scrollable max-width="350px" @input="(v) => v || dirtyStorage()">
                    <template v-slot:[`activator`]="{ on }">
                        <v-btn text v-on="on"> <v-icon left>mdi-view-column</v-icon>Columns </v-btn>
                    </template>
                    <v-card flat>
                        <v-card-title>Show & Hide Columns</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-switch
                                v-for="column in selectableColumns"
                                :key="column.value"
                                v-model="column.selected"
                                color="primary"
                                :label="column.text"
                                hide-details
                            ></v-switch>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary darken-1"
                                text
                                @click.native="
                                    dialog_columns = false
                                    dirtyStorage()
                                "
                                >Done</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar-items>

            <template v-if="$vuetify.breakpoint.smAndUp">
                <v-btn icon @click="resetSearch()">
                    <v-icon>mdi-delete-circle</v-icon>
                </v-btn>
            </template>
        </v-toolbar>

        <v-data-table
            v-model="selected"
            :headers="filteredHeaders"
            :items="filteredItems"
            :options.sync="options"
            :server-items-length="total"
            :footer-props="{ itemsPerPageOptions: [10, 30, 50, 100, 500] }"
            :loading="pending"
            no-results-text="Loading data..."
            class="elevation-1 mytable"
            :mobile-breakpoint="0"
            show-select
            single-select
            @click:row="clickSelect"
        >
            <template v-slot:top>
                <v-expand-transition>
                    <div v-show="header_filters" class="elevation-3">
                        <v-toolbar v-for="(search, index) in filteredSearch" :key="index" dense flat>
                            <v-select
                                :items="selectableColumns"
                                v-model="filteredSearch[index].column"
                                hide-details
                                class="mr-4"
                                style="max-width: 220px"
                            ></v-select>
                            <v-text-field
                                v-model="filteredSearch[index].value"
                                hide-details
                                v-on:input="debounceFilter(filteredSearch[index].value)"
                                single-line
                                clearable
                            ></v-text-field>

                            <v-btn v-if="index == 0" icon color="primary" @click="addFilter()">
                                <v-icon>mdi-plus-circle</v-icon>
                            </v-btn>
                            <v-btn v-else icon color="red" @click="removeFilter(index)">
                                <v-icon>mdi-minus-circle</v-icon>
                            </v-btn>
                        </v-toolbar>
                    </div>
                </v-expand-transition>
            </template>
            <template v-slot:[`item.last_payload_date`]="{ item }">
                <span v-if="item.last_payload_date">
                    {{ item.last_payload_date | datetime }}
                </span>
            </template>
            <template v-slot:[`item.active`]="{ item }">
                <template v-if="item.active == true">
                    <v-chip color="green" small dark>
                        <v-icon left small>mdi-battery-charging-wireless-50</v-icon>Activated
                    </v-chip>
                </template>
                <template v-else-if="item.active == false">
                    <v-chip color="red" small dark>
                        <v-icon left small>mdi-battery-off-outline</v-icon>Deactivated
                    </v-chip>
                </template>
            </template>
            <template v-slot:[`item.notes`]="{ item }">
                <div v-if="checkNotes(item.notes)">
                    <v-tooltip bottom>
                        <template v-slot:[`activator`]="{ on }">
                            <v-chip v-on="on" color="primary" text-color="white" small>
                                <v-avatar left class="secondary">
                                    {{ item.notes.length }}
                                </v-avatar>
                                Notes
                            </v-chip>
                        </template>
                        <ul>
                            <li v-for="note in item.notes" :key="note.value">{{ note.label }}: {{ note.content }}</li>
                        </ul>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:[`item.action`]="{ item }">
                <v-icon color="primary" @click="showLocationDialog(item)">mdi-map-marker</v-icon>
            </template>
            <v-alert slot="no-data" :value="true" color="info" icon="mdi-magnify-close" class="mt-4" dark>
                There are no results that match your search.
            </v-alert>
        </v-data-table>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { generateFilter } from '../../helpers'

export default {
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
        }),
        ...mapState('session', {
            stored(state) {
                return state.storage.devices || { headers: [], options: {}, search_global: '', search_fields: [] }
            },
            advanced_view: (state) => state.rules.advanced_view || false,
        }),
        filteredHeaders() {
            var selected_headers = this.headers.filter((h) => h.selected)
            var selected_values = selected_headers.map((x) => x.value)

            if (selected_values.length > 0) {
                this.$store.dispatch('session/storage', { name: 'devices', headers: selected_values })
            }

            return selected_headers
        },
        filteredItems() {
            return this.payload.map((item) => {
                return Object.assign({}, item)
            })
        },
        searchColumns() {
            var selected_headers = this.headers.filter((h) => h.selected && h.searchable != false)
            var selected_values = selected_headers.map((x) => x.value)

            return selected_headers
        },
        selectableColumns() {
            if (this.advanced_view) {
                return this.headers.filter((h) => h.toggleable != false)
            } else {
                return this.headers.filter((h) => h.toggleable != false && h.advanced != true)
            }
        },
        filteredSearch() {
            if (this.advanced_view) {
                return this.search_fields
            } else {
                return this.search_fields.filter((s) =>
                    this.headers.some((h) => h.value == s.column && h.advanced != true)
                )
            }
        },
        toggleActionsButton() {
            return this.selected.length ? false : true
        },
    },
    data() {
        return {
            headers: [
                { text: 'Device EUI', value: 'dev_eui', selected: true },
                { text: 'Device Address', value: 'dev_addr', selected: false },
                { text: 'Network Secret Key', value: 'nwks_key', selected: false },
                { text: 'App EUI', value: 'app_eui', selected: true },
                { text: 'Status', value: 'active', selected: true },
                { text: 'Location', value: 'action', selected: true, sortable: false },
                { text: 'Notes', value: 'notes', selected: true },
                { text: 'Customer', value: 'customer_name', selected: false },
                { text: 'Last Payload Date', value: 'last_payload_date', selected: true },
                { text: 'Last Payload', value: 'last_payload', selected: true },
            ],
            selected: [],
            search_global: '',
            search_fields: [{ column: 'dev_eui', value: '' }],
            dialog_columns: false,
            dialog_location: false,
            dialog_device: false,
            dialog_message: false,
            dialog_help: false,
            header_filters: false,
            date_picker: false,
            options: {},
            total: -1,
            editedItem: {},
            location_progress: true,
            location_map: false,
            location_failed: false,
            googlemapslink: '',
        }
    },
    methods: {
        getDataFromApi(reset_total = false, total_only = false) {
            this.selected = []

            const { sortBy, sortDesc, page, itemsPerPage } = this.options

            if (!!this.search_global) {
                const search_fields = this.headers
                    .filter((h) => h.selected)
                    .map((h) => {
                        h = { column: h.value, value: this.search_global }
                        return h
                    })
                var api_filter = this.createApiFilter(search_fields, true)
            } else {
                var api_filter = this.createApiFilter(this.search_fields)
            }

            this.$store
                .dispatch('api/call', {
                    name: total_only ? 'devices_total' : 'default',
                    url: '/lora/devices',
                    params: {
                        count: itemsPerPage,
                        skip: (page - 1) * itemsPerPage,
                        sort_by: sortBy[0],
                        descending: sortDesc[0],
                        extra: 'id, customer_name',
                        filter: api_filter,
                        total: total_only ? 'only' : '',
                    },
                })
                .then((response) => {
                    if (response.data.total) {
                        this.total = response.data.total
                    } else {
                        // Store in local storage
                        this.$store.dispatch('session/storage', {
                            name: 'devices',
                            options: this.options,
                            search_global: this.search_global,
                            search_fields: this.search_fields,
                        })

                        if (!total_only) {
                            this.dirtyStorage()
                        }

                        if (this.total == -1 || reset_total) {
                            // Fetch only the items total
                            this.getDataFromApi(false, true)
                        }
                    }
                })
        },
        createApiFilter(filter, or_clause = false) {
            const conversions = {
                active: { activated: '1', deactivated: '0' },
                action: { _ignore: true },
            }
            return generateFilter(filter, or_clause, conversions)
        },
        showColumn(col) {
            return this.headers.find((h) => h.value === col).selected
        },
        showLocationDialog(item) {
            this.location_progress = true
            this.location_map = false
            this.location_failed = false
            this.dialog_location = true

            this.$store
                .dispatch('api/call', {
                    name: 'location',
                    method: 'get',
                    url: '/lora/messages',
                    unpack_json: true,
                    params: {
                        deveui: item.dev_eui,
                        count: 3,
                        only: 'date,message,category',
                        message: 'LrrLAT,LrrLON,DevLAT,DevLON',
                        category: 'uplink,location',
                        sort_by: 'date',
                        descending: true,
                    },
                })
                .then((response) => {
                    item = 0
                    for (const k in response.data.payload) {
                        if (response.data.payload[k].category === 'location') {
                            item = k
                        }
                    }
                    if (response.data.payload.length > 0) {
                        let latitude = response.data.payload[item].lrrlat || response.data.payload[item].devlat
                        let longitude = response.data.payload[item].lrrlon || response.data.payload[item].devlon

                        if (longitude && latitude) {
                            this.googlemapslink = `https://www.google.com/maps/embed/v1/search?key=AIzaSyCl6HPOay5bXNFHFrIcXBwlig3-wKL-YNU&q=${latitude},${longitude}&zoom=15`
                            setTimeout(() => {
                                this.location_progress = false
                                this.location_map = true
                            }, 1000)
                        }
                    } else {
                        this.location_progress = false
                        this.location_map = false
                        this.location_failed = true
                    }
                })
        },

        showDeviceDialog() {
            if (this.selected.length > 0) {
                if (this.selected.length == 1) {
                    this.editedItem = Object.assign({}, this.selected[0])
                }
                this.dialog_device = true
            }
        },
        showMessageDialog() {
            if (this.selected.length > 0) {
                if (this.selected.length == 1) {
                    this.editedItem = Object.assign({}, this.selected[0])
                }
                this.dialog_message = true
            }
        },
        clickSelect(item) {
            const index = this.selected.findIndex((v) => v.id === item.id)
            if (index !== -1) {
                this.selected.splice(index, 1)
            } else {
                this.selected.push(item)
            }
        },
        resetFromStorage() {
            if ('headers' in this.stored) {
                if (this.stored.headers.length) {
                    for (let header of this.headers) {
                        header.selected = this.stored.headers.includes(header.value) ? true : false
                    }
                }
            }
            if ('options' in this.stored) {
                if ('sortBy' in this.stored.options) {
                    this.options = this.stored.options
                } else {
                    this.options = { ...this.stored.options, ...{ sortBy: ['status'], sortDesc: ['true'] } }
                }
            }
            if ('search_global' in this.stored) {
                this.search_global = this.stored['search_global']
            }
            if ('search_fields' in this.stored) {
                if (this.stored.search_fields.length) {
                    this.search_fields = this.stored['search_fields']
                    for (let field of this.search_fields) {
                        if (field['value']) {
                            this.header_filters = true
                        }
                    }
                }
            }
        },
        dirtyStorage() {
            this.$store.dispatch('api/call', {
                name: 'dirty_storage',
                method: 'put',
                url: '/account/storage',
                data: {
                    storage: { devices: this.stored },
                },
            })
        },
        resetSearch() {
            for (let field in this.search_fields) {
                if (this.search_fields.hasOwnProperty(field)) {
                    this.search_fields[field] = ''
                }
            }
            this.search_global = ''
            this.search_fields = [{ column: 'iccid', value: '' }]

            this.getDataFromApi(true)
            this.dirtyStorage()
        },
        addFilter() {
            this.search_fields.push({ column: 'iccid', value: '' })
        },
        removeFilter(index) {
            this.search_fields.splice(index, 1)
            this.getDataFromApi(true)
        },
        checkNotes(notes) {
            if (notes) {
                return !Object.values(notes).every((note) => note.content === '')
            }
            return false
        },
        deviceClose() {
            this.dialog_device = false
            setTimeout(() => {
                this.editedItem = {}
            }, 300)
        },
        deviceSave() {
            if (this.selected.length > 0) {
                this.$store
                    .dispatch('api/call', {
                        name: 'device_edit',
                        method: 'put',
                        url: '/lora/devices',
                        data: {
                            deveui: this.editedItem.dev_eui,
                            notes: this.editedItem.notes,
                        },
                    })
                    .then((response) => {
                        for (let select of this.selected) {
                            let index = _findIndex(this.filteredItems, ['id', select.id])
                            Object.assign(this.payload[index], this.editedItem)
                        }
                        this.selected = []
                    })

                this.close()
            }
        },
        messageClose() {
            this.dialog_message = false
            setTimeout(() => {
                this.editedItem = {}
            }, 300)
        },
        messageSend() {
            if (this.selected.length > 0) {
                this.$store
                    .dispatch('api/call', {
                        name: 'message_send',
                        method: 'post',
                        url: '/lora/messages',
                        data: {
                            deveui: this.editedItem.dev_eui,
                            payload: this.editedItem.payload,
                        },
                    })
                    .then((response) => {
                        for (let select of this.selected) {
                            let index = _findIndex(this.filteredItems, ['id', select.id])
                            Object.assign(this.payload[index], this.editedItem)
                        }
                        this.selected = []
                    })

                this.messageClose()
            }
        },
        debounceFilter: _debounce(function (value) {
            if (value) {
                const operators = value.match(/(!=|>=|<=|!\(|\(|\)|\[|\]|\*|!|=|>|<)/g)

                if (operators) {
                    if (operators.includes('(') | operators.includes('!(') | operators.includes('[')) {
                        if (operators.includes(')') | operators.includes(']')) {
                            this.getDataFromApi(true)
                        }
                    } else if (value.length > operators[0].length) {
                        this.getDataFromApi(true)
                    }
                } else {
                    this.getDataFromApi(true)
                }
            } else {
                this.getDataFromApi(true)
            }
        }, 800),
    },
    watch: {
        options: {
            handler() {
                this.getDataFromApi()
            },
            deep: true,
        },
    },
    created() {
        this.resetFromStorage()

        if (typeof this.$route.query.sort_by !== 'undefined') {
            this.options.sortBy[0] = this.$route.query.sort_by
        }

        if (typeof this.$route.query.descending !== 'undefined') {
            this.options.sortDesc[0] = this.$route.query.descending
        }

        if (typeof this.$route.query.filter !== 'undefined') {
            this.search_global = ''
            this.search_fields = []
            this.header_filters = true
            let filters = this.$route.query.filter.split(',')
            for (let filter of filters) {
                let args = filter.split(':')
                this.search_fields.push({ column: args[0], value: args[1] })
            }
        }
    },
}
</script>

<style scoped>
.payload {
    max-width: 200px;
    word-wrap: break-word;
}
</style>