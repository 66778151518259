var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"mb-4"},[_vm._v("Bundles")]),_c('v-toolbar',{attrs:{"dense":"","dark":""}},[_c('v-text-field',{attrs:{"name":"searchfield","prepend-icon":"mdi-table-search","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.pending,"headers":_vm.filteredHeaders,"items":_vm.payload,"options":_vm.options,"search":_vm.search,"mobile-breakpoint":0,"footer-props":{ itemsPerPageOptions: [10, 30, 50, 100, 500] },"item-key":"id","sort-by":"percentage","sort-desc":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.bundle",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-view-dashboard-outline")]),_vm._v(_vm._s(item.bundle)+" ")],1)]}},{key:"item.operator",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"pr-3",staticStyle:{"width":"72px","max-height":"24px"},attrs:{"src":_vm.getImgUrl(item.operator)}})]}},{key:"item.percentage",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"align-center":"","justify-end":""}},[_c('v-progress-linear',{staticClass:"mr-2",attrs:{"value":item.percentage,"color":_vm.percentageToHsl(item.percentage, 120, 0),"height":"18"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}],null,true)},[_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.percentage)+"%")])])],1)]}},{key:"item.last_usage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datetime")(item.last_usage))+" ")]}},{key:"item.trigger_mail",fn:function(ref){
var item = ref.item;
return [(item.trigger_mail)?_c('v-tooltip',{attrs:{"bottom":"","offset-overflow":"","max-width":"340"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v("mdi-email")])]}}],null,true)},[_c('ul',_vm._l((item.trigger_mail.split(',')),function(email,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(email)+" ")])}),0)]):_c('span',[_c('v-icon',{attrs:{"color":"gray"}},[_vm._v("mdi-email-off")])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.gotoOverview(item.bundle, item.customer_id, item.operator)}}},on),[_vm._v(" mdi-sim ")])]}}],null,true)},[_c('span',[_vm._v("Show cards in Overview")])])]}}],null,true)},[_c('v-alert',{staticClass:"mt-4",attrs:{"slot":"no-results","value":true,"color":"info","icon":"mdi-magnify-close","dark":""},slot:"no-results"},[_vm._v(" There are no results that match your search. ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }