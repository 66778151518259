const generateFilter = (filter, or_clause = false, conversions = {}) => {
    let api_filter = ''

    main_loop: for (let i = 0, length = filter.length; i < length; i++) {
        if (filter[i].value) {
            let column = filter[i].column
            let value = filter[i].value.toLowerCase()

            if (column in conversions) {
                for (let match of value.match(/[A-z0-9-]+/gi)) {
                    if ('_method' in conversions[column]) {
                        value = conversions[column]._method(value)
                    } else if ('_ignore' in conversions[column]) {
                        continue main_loop
                    } else {
                        let key = Object.keys(conversions[column]).find(k => k.includes(match))
                        if (key) {
                            value = value.replace(match, conversions[column][key])
                        }
                    }
                }
            }

            api_filter += `{${column}:${value}}`

            if (or_clause && i < filter.length - 1) {
                api_filter += '|'
            }
        }
    }

    return api_filter
}

export { generateFilter }
