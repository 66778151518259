<template>
    <div>
        <v-dialog v-model="dialog_add_credits" max-width="400">
            <v-card>
                <v-card-title class="headline">Add Credits</v-card-title>

                <v-card-text>
                    Do you want to add the {{ credits }} credits from your wallet directly to your SmartSim now?
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="primary darken-1" text @click="dialog_add_credits = false"> No </v-btn>

                    <v-btn color="primary darken-1" text @click.native="addCredits"> Yes, add credits </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_successful" max-width="280">
            <v-card>
                <v-card-title class="headline">Credits Added</v-card-title>

                <v-card-text> {{ credits }} credits have been added successfully to your SmartSim! </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="primary darken-1" text @click="dialog_successful = false"> Ok </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <h1 class="mb-4">Order #{{ order_id }}</h1>

        <v-alert v-if="alert_type == 'open'" text color="info">
            <v-row align="center">
                <v-col class="shrink">
                    <v-progress-circular indeterminate :size="38" :width="3" color="primary"></v-progress-circular>
                </v-col>
                <v-col class="grow">Checking payment status for Order {{ order_id }}. Please wait...</v-col>
            </v-row>
        </v-alert>

        <v-alert v-else-if="alert_type == 'success'" type="success" prominent text class="pb-2">
            <div class="mb-3">{{ alert_message }}</div>

            <div>
                <v-btn color="primary" class="white--text mr-4 mb-3" @click="gotoOverview()">
                    <v-icon left dark>mdi-view-list</v-icon>Go to Overview
                </v-btn>

                <v-btn color="primary" class="white--text mb-3" @click="gotoDownloads()">
                    <v-icon left dark>mdi-file-download</v-icon>Download Invoices
                </v-btn>
            </div>
        </v-alert>
        <v-alert v-else-if="alert_type == 'error'" prominent text type="error">
            {{ alert_message }}
        </v-alert>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dialog_add_credits: false,
            dialog_successful: false,
            alert_type: 'open',
            alert_message: '',
            smartsim: null,
            credits: 0,
        }
    },
    computed: {
        order_id() {
            return this.$route.params.order_id
        },
    },
    timers: {
        checkOrderStatus: { time: 1000, repeat: true, autostart: true },
    },
    methods: {
        checkOrderStatus() {
            this.$store
                .dispatch('api/call', {
                    method: 'get',
                    url: '/order/' + this.order_id,
                })
                .then(
                    (response) => {
                        if (response.data.payload.status !== 'open') {
                            this.$timer.stop('checkOrderStatus')

                            switch (response.data.payload.status) {
                                case 'paid':
                                    this.$store.dispatch('session/credits', response.data.payload.user_credits)
                                    this.credits = response.data.payload.credits

                                    this.alert_type = 'success'
                                    this.alert_message = `Payment was successful. ${this.credits} credits have been added to your wallet. You can now add these credits to your SmartSims. Click on the button on the left to go to your SmartSim overview.`

                                    this.$store
                                        .dispatch('api/call', {
                                            name: 'smartsims',
                                            url: '/smartsims',
                                        })
                                        .then((reply) => {
                                            if (reply.data.payload.length == 1) {
                                                this.smartsim = reply.data.payload[0]
                                                this.dialog_add_credits = true
                                            }
                                        })

                                    break
                                case 'error':
                                    this.alert_type = 'error'
                                    this.alert_message =
                                        'Payment was unsuccessful. Please contact support for further assistance.'
                                    break
                                case 'timeout':
                                    this.alert_type = 'error'
                                    this.alert_message =
                                        'Payment was unsuccessful due to a timeout. Please contact support for further assistance.'
                                    break
                                case 'failed':
                                    this.alert_type = 'error'
                                    this.alert_message =
                                        'Payment was unsuccessful. No credits have been added to your account.'
                                    break
                                case 'canceled':
                                    this.alert_type = 'error'
                                    this.alert_message =
                                        'Payment was canceled. No credits have been added to your account.'
                                    break
                                case 'expired':
                                    this.alert_type = 'error'
                                    this.alert_message =
                                        'Payment was unsuccessful due to a timeout. Please contact support for further assistance.'
                                    break
                            }
                        }
                    },
                    (error) => {
                        this.$timer.stop('checkOrderStatus')
                        this.alert_type = 'error'
                        this.alert_message = ''
                    }
                )
        },
        gotoOverview() {
            this.$router.push({ path: `/smartsims/overview` })
        },
        gotoDownloads() {
            this.$router.push({ path: `/downloads` })
        },
        addCredits() {
            let data = {
                iccids: [this.smartsim.iccid],
                credits: this.credits,
            }
            this.$store
                .dispatch('api/call', {
                    name: 'prepaid_change',
                    method: 'put',
                    url: '/smartsims',
                    data: data,
                })
                .then((response) => {
                    if (response.data.name == 'credits_added') {
                        this.$store.dispatch('session/credits', response.data.payload.user_credits)
                        this.dialog_add_credits = false
                        this.dialog_successful = true
                    }
                })
        },
    },
}
</script>
<style>
</style>