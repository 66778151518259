<template>
    <div>
        <h1 class="mb-4">Order SIM Cards</h1>
        <v-alert
            v-if="
                selected_type == 'regular' &&
                customer_specials.length > 0 &&
                customer_specials[0].subcategory == 'regular'
            "
            icon="mdi-alert"
            dense
            border="left"
            type="warning"
        >
            <ul v-for="item in customer_specials[0].specials" :key="item">
                <li>{{ item }}</li>
            </ul>
        </v-alert>
        <v-alert
            v-if="
                selected_type == 'smart' && customer_specials.length > 0 && customer_specials[0].subcategory == 'smart'
            "
            icon="mdi-alert"
            dense
            border="left"
            type="warning"
        >
            <ul v-for="item in customer_specials[0].specials" :key="item">
                <li>{{ item }}</li>
            </ul>
        </v-alert>
        <v-stepper v-model="order_stepper" vertical>
            <v-stepper-step :rules="[() => ordertype_valid]" :complete="order_stepper > 1" step="1">
                Order Type
            </v-stepper-step>

            <v-stepper-content step="1">
                <v-form ref="order_type">
                    <v-card dark color="secondary" class="mb-6">
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-autocomplete
                                        v-model="selected_customer"
                                        :items="customers.payload"
                                        label="Select Customer"
                                        item-value="id"
                                        item-text="customer_name"
                                        outlined
                                        :rules="[rules.required]"
                                        dense
                                        hide-details="auto"
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-select
                                        v-if="selected_type != 'lora'"
                                        v-model="selected_operator"
                                        :items="operators"
                                        label="Select Operator"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        :rules="[rules.required]"
                                        dense
                                        hide-details="auto"
                                    >
                                        <template slot="selection" slot-scope="data">
                                            <img
                                                :src="getImgUrl(data.item.name)"
                                                class="pr-3"
                                                style="width: 72px; max-height: 24px"
                                            />
                                            <span>{{ data.item.name }}</span>
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            <img
                                                :src="getImgUrl(data.item.name)"
                                                class="pr-3"
                                                style="width: 72px; max-height: 24px"
                                            />

                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-select
                                        v-model="selected_type"
                                        :items="generate_bundel_subtypes"
                                        outlined
                                        label="Select Product"
                                        :rules="[rules.required]"
                                        :disabled="!selected_customer"
                                        item-text="name"
                                        item-value="id"
                                        dense
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-btn color="primary" @click="validate_ordertype(2)" :loading="is_loading"> Continue </v-btn>
                    <v-row class="ma-2" v-if="is_loading == true">
                        <small> We're getting backend information...</small>
                    </v-row>
                </v-form>
            </v-stepper-content>

            <v-stepper-step :rules="[() => details_valid]" :complete="order_stepper > 2" step="2">
                Specify order details
            </v-stepper-step>

            <v-stepper-content step="2">
                <v-form ref="details">
                    <v-card dark color="secondary" class="mb-6">
                        <v-alert v-if="too_many" icon="mdi-alert" dense border="left" type="warning">
                            You've selected too many SIM cards for this IP Range! If the order needs to be configured in
                            this specific IP Range, set the ticket to team Dev-Ops first!
                        </v-alert>
                        <v-card-text
                            v-if="selected_type == 'regular' || selected_type == 'smart' || selected_type == 'test'"
                        >
                            <v-row
                                v-if="selected_type == 'regular' || selected_type == 'smart' || selected_type == 'test'"
                            >
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="quantity"
                                        label="How many simcards to process"
                                        type="number"
                                        outlined
                                        :rules="[rules.required, rules.amounts]"
                                        dense
                                        hide-details="auto"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col v-if="selected_type == 'regular' || 'smart'" cols="12" sm="6">
                                    <v-select
                                        label="Select SIM Card type"
                                        v-model="simcard_type"
                                        :items="generate_simcard_types"
                                        :rules="[rules.required]"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                                <v-col v-if="selected_type == 'regular'" cols="12" sm="6">
                                    <v-select
                                        label="Select the bundle"
                                        v-model="bundle"
                                        :items="generate_bundles"
                                        item-text="name"
                                        item-value="id"
                                        outlined
                                        :disabled="!simcard_type"
                                        :rules="[rules.required]"
                                        dense
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                                <v-col
                                    v-if="selected_type == 'regular' && selected_operator == 't_mobile'"
                                    cols="12"
                                    sm="6"
                                >
                                    <v-select
                                        label="Select state"
                                        v-model="billable_state"
                                        :items="billable_states"
                                        item-text="name"
                                        item-value="id"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                                <v-col v-else-if="selected_type == 'smart'" cols="12" sm="6">
                                    <v-select
                                        label="Select the PrePaid Plan"
                                        v-model="prepaid_plan"
                                        :items="generate_bundles"
                                        item-text="name"
                                        item-value="id"
                                        outlined
                                        :rules="[rules.required]"
                                        dense
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                                <v-col v-if="selected_type == 'smart'" cols="12" sm="6">
                                    <v-text-field
                                        label="Select SmartSim credits"
                                        v-model="prepaid_credits"
                                        type="number"
                                        outlined
                                        :rules="[rules.required, rules.credits]"
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                <v-col v-if="selected_type == 'smart'" cols="12" sm="6">
                                    <v-select
                                        label="Select SmartSim status"
                                        v-model="prepaid_status"
                                        :items="prepaid_statuses"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row v-if="!['conexa_ld', 'conexa_os'].includes(this.selected_operator)">
                                <v-col cols="12" sm="12">
                                    <v-select
                                        label="Select IP Range"
                                        v-model="selected_iprange"
                                        :items="ipRanges"
                                        item-text="customer_range"
                                        item-value="id"
                                        :rules="[rules.required]"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    >
                                        <template v-slot:item="{ item }">
                                            <span :style="{ color: item.color }"
                                                >{{ item.customer_range }} (Free IPs: {{ item.free_ips }})</span
                                            >
                                        </template>
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row v-if="selected_type != 'test'">
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="shippingFee"
                                        label="Fill the Shipping and Handling Cost"
                                        outlined
                                        :rules="[rules.required, rules.currency]"
                                        dense
                                        hide-details="auto"
                                        prefix="€"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="setup_fee"
                                        label="Fill the Setup-fee Cost"
                                        value=""
                                        outlined
                                        :rules="[rules.required, rules.currency]"
                                        dense
                                        hide-details="auto"
                                        prefix="€"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-textarea
                                        v-model="remark"
                                        outlined
                                        label="Add extra details as a remark..."
                                        counter="100"
                                        :rules="[rules.max]"
                                        dense
                                        hide-details="auto"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-expansion-panels multiple>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header color="secondary darken-1"
                                                >Custom Fields</v-expansion-panel-header
                                            >
                                            <v-expansion-panel-content color="secondary darken-1">
                                                <v-row>
                                                    <v-col cols="12" sm="6">
                                                        <v-select
                                                            v-model="custom_1.default"
                                                            label="Custom 1"
                                                            :items="custom_items"
                                                            item-text="name"
                                                            item-value="id"
                                                            outlined
                                                            dense
                                                            hide-details="auto"
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field
                                                            v-if="custom_1.default == 'Custom'"
                                                            v-model="custom_1.value"
                                                            label="Add custom value"
                                                            outlined
                                                            dense
                                                            hide-details="auto"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>

                                                <v-row>
                                                    <v-col cols="12" sm="6">
                                                        <v-select
                                                            v-model="custom_2.default"
                                                            label="Custom 2"
                                                            :items="custom_items"
                                                            item-text="name"
                                                            item-value="id"
                                                            outlined
                                                            dense
                                                            hide-details="auto"
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field
                                                            v-if="custom_2.default == 'Custom'"
                                                            v-model="custom_2.value"
                                                            label="Add custom value"
                                                            outlined
                                                            dense
                                                            hide-details="auto"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>

                                                <v-row>
                                                    <v-col cols="12" sm="6">
                                                        <v-select
                                                            v-model="custom_3.default"
                                                            label="Custom 3"
                                                            :items="custom_items"
                                                            item-text="name"
                                                            item-value="id"
                                                            outlined
                                                            dense
                                                            hide-details="auto"
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field
                                                            v-if="custom_3.default == 'Custom'"
                                                            v-model="custom_3.value"
                                                            label="Add custom value"
                                                            outlined
                                                            dense
                                                            hide-details="auto"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>

                                                <v-row>
                                                    <v-col cols="12" sm="6">
                                                        <v-select
                                                            v-model="custom_4.default"
                                                            label="Custom 4"
                                                            :items="custom_items"
                                                            item-text="name"
                                                            item-value="id"
                                                            outlined
                                                            dense
                                                            hide-details="auto"
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field
                                                            v-if="custom_4.default == 'Custom'"
                                                            v-model="custom_4.value"
                                                            label="Add custom value"
                                                            outlined
                                                            dense
                                                            hide-details="auto"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>

                                                <v-row>
                                                    <v-col cols="12" sm="6">
                                                        <v-select
                                                            v-model="custom_5.default"
                                                            label="Custom 5"
                                                            :items="custom_items"
                                                            item-text="name"
                                                            item-value="id"
                                                            outlined
                                                            dense
                                                            hide-details="auto"
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <v-text-field
                                                            v-if="custom_5.default == 'Custom'"
                                                            v-model="custom_5.value"
                                                            label="Add custom value"
                                                            outlined
                                                            dense
                                                            hide-details="auto"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-btn color="primary" @click="validate_details(3)"> Continue </v-btn>
                    <v-btn text @click="step_back"> Back </v-btn>
                </v-form>
            </v-stepper-content>

            <v-stepper-step :rules="[() => simcards_valid]" :complete="order_stepper > 3" step="3">
                {{ product_title }}
                <small v-if="this.ordered_list_valid == false">ICCIDs are not ascending</small>
                <small v-if="this.random_list_valid == false || this.single_iccids_valid == false"
                    >Incorrect amount of ICCIDs selected</small
                >
            </v-stepper-step>

            <v-stepper-content step="3">
                <v-form ref="simcards">
                    <v-card dark color="secondary" class="mb-6">
                        <v-card-text v-if="this.selected_type != 'lora'">
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-radio-group type="number" v-model="iccids_type" row>
                                        <v-radio dark label="Single ICCID" value="single"></v-radio>
                                        <v-radio dark label="Ordered Range" value="range"></v-radio>
                                        <v-radio dark label="Unordered Range" value="random"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>

                            <v-row v-if="iccids_type == 'single'">
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="iccids_single"
                                        label="Fill the ICCID"
                                        outlined
                                        :rules="[rules.required, rules.iccids]"
                                        dense
                                        hide-details="auto"
                                        :error="!single_iccids_valid"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row v-if="iccids_type == 'range'">
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="iccids_ranged_first"
                                        label="Fill the first ICCID"
                                        outlined
                                        :rules="[rules.required, rules.iccids]"
                                        dense
                                        hide-details="auto"
                                        :error="!ordered_list_valid"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="iccids_ranged_last"
                                        label="Fill the last ICCID"
                                        outlined
                                        :rules="[rules.required, rules.iccids]"
                                        dense
                                        hide-details="auto"
                                        :error="!ordered_list_valid"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row v-if="iccids_type == 'random'">
                                <v-col cols="12" sm="12">
                                    <div v-for="item in list" :key="item.id">
                                        <v-textarea
                                            v-model="iccids_random"
                                            outlined
                                            label="List of ICCIDs"
                                            :rules="[rules.required]"
                                            dense
                                            hide-details="auto"
                                            :error="!random_list_valid"
                                        ></v-textarea>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-switch
                                v-if="iccids_type == 'range'"
                                label="ICCIDs in one batch"
                                v-model="moron_proof"
                            ></v-switch>
                        </v-card-text>
                        <v-card-text v-else>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-radio-group type="number" v-model="lora_key_type" row>
                                        <v-radio dark label="DevEUI, AppEUI and AppKey" value="all"></v-radio>
                                        <v-radio dark label="AppEUI and AppKey" value="appeui_appkey"></v-radio>
                                        <v-radio dark label="AppKey" value="appkey"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-textarea
                                        v-if="!lora_text_area"
                                        v-model="lora_keys"
                                        outlined
                                        label="Paste Provided DevEUI and/or AppEUI"
                                        :rules="[rules.required]"
                                        :disabled="lora_text_area"
                                        dense
                                        hide-details="auto"
                                    ></v-textarea>
                                    <v-alert
                                        v-if="lora_text_area"
                                        icon="mdi-information"
                                        dense
                                        border="left"
                                        type="info"
                                    >
                                        With this option, you'll create new LoRa keys with DevEUIs managed by
                                        Wirelesslogic BeNeLux.
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-form>

                <v-btn color="primary" @click="validate_simcards(4)"> Continue </v-btn>
                <v-btn text @click="step_back"> Back </v-btn>
            </v-stepper-content>

            <v-stepper-step step="4"> Review order </v-stepper-step>
            <v-stepper-content step="4">
                <v-card dark color="secondary" class="mb-6">
                    <div v-if="selected_item == 0">
                        <v-card-title>New order for an existing customer</v-card-title>
                        <v-card-text>
                            <ul>
                                <li>
                                    Simcard Operator: <strong>{{ selected_operator }}</strong>
                                </li>
                                <li>
                                    Amount: <strong>{{ quantity }}</strong>
                                </li>
                                <li>
                                    Customer Name: <strong>{{ selected_customername.customer_name }}</strong>
                                </li>
                                <li>
                                    Order Type: <strong>{{ selected_type }}</strong>
                                </li>
                                <!-- <div v-if="selected_type == 'regular'">
                                    <li>
                                        Bundle: <strong>{{ selected_subscription.name }}</strong>
                                    </li>
                                </div> -->
                                <div v-if="selected_type == 'smart'">
                                    <ul>
                                        <li>
                                            Prepaid Plan Type: <strong>{{ prepaidplan_option }}</strong>
                                        </li>
                                        <li>
                                            Prepaid Plan: <strong>{{ prepaid_plan.name }}</strong>
                                        </li>
                                        <li>
                                            SmartSim credits: <strong>{{ prepaid_credits }}</strong>
                                        </li>
                                        <li>
                                            SmartSim status: <strong>{{ prepaid_status }}</strong>
                                        </li>
                                    </ul>
                                </div>
                                <li v-if="iccids_type == 'single'">
                                    Single ICCID: <strong>{{ iccids_single }}</strong>
                                </li>
                                <li v-if="iccids_type == 'range'">
                                    First ICCID: <strong>{{ iccids_ranged_first }}</strong> - Last ICCID:
                                    <strong>{{ iccids_ranged_last }}</strong>
                                </li>
                                <li v-if="iccids_type == 'random'">
                                    ICCID List: <strong>{{ iccid_list }}</strong>
                                </li>
                            </ul>
                        </v-card-text>
                    </div>
                </v-card>
                <v-btn color="primary" @click="process_order()" :loading="is_loading"> Continue </v-btn>
                <v-btn text @click="step_back"> Back </v-btn>
            </v-stepper-content>
        </v-stepper>
        <v-dialog v-model="dialog" transition="dialog-top-transition" max-width="800" persistent>
            <v-card>
                <v-toolbar :color="order_status" dark>
                    <v-card-title>{{ order_message }}</v-card-title>
                </v-toolbar>
                <v-card-text>
                    <v-card-title v-if="!error">Order has been processed correctly.</v-card-title>
                    <v-card-text v-if="!error">
                        The order has been processed, click the overview button to go to the orders page.
                    </v-card-text>

                    <v-card-title v-if="error">An error occured while processing the order.</v-card-title>
                    <v-card-text v-if="error">
                        {{ error_message }}
                    </v-card-text>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn color="warning" v-if="error == true" @click="dialog = false">Edit</v-btn>
                    <v-btn text @click="got_to_overview">Overview</v-btn>
                    <v-btn text @click="reset">Finish</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            centered
            top
            :color="snackbar.color"
            v-model="snackbar.state"
            :timeout="snackbar.timeout"
            :multi-line="snackbar.multiLine"
        >
            {{ snackbar.message }}
            <template v-if="snackbar.persistent" v-slot:action="{ attrs }">
                <v-btn icon v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
            customers: (state) => state.calls.customer_list,
            subscriptions: (state) => state.calls.subscription_list,
        }),
        product_title() {
            return this.selected_type !== 'lora' ? 'Select ICCIDs' : 'Generate LoRa Keys'
        },
        lora_text_area() {
            return this.lora_key_type !== 'all' ? false : true
        },
        subscriptionItems() {
            return this.subscriptions ? this.subscriptions.payload : []
        },
        iccid_list() {
            if (this.iccids_random.split(/\s*[\n, ]+\s*/).length > 5) {
                let iccids = this.iccids_random.split(/\s*[\n, ]+\s*/)
                let first = iccids[0]
                let last = iccids[iccids.length - 1]
                return String(first + ' - ' + last)
            } else {
                let iccids = this.iccids_random
                return iccids
            }
        },
        generate_bundel_subtypes() {
            var sub_types = [
                { name: 'Regular SIM', id: 'regular' },
                { name: 'Smart SIM', id: 'smart' },
                { name: 'Test SIM', id: 'test' },
            ]
            if (this.selected_customer) {
                const customer = this.customers.payload.filter((object) => {
                    return object.id === this.selected_customer
                })
                if (customer[0].prospect) {
                    sub_types = [{ name: 'Test SIM', id: 'test' }]
                }
            }
            return sub_types
        },
        generate_simcard_types() {
            var simcard_types = []
            this.subscriptionItems.forEach((element) => {
                if (!simcard_types.includes(element.simcard_type)) {
                    simcard_types.push(element.simcard_type)
                }
            })
            if (simcard_types.length == 1) {
                this.simcard_type = simcard_types[0]
            }
            return simcard_types
        },
        generate_bundles() {
            var simcard_type = this.simcard_type
            var simcard_bundles = []
            if (this.selected_type === 'smart') {
                var subscriptions = _.filter(this.subscriptionItems, { bundle_type: this.selected_type.toUpperCase() })
                subscriptions.forEach((element) => {
                    if (element.simcard_type == simcard_type) {
                        simcard_bundles.push({ name: element.name, id: element.id })
                    }
                })
            } else {
                // _.filter(users, function(o) { return !o.active; });
                var subscriptions = _.filter(this.subscriptionItems, function (o) {
                    return o.bundle_type !== 'SMART'
                })
                subscriptions.forEach((element) => {
                    if (element.simcard_type == simcard_type) {
                        let modifiedName = element.name;

                        if (element.sms && element.voice) {
                            modifiedName += " (sms+voice)";
                        } else if (element.sms) {
                            modifiedName += " (sms)";
                        } else if (element.voice) {
                            modifiedName += " (voice)";
                        }
                        
                        simcard_bundles.push({ name: modifiedName, id: element.id });
                    }
                })
            }

            if (simcard_bundles.length == 1) {
                if (this.selected_type == 'regular') {
                    this.bundle = simcard_bundles[0]
                }
                if (this.selected_type == 'smart') {
                    this.prepaid_plan = simcard_bundles[0]
                }
            }
            return simcard_bundles
        },
        ipRanges() {
            var ip_ranges = []
            var quantity = this.quantity
            // First calculate the default IP ranges: SimServices and TestVPN
            var default_obj = this.customers.payload.find((o) => o.id === 250)
            var default_ipranges = _.filter(default_obj.ip_ranges, function (o) {
                return !o.free_ips == 0
            })
            default_ipranges = _.filter(default_ipranges, { operator: this.selected_operator })
            for (var i = 0; i < default_ipranges.length; i++) {
                if (Number(default_ipranges[i].free_ips) < quantity) {
                    default_ipranges[i].color = 'red'
                }
            }

            ip_ranges = ip_ranges.concat(default_ipranges)

            // Then get all the customer specific IP ranges
            if (this.selected_customer) {
                var customer_obj = this.customers.payload.find((o) => o.id === this.selected_customer)
                var customer_ipranges = _.filter(customer_obj.ip_ranges, function (o) {
                    return !o.free_ips == 0
                })
                customer_ipranges = _.filter(customer_ipranges, { operator: this.selected_operator })
                for (var i = 0; i < customer_ipranges.length; i++) {
                    if (Number(customer_ipranges[i].free_ips) < quantity) {
                        customer_ipranges[i].color = 'red'
                    }
                }
                ip_ranges = ip_ranges.concat(customer_ipranges)
                this.ip_ranges = ip_ranges
            }
            return ip_ranges
        },
        shippingFee() {
            if (this.selected_customer) {
                var customer_obj = this.customers.payload.find((o) => o.id === this.selected_customer)
                this.shipping =  customer_obj.shipping_fee
                return this.shipping
            } else {
                this.shipping = 15.95
                return this.shipping
            }
        },
    },
    data() {
        return {
            too_many: false,
            ip_ranges: [],
            error: false,
            error_message: '',
            filtered: '',
            is_loading: false,
            bundles: [],
            dialog: false,
            order_stepper: 1,
            ordertype_valid: true,
            details_valid: true,
            config_valid: true,
            simcards_valid: true,
            selected_item: '',
            operators: [
                { name: 'Tele2', id: 'tele2' },
                { name: 'KPN Roosendaal', id: 'kpn_rd' },
                { name: 'KPN Eindhoven', id: 'kpn_eh' },
                { name: 'Telenor', id: 'telenor' },
                { name: 'T-mobile', id: 't_mobile' },
                { name: 'Vodafone', id: 'vodafone' },
                { name: 'Conexa London', id: 'conexa_ld' },
                { name: 'Conexa Oslo', id: 'conexa_os' },
            ],
            billable_states: [
                { id: true, name: 'Billable' },
                { id: false, name: 'Non-Billable' },
            ],
            billable_state: { id: true, name: 'Billable' },
            shipping: '',
            selected_operator: '',
            simcard_type: '',
            quantity: '',
            selected_customer: '',
            selected_customername: '',
            new_customername: '',
            new_customercontact: '',
            new_customeremail: '',
            order_types: [
                { name: 'Regular SIM', id: 'regular' },
                // { name: 'LoRa Keys', id: 'lora' },
                { name: 'Smart SIM', id: 'smart' },
                { name: 'Test SIM', id: 'test' },
            ],
            selected_type: '',
            bundle_option: 'Existing Bundle',
            bundle_options: ['Existing Bundle', 'New Bundle'],
            prepaidplan_option: 'Existing PrePaid Plan',
            prepaidplan_options: ['Existing PrePaid Plan', 'New PrePaid Plan'],
            prepaid_plan: '',
            prepaid_credits: 50,
            prepaid_status: 'Deactivated',
            prepaid_statuses: ['Activated', 'Deactivated'],
            existing_bundles: [],
            bundle: '',
            selected_subscription: '',
            remark: '',
            existing_prepaidplans: ['classic', 'SSS', 'MMM', 'LLL'],
            prepaidplan: '',
            communication_plan: '',
            communicationplan_types: ['Data Only', 'Data SMS', 'Data Voice', 'Data SMS Voice'],
            selected_zone: '',
            zone_types: ['Netherlands', 'Europe', 'Global', 'Globel Select', 'Global Extended'],
            selected_bundle: '',
            bundle_types: ['Pooled Bundle', 'Pay As You Go', 'Auto Rated', 'Auto Select'],
            pool_amount: '',
            pool_units: ['MB', 'GB'],
            selected_poolunit: '',
            eco: false,
            flex: false,
            flex_included: 'No Data',
            included_types: ['No Data', 'MB Included', 'GB Included'],
            iccids_single: '',
            iccids_ranged_first: '',
            iccids_ranged_last: '',
            iccids_random: '',
            iccids_type: 'range',
            iccids: [],
            single_iccids_valid: true,
            ordered_list_valid: true,
            random_list_valid: true,
            response_iccids: '',
            show_iccids: false,
            list: [
                {
                    id: 1,
                    value: '',
                },
            ],
            snackbar: {
                state: false,
                color: '',
                message: '',
                timeout: 5000,
                multiline: true,
                persistent: false,
            },
            rules: {
                required: (val) => !!val || 'Required.',
                iccids: (val) => (val && val.length >= 10) || 'Simcard ICCID needs to be at least 10 digits',
                amounts: (val) => val > 0 || 'Simcard amount needs to be at least 1',
                credits: (val) => val > 10 || 'SmartSim credits needs to be at least 10',
                max: (val) => val.length <= 100 || 'Remark can be max 100 Characters',
                currency: (value) => {
                    const pattern = /^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/
                    return pattern.test(value) || 'Invalid currency format!'
                },
            },
            order_status: '',
            order_message: '',
            customer_specials: [],
            customer_ipranges: ['Sim Services', 'Test-VPN'],
            selected_iprange: '',
            setup_fee: '',
            custom_items: [
                { name: 'IP address', id: '#ip_address' },
                { name: 'Subscription', id: '#subscription' },
                { name: 'IMEI Lock', id: '#imei_lock' },
                { name: 'IMSI', id: '#imsi' },
                { name: 'Custom', id: 'Custom' },
            ],
            custom_1: { default: '', value: '' },
            custom_2: { default: '', value: '' },
            custom_3: { default: '', value: '' },
            custom_4: { default: '', value: '' },
            custom_5: { default: '', value: '' },
            lora_app_server: '',
            lora_conn_plan: '',
            lora_model: '',
            lora_motion_id: '',
            lora_key_type: 'appeui_appkey',
            lora_keys: [],
            moron_proof: true,
            lora_app_servers: [
                { name: 'AppServer 1', id: '1' },
                { name: 'AppServer 2', id: '2' },
                { name: 'AppServer 3', id: '3' },
                { name: 'AppServer 4', id: '4' },
            ],
            lora_conn_plans: [
                { name: 'Mostly', id: '1' },
                { name: 'Often', id: '2' },
                { name: 'Rarely', id: '3' },
                { name: 'Regular', id: '4' },
                { name: 'Sometimes', id: '5' },
            ],
            lora_models: [
                { name: 'Fast Moving', id: '1' },
                { name: 'Normandic', id: '2' },
                { name: 'Slow Moving', id: '3' },
                { name: 'Static', id: '4' },
            ],
            lora_motion_ids: [
                { name: 'Near Static', id: '1' },
                { name: 'Walking Speed', id: '2' },
                { name: 'Bike Speed', id: '3' },
                { name: 'Vehicle Speed', id: '4' },
                { name: 'Random', id: '5' },
            ],
        }
    },
    watch: {
        bundle() {
            if (typeof this.bundle == 'object') {
                this.subscriptionItems.forEach((element) => {
                    if (element.id == this.bundle.id) {
                        this.setup_fee = element.setup_fee
                    }
                })
            }
            if (typeof this.bundle == 'number') {
                this.subscriptionItems.forEach((element) => {
                    if (element.id == this.bundle) {
                        this.setup_fee = element.setup_fee
                        this.setup_fee = element.setup_fee
                    }
                })
            }
        },
        prepaid_plan() {
            if (typeof this.prepaid_plan == 'object') {
                this.subscriptionItems.forEach((element) => {
                    if (element.id == this.prepaid_plan.id) {
                        this.setup_fee = element.setup_fee
                        this.setup_fee = element.setup_fee
                    }
                })
            }
            if (typeof this.prepaid_plan == 'number') {
                this.subscriptionItems.forEach((element) => {
                    if (element.id == this.prepaid_plan) {
                        this.setup_fee = element.setup_fee
                        this.setup_fee = element.setup_fee
                    }
                })
            }
        },
    },
    methods: {
        getOperator(operator_sid) {
            const operators = {
                tele2: '2POM',
                kpn_rd: 'KPOM',
                kpn_eh: 'KPOM',
                telenor: 'TPOM',
                t_mobile: 'TMPOM',
                vodafone: 'voda',
                conexa_ld: 'mobilenetwork',
                conexa_en: 'mobilenetwork',
            }

            return operators[operator_sid]
        },
        toggleSnackbar(persistent, color, message) {
            this.snackbar.state = true
            this.snackbar.color = color
            this.snackbar.message = message

            if (persistent == true) {
                this.snackbar.persistent = true
                this.snackbar.timeout = 0
            }
        },
        getImgUrl(img) {
            const telecoms = [
                'arkessa',
                'airtel',
                'kpn',
                't-mobile',
                'orange',
                'tele2',
                'vodafone',
                'base',
                'o2',
                'proximus',
                'telefonica',
                'telenor',
                'three',
                'mobistar',
                'telekom',
                'cable',
                'digicel',
            ]

            const i = telecoms.findIndex((v) => img.toLowerCase().includes(v))
            if (i !== -1) {
                return '/img/' + telecoms[i] + '.svg'
            }

            return '/img/mobilenetwork.svg'
        },
        get_bundles() {
            this.$store
                .dispatch('api/call', {
                    name: 'subscriptions_list',
                    url: '/root/subscriptions/' + this.selected_customer,
                    params: {
                        operator: this.selected_operator,
                        bundle_type_skip: 'NEW',
                    },
                })
                .then(
                    (response) => {
                        this.bundles = true
                    },
                    (error) => {
                        this.bundles = false
                        console.log(this.data.payload)
                    }
                )
        },
        check_orderd_list(first, last, quantity) {
            first = Number(first.slice(0, -1).slice(first.length - 6))
            last = Number(last.slice(0, -1).slice(last.length - 6))

            if (this.moron_proof == false) {
                return true
            }

            if (quantity > 1) {
                quantity = quantity - 1
            }

            if (first + quantity == last) {
                return true
            } else {
                return false
            }
        },
        validate_ordertype(step = false) {
            if (this.$refs.order_type.validate()) {
                this.ordertype_valid = true
                this.is_loading = true

                if (this.selected_type == 'test') {
                    this.custom_3.default = 'Custom'
                    this.custom_3.value = 'Testkaart'
                }

                let customer_obj = this.customers.payload.find((o) => o.id === this.selected_customer)
                let customer_ipranges = _.filter(customer_obj.ip_ranges, function (o) {
                    return !o.free_ips == 0
                })

                this.customer_specials = customer_obj.specials
                this.customer_ipranges = this.customer_ipranges.concat(customer_ipranges)

                let selected_type = this.selected_type

                if (this.customer_specials.length > 1) {
                    this.customer_specials = _.filter(customer_obj.specials, function (o) {
                        return o.subcategory == selected_type
                    })
                }

                if (['regular', 'smart', 'test'].includes(this.selected_type)) {
                    let url_customer = this.selected_type == 'test' ? '' : `/${this.selected_customer}`

                    this.$store
                        .dispatch('api/call', {
                            url: '/root/subscriptions' + url_customer,
                            name: 'subscription_list',
                            params: {
                                operator: this.selected_operator,
                                bundle_type_skip: 'NEW',
                            },
                        })
                        .then(
                            (response) => {
                                if (step) {
                                    if (response.data.payload.length == 0) {
                                        this.ordertype_valid = false
                                        this.is_loading = false
                                        this.toggleSnackbar(
                                            true,
                                            'error',
                                            'No bundles found for operator: ' + this.selected_operator
                                        )
                                    } else if (response.data.payload.length > 0) {
                                        if (this.selected_type == 'test') {
                                            this.bundle = response.data.payload[0].id
                                        }
                                        this.bundles = response.data.payload
                                        this.order_stepper = step
                                        this.is_loading = false
                                        this.toggleSnackbar(false, 'success', 'Bundles retrieved')
                                    }
                                }
                            },
                            (error) => {
                                console.log(this.data.payload)
                                this.ordertype_valid = false
                                this.is_loading = false
                                this.toggleSnackbar(
                                    true,
                                    'error',
                                    'No bundles found for operator: ' + this.selected_operator
                                )
                            }
                        )
                } else {
                    this.is_loading = false
                    if (step) {
                        this.order_stepper = step
                    } else {
                        this.ordertype_valid = false
                    }
                }
            }
        },
        validate_details(step = false) {
            if (['regular', 'smart', 'test'].includes(this.selected_type)) {
                this.is_loading = true
                this.selected_customername = this.customers.payload.find((o) => o.id == this.selected_customer)
                this.selected_subscription = this.subscriptions.payload.find((o) => o.id == this.bundle)
            }

            if (!['conexa_ld', 'conexa_os'].includes(this.selected_operator)) {
                var ip_range = this.ip_ranges.find((o) => o.id === this.selected_iprange)

                if (ip_range.free_ips < this.quantity) {
                    this.too_many = true
                    this.details_valid = false
                    return
                }
            }

            if (this.$refs.details.validate()) {
                this.details_valid = true
                this.is_loading = false
                this.too_many = false

                // Also activate stepper
                if (step) {
                    this.order_stepper = step
                }
            } else {
                this.is_loading = false
                this.details_valid = false
            }
        },
        validate_simcards(step = false) {
            if (this.$refs.simcards.validate()) {
                if (this.iccids_type == 'range') {
                    this.simcards_valid = this.check_orderd_list(
                        this.iccids_ranged_first,
                        this.iccids_ranged_last,
                        this.quantity
                    )
                    this.ordered_list_valid = false
                    if (this.simcards_valid == true) {
                        this.ordered_list_valid = true
                        if (step) {
                            this.order_stepper = step
                        }
                    }
                } else if (this.iccids_type == 'random') {
                    if (this.iccids_random.split(/\s*[\n, ]+\s*/).length != this.quantity) {
                        this.random_list_valid = false
                        this.simcards_valid = false
                    } else {
                        if (step) {
                            this.random_list_valid = true
                            this.simcards_valid = true
                            this.order_stepper = step
                        }
                    }
                } else if ((this.iccids_type = 'single')) {
                    if (this.iccids_single.length > 1 && this.quantity > 1) {
                        this.single_iccids_valid = false
                        this.simcards_valid = false
                    } else {
                        if (step) {
                            this.single_iccids_valid = true
                            this.simcards_valid = true
                            this.order_stepper = step
                        }
                    }
                } else {
                    this.simcards_valid = true
                    //Also activate stepper
                    if (step) {
                        this.order_stepper = step
                    }
                }
            } else {
                this.simcards_valid = false
            }
        },
        process_order() {
            this.is_loading = true
            this.iccids = []
            this.error = false
            this.error_message = ''

            const expr = this.iccids_type
            switch (expr) {
                case 'single':
                    this.iccids.push(this.iccids_single.toString())
                    break
                case 'range':
                    this.iccids.push(this.iccids_ranged_first.toString())
                    this.iccids.push(this.iccids_ranged_last.toString())
                    break
                case 'random':
                    this.iccids = this.iccids_random.split(/\s*[\n,]+\s*/)
                    break
            }

            let custom_1 = this.custom_1.default
            let custom_2 = this.custom_2.default
            let custom_3 = this.custom_3.default
            let custom_4 = this.custom_4.default
            let custom_5 = this.custom_5.default

            if (this.custom_1.default == 'Custom') {
                custom_1 = this.custom_1.value
            }

            if (this.custom_2.default == 'Custom') {
                custom_2 = this.custom_2.value
            }

            if (this.custom_3.default == 'Custom') {
                custom_3 = this.custom_3.value
            }

            if (this.custom_4.default == 'Custom') {
                custom_4 = this.custom_4.value
            }

            if (this.custom_5.default == 'Custom') {
                custom_5 = this.custom_5.value
            }

            var shipping = this.shipping
            var setup_fee = this.setup_fee
            
            if (typeof shipping == 'string') {
                shipping = parseFloat(shipping).toFixed(2)
            } else {
                shipping.toFixed(2)
            }

            if (typeof setup_fee == 'string') {
                setup_fee = parseFloat(setup_fee).toFixed(2)
            } else {
                setup_fee.toFixed(2)
            }

            var subscription_id = 0
            if (this.selected_type == 'test') {
                const subscription = this.subscriptionItems.filter((object) => {
                    return object.operator === this.selected_operator && object.bundle_type === 'TEST'
                })
                subscription_id = subscription[0].id
            } else {
                subscription_id = this.bundle
                if (typeof this.bundle == 'object') {
                    subscription_id = subscription_id.id
                }
            }

            const data = {
                bundle_subtype: this.selected_type,
                customer_id: this.selected_customer,
                operator: this.selected_operator,
                quantity: this.quantity,
                remark: this.remark,
                iccids: this.iccids,
                iccids_input: this.iccids_type,
                subscription_id: subscription_id,
                shipping: this.shipping,
                setup_fee: this.setup_fee,
                custom_1: custom_1,
                custom_2: custom_2,
                custom_3: custom_3,
                custom_4: custom_4,
                custom_5: custom_5,
                shipping: shipping,
                setup_fee: setup_fee,
            }

            if (!['conexa_ld', 'conexa_os'].includes(this.selected_operator)) {
                var ip_range = this.ip_ranges.find((o) => o.id === this.selected_iprange)
                data.ip_range = ip_range.customer_range
            }

            if (this.selected_type == 'regular' && this.selected_operator == 't_mobile') {
                
                data.billable = this.billable_state.id
            }

            if (this.selected_type == 'smart') {
                data.subscription_id = this.prepaid_plan.id
                data.credits = this.prepaid_credits
                data.status = this.prepaid_status
            }
            
            
            this.$store
                .dispatch('api/call', {
                    method: 'post',
                    url: '/root/order',
                    data: { ...data },
                })
                .then(
                    (response) => {
                        this.dialog = true
                        this.is_loading = false
                        this.order_status = 'success'
                        this.order_message = 'The order was processed successfully.'
                    },
                    (error) => {
                        this.error = true
                        this.error_message = this.payload.description
                        this.dialog = true
                        this.is_loading = false
                        this.order_status = 'error'
                        this.order_message = 'There was an error processing the order.'
                    }
                )
        },
        step_back() {
            this.order_stepper -= 1
        },
        reset() {
            if (this.order_stepper == 2) {
                this.customer_ipranges = ['Sim Services', 'Test-VPN']
                this.customer_specials = []
            }
            if (this.order_stepper == 4) {
                this.$refs.order_type.resetValidation()
                this.$refs.details.resetValidation()
                this.$refs.simcards.resetValidation()
                this.customer_ipranges = ['Sim Services', 'Test-VPN']
                this.customer_specials = []
                this.selected_type = ''
                this.selected_customer = ''
                this.selected_operator = ''
                this.quantity = ''
                this.bundle = ''
                this.prepaid_credits = ''
                this.prepaid_status = 'Deactivated'
                this.selected_iprange = ''
                this.remark = ''
                this.iccids_type = 'range'
                this.iccids_single = ''
                this.iccids_ranged_first = ''
                this.iccids_ranged_last = ''
                this.iccids_random = ''

                this.dialog = false
                this.order_stepper = 1
            }
            if (this.order_stepper > 1) {
                this.order_stepper -= 1
            }
        },
        got_to_overview() {
            this.reset()
            this.$router.push({ path: `/orders/overview` })
        },
        price_index(price) {
            const price_index = 6.3
            const price_increase = price * (price_index / 100)
            return parseFloat(price + price_increase).toFixed(2)
        },
    },
    created() {
        this.$store
            .dispatch('api/call', {
                name: 'customer_list',
                url: '/root/customers',
            })
            .then((response) => {
                this.customers.payload.sort((a, b) => a.customer_name.localeCompare(b.customer_name))
                for (var i = 0; i < this.customers.payload.length; i++) {
                    this.customers.payload[i]['customer_name'] += ` (${this.customers.payload[i]['id']})`
                }
            })
    },
}
</script>
