<template>
    <v-app>
        <v-main>
            <v-container class="fill-height" fluid>
                <v-row
                    align="center"
                    justify="center"
                >
                    <v-col
                    cols="12"
                    sm="8"
                    md="4"
                    >
                    <v-form
                        ref="form"
                        @submit.prevent="submit"
                    >
                        <v-card class="elevation-12">
                            <v-toolbar
                                :color="$cfg.theme.app_bar.background"
                                flat
                            >
                            <img 
                            :src="logo"
                            :width="$cfg.theme.logo.width"
                            :height="$cfg.theme.logo.height"
                            />
                            <v-toolbar-title :class="'ml-3 ' + $cfg.theme.app_bar.text">Two factor Authentication</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-alert
                                    prominent
                                    type="error"
                                    transition="fade-transition"
                                    :value="form.otp.error"
                                > {{ form.otp.message }} </v-alert>
                                
                                    <v-text-field
                                        v-model="otp_code"
                                        label="Enter Passcode"
                                        placeholder="e.g. 123456 or Ab12XyZ4"
                                        hint="You can use your One Time Passcode or Recovery Code"
                                        type="text"
                                        name="otp"
                                        prepend-icon="mdi-lock"
                                        auto-complete="one-time-code"
                                        :rules="[rules.required,rules.passcode]"
                                    ></v-text-field>
                                
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    :loading="pending"
                                    :disabled="!valid"
                                    @click="submit"
                                >
                                    Login
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>
<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            otp_code: '',
            form: {
                otp: {
                    valid: true,
                    error: false,
                    message: 'The provided passcode is invalid, please try again.'
                }
            },
            rules: {
                required: (value) => !!value || 'Required.',
                passcode: (value) => {
                  const otp_code_pattern = /^\d{6}$/;
                  const recovery_code_pattern = /^[a-zA-Z0-9]{8}$/;

                  return otp_code_pattern.test(value) || recovery_code_pattern.test(value) || 'Passcode must be 6 digits or a valid recovery code!';
              }
            }
        }
    },
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload
        }),
        logo() {
            return `/themes/${this.$cfg.theme.folder}/${this.$cfg.theme.logo.filename}`
        },
        valid() {
            return (this.otp_code && this.otp_code.length === 6) || (this.otp_code && /^[a-zA-Z0-9]{8}$/.test(this.otp_code));
        }
    },
    methods: {
        submit() {
            // Only run when valid is false:
            if (!this.valid) {
                return;
            }

            // submit otp
            this.$store.dispatch('api/call', {
                method: 'post',
                url: '/authorize/otp/verify',
                data: {
                    otp_code: this.otp_code
                },
            })
            .then(
                (response) => {
                    // redirect to dashboard
                    this.$router.push({ name: 'home' })
                }, (error) => {
                    // show error
                    this.form.otp.error = true
            });
            
        }
    }
}
</script>