<template>
  <div>
    <v-dialog
      v-model="dialog_credits"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Add Credits</span>
        </v-card-title>
        <v-card-text>
          <v-alert
            v-if="!credits_select"
            color="primary"
            dark
            icon="mdi-wallet"
            dense
          >
            You have {{ wallet }} credits in your wallet.
          </v-alert>
          <v-alert
            v-else-if="credits * selected.length <= wallet"
            color="primary"
            dark
            icon="mdi-wallet"
            dense
          >
            You have {{ wallet }} credits in your wallet. {{ credits * selected.length }} credits will be
            deducted from your wallet to add {{ credits }} credits to {{ selected.length }} SmartSims.
          </v-alert>

          <v-alert
            v-else
            color="red"
            dark
            icon="mdi-wallet"
            dense
          >
            You need {{ credits * selected.length }} credits, but only have {{ wallet }} credits left in
            your wallet. Purchase new credits
            <v-chip
              small
              @click="gotoWallet()"
            >
              here
            </v-chip>
          </v-alert>

          <div class="mb-4">Please choose the amount of credits you want to add.</div>
          <v-select
            v-model="credits_select"
            :items="credit_options"
            label="How many credits?"
            solo
          ></v-select>
          <v-text-field
            v-if="credits_select == 'custom'"
            v-model="credits_custom"
            label="Fill custom amount of credits"
            type="number"
            solo
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click.native="close"
          >Close</v-btn>
          <v-btn
            :disabled="toggleCreditsButton"
            color="primary darken-1"
            text
            @click.native="save"
          >Add Credits</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_switch"
      max-width="700"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Switch Plan</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-expansion-panels
                dark
                class="mb-6"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Information
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>
                      Every SmartSim has its own Rate Plan, which sets the amount of data usage,
                      SMS messages or voice seconds before a credit is subtracted. Here, you can
                      set or change the Rate Plan on your SmartSim.
                    </p>

                    <p>
                      In the below selection boxes, please set the expected amounts of MB of data
                      traffic, amount of SMS messages and seconds of voice traffic you expect your
                      device to process during an average month. These settings create a unique
                      Rate Plan. Click the button ‘Change Plan’ to confirm your selected Rate
                      Plan. When changing an existing Rate Plan, this change will be set to take
                      place on the first day of the new calendar month. An upcoming Rate Plan
                      change can be seen on your SmartSim account page.
                    </p>

                    <p>
                      Please keep in mind that every Rate Plan has its own Gateway Credit value.
                      This is the amount of credits that is subtracted from the SmartSim during
                      its first usage in a calendar month, costs related to use of the
                      infrastructure. Rate Plans with higher usage values tend to have a higher
                      Gateway Credit value, but have a lower price per MB data, SMS or second of
                      voice traffic.
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-layout>
            <v-layout
              mx-auto
              wrap
            >
              <v-flex
                xs12
                md4
              >
                <v-select
                  v-model="switch_select[0]"
                  :items="switch_data_per_month"
                  label="Choose Data"
                  hint="Data per month"
                  solo
                  persistent-hint
                ></v-select>
              </v-flex>
              <v-flex
                xs12
                md4
              >
                <v-select
                  v-model="switch_select[1]"
                  :items="switch_sms_per_month"
                  label="Choose SMS"
                  hint="SMS per month"
                  solo
                  persistent-hint
                ></v-select>
              </v-flex>
              <v-flex
                xs12
                md4
                v-if="switch_select"
              >
                <v-select
                  v-model="switch_select[2]"
                  :items="switch_voice_per_month"
                  label="Choose Voice"
                  hint="Voice seconds per month"
                  solo
                  persistent-hint
                ></v-select>
              </v-flex>
              <v-expand-transition>
                <v-flex
                  xs12
                  v-if="isNotEmpty(selectedRateplan)"
                >
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Rate Plan</th>
                          <th class="text-left">Gateway Credits</th>
                          <th class="text-left">Data per credit</th>
                          <th class="text-left">SMS per credit</th>
                          <th class="text-left">Voice seconds per credit</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ selectedRateplan.plan_code }}</td>
                          <td>{{ selectedRateplan.gateway_credits }}</td>
                          <td>{{ selectedRateplan.data_per_credit | decimal_unzero }}</td>
                          <td>{{ selectedRateplan.sms_per_credit }}</td>
                          <td>{{ selectedRateplan.voice_per_credit }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
              </v-expand-transition>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click.native="close"
          >Close</v-btn>
          <v-btn
            :disabled="toggleSwitchButton"
            color="primary darken-1"
            text
            @click.native="save"
          >Change Plan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_notes"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Edit Notes</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="editedItem.note_1"
                  prepend-icon="mdi-file-document-edit"
                  label="Note 1"
                  hint="Place your own reference or remark here."
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="editedItem.note_2"
                  prepend-icon="mdi-file-document-edit"
                  label="Note 2"
                  hint="Place your own reference or remark here."
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click.native="close"
          >Close</v-btn>
          <v-btn
            color="primary darken-1"
            text
            @click.native="save"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <h1 class="mb-4">Overview</h1>

    <v-toolbar
      dense
      dark
    >
      <v-text-field
        name="searchfield"
        prepend-icon="mdi-table-search"
        v-model="search"
        clearable
        hide-details
      >
      </v-text-field>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-menu offset-y>
          <template v-slot:[`activator`]="{ on }">
            <v-btn
              text
              v-on="on"
              :disabled="toggleActionsButton"
            >
              <v-icon left>mdi-ballot-outline</v-icon>
              Actions
            </v-btn>
          </template>
          <v-list
            v-if="selected.length == 1"
            dense
          >
            <v-list-item-group color="primary">
              <v-list-item @click="showDialog('credits')">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon class="mr-1">mdi-hexagon-multiple</v-icon>
                    Add Credits
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :disabled="toggleSwitchAction"
                @click="showDialog('switch')"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon class="mr-1">mdi-transfer-right</v-icon>
                    Switch Plan
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="showDialog('notes')">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon class="mr-1">mdi-file-edit</v-icon>
                    Edit Notes
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="gotoAuditLog()">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon class="mr-1">mdi-history</v-icon>
                    Show History
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-list
            v-else-if="selected.length > 1"
            dense
          >
            <v-list-item-group color="primary">
              <v-list-item @click="showDialog('credits')">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon class="mr-1">mdi-hexagon-multiple</v-icon>
                    Add Credits
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :disabled="toggleSwitchAction"
                @click="showDialog('switch')"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon class="mr-1">mdi-transfer-right</v-icon>
                    Switch Plans
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="showDialog('notes')">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon class="mr-1">mdi-file-edit</v-icon>
                    Bulk Edit Notes
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table
      v-model="selected"
      :loading="pending"
      :headers="filteredHeaders"
      :items="payload"
      :mobile-breakpoint="0"
      :search="search"
      :footer-props="{ itemsPerPageOptions: [10, 30, 50, 100, 500] }"
      no-results-text="Loading data..."
      class="elevation-1"
      item-key="id"
      sort-by="percentage"
      sort-desc
      show-select
      @click:row="clickSelect"
    >
      <template v-slot:[`item.bundle`]="{ item }">
        <v-chip
          small
          outlined
        >
          <v-icon left>mdi-view-dashboard-outline</v-icon>{{ item.bundle }}
        </v-chip>
      </template>
      <template v-slot:[`item.operator`]="{ item }">
        <img
          :src="getImgUrl(item.operator)"
          class="pr-3"
          style="width:72px;max-height:24px;"
        />
      </template>
      <template v-slot:[`item.plan_code`]="{ item }">
        {{ item.plan_code }}

        <v-tooltip
          v-if="item.switch_plan_code"
          bottom
        >
          <template v-slot:[`activator`]="{ on }">
            <v-icon
              color="primary"
              dark
              v-on="on"
            >mdi-transfer-right</v-icon>
          </template>
          <span>Will be switched to {{ item.switch_plan_code }} next month.</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.percentage`]="{ item }">
        <v-layout
          align-center
          justify-end
        >
          <v-progress-linear
            :value="item.percentage"
            class="mr-2"
            :color="percentageToHsl(item.percentage, 120, 0)"
            height="18"
          >
            <span class="ml-2">{{ item.percentage }}%</span>
            <template v-slot="{ value }">
              <strong>{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
        </v-layout>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <template v-if="item.status === 2">
          <v-chip
            color="green"
            small
            dark
          >
            <v-icon left>mdi-sim</v-icon>Activated
          </v-chip>
        </template>
        <template v-else-if="item.status === 1">
          <v-chip
            color="red"
            small
            dark
          >
            <v-icon left>mdi-sim-alert</v-icon>Deactivated
          </v-chip>
        </template>
        <template v-else-if="item.status === 0">
          <v-chip
            color="gray"
            small
            dark
          >
            <v-icon left>mdi-sim-off</v-icon>Retired
          </v-chip>
        </template>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip right>
          <template v-slot:[`activator`]="{ on }">
            <v-icon
              @click="gotoOverview(item.bundle, item.customer_id)"
              v-on="on"
            >
              sim_card
            </v-icon>
          </template>
          <span>Show cards in Overview</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            headers: [
                { text: 'ICCID', value: 'iccid' },
                { text: 'Data Number', value: 'data_number' },
                { text: 'Plan', value: 'plan_code' },
                { text: 'Operator', value: 'operator' },
                { text: 'User', value: 'user_name', advanced: true },
                { text: 'Customer', value: 'customer_name', advanced: true },
                { text: 'Credits', value: 'credits' },
                { text: 'Percentage', value: 'percentage', width: '190px' },
                { text: 'Status', value: 'status' },
                { text: 'Note 1', value: 'note_1' },
                { text: 'Note 2', value: 'note_2' },
            ],
            selected: [],
            search: '',
            editedItem: {},
            dialog_credits: false,
            dialog_switch: false,
            dialog_notes: false,
            credit_options: [
                { text: '10 credits', value: 10 },
                { text: '50 credits', value: 50 },
                { text: '100 credits', value: 100 },
                { text: '250 credits', value: 250 },
                { text: 'Custom amount', value: 'custom' },
            ],
            credits_select: '',
            credits_custom: 10,
            switch_data_per_month: [
                { text: 'Small (0-2 MB)', value: 'S' },
                { text: 'Medium (3-69 MB)', value: 'M' },
                { text: 'Large (70+ MB)', value: 'L' },
            ],
            switch_sms_per_month: [
                { text: 'Small (0-8)', value: 'S' },
                { text: 'Medium (9-160)', value: 'M' },
                { text: 'Large (161+)', value: 'L' },
            ],
            switch_voice_per_month: [
                { text: 'Small (0-15)', value: 'S' },
                { text: 'Medium (16-55)', value: 'M' },
                { text: 'Large (56+)', value: 'L' },
            ],
            switch_select: [],
        }
    },
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
            plans: (state) => state.calls.plans_list.payload,
        }),
        ...mapState('session', {
            advanced_view: (state) => state.rules.advanced_view || false,
        }),
        filteredHeaders() {
            if (this.advanced_view) {
                return this.headers
            } else {
                return this.headers.filter((h) => h.advanced != true)
            }
        },
        wallet() {
            return this.$store.getters['session/credits']
        },
        credits() {
            return this.credits_select == 'custom' ? this.credits_custom : this.credits_select
        },
        toggleActionsButton() {
            return this.selected.length ? false : true
        },
        toggleCreditsButton() {
            if (!this.credits_select) {
                return true
            }

            if (this.credits == 0) {
                return true
            }

            return this.credits * this.selected.length <= this.wallet ? false : true
        },
        toggleSwitchButton() {
            return this.switch_select.length >= 3 ? false : true
        },
        toggleSwitchAction() {
            if (this.selected.length > 0) {
                for (let select of this.selected) {
                    if (select.plan_code.length > 3 && select.plan_code != 'classic') {
                        return true
                    }
                }
            }
            return false
        },
        selectedRateplan() {
            const index = _findIndex(this.plans, ['plan_code', this.switch_select.join('')])
            return index == -1 ? {} : this.plans[index]
        },
    },
    methods: {
        getImgUrl(img) {
            return '/img/' + img.toLowerCase() + '.svg'
        },
        isNotEmpty(obj) {
            return !_isEmpty(obj)
        },
        percentageToColor(value) {
            const hue = Math.round(value)
            return ['hsl(', hue, ', 100%, 50%)'].join('')
        },
        percentageToHsl(percentage, hue0, hue1) {
            var hue = Math.min(percentage / 100, 1) * (hue1 - hue0) + hue0
            return 'hsl(' + hue + ', 90%, 50%)'
        },
        clickSelect(item) {
            const index = this.selected.findIndex((v) => v.id === item.id)
            if (index !== -1) {
                this.selected.splice(index, 1)
            } else {
                this.selected.push(item)
            }
        },
        showDialog(name) {
            if (this.selected.length > 0) {
                if (this.selected.length == 1) {
                    this.editedItem = Object.assign({}, this.selected[0])
                }
                switch (name) {
                    case 'credits':
                        this.dialog_credits = true
                        break
                    case 'switch':
                        this.dialog_switch = true
                        if (this.selected.length == 1) {
                            if (this.editedItem.plan_code == 'classic') {
                                this.switch_select = 'SSS'.split('')
                            } else {
                                this.switch_select = this.editedItem.plan_code.split('')
                            }
                        } else {
                            this.switch_select = []
                        }
                        break
                    case 'notes':
                        this.dialog_notes = true
                        break
                }
            }
        },
        gotoWallet() {
            this.$router.push({ path: `/smartsims/wallet` })
        },
        gotoAuditLog() {
            this.$router.push({
                name: 'diagnostics_audit',
                query: {
                    search: this.selected[0]['iccid'],
                },
            })
        },
        close() {
            this.dialog_credits = false
            this.dialog_switch = false
            this.dialog_notes = false

            this.credits_select = ''
            this.credits_custom = ''
            this.switch_select = []

            setTimeout(() => {
                this.editedItem = {}
            }, 300)
        },
        save() {
            if (this.selected.length > 0) {
                let data = {}
                data.iccids = _map(this.selected, 'iccid')

                if (this.credits_select) {
                    if (this.credits_select == 'custom') {
                        data.credits = this.credits_custom
                    } else {
                        data.credits = this.credits_select
                    }
                }

                if (!_isEmpty(this.selectedRateplan)) {
                    data.switch_plan_id = this.selectedRateplan.id
                }

                if (_has(this.editedItem, 'note_1')) {
                    data.note_1 = this.editedItem['note_1']
                }

                if (_has(this.editedItem, 'note_2')) {
                    data.note_2 = this.editedItem['note_2']
                }

                this.$store
                    .dispatch('api/call', {
                        name: 'prepaid_change',
                        method: 'put',
                        url: '/smartsims',
                        data: data,
                    })
                    .then((response) => {
                        this.selected = []

                        if (response.data.name == 'credits_added') {
                            this.$store.dispatch('session/credits', response.data.payload.user_credits)
                        }
                        this.$store.dispatch('api/call', {
                            url: '/smartsims',
                        })
                    })

                this.close()
            }
        },
    },
    created() {
        this.$store.dispatch('api/call', {
            url: '/smartsims',
        })
        this.$store.dispatch('api/call', {
            name: 'plans_list',
            url: '/smartsims/plans',
            params: {
                switchable: true,
            },
        })
    },
}
</script>
