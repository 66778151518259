<template>
  <div>
    <h1 class="mb-4">Country History</h1>
    <v-stepper
      v-model="country_stepper"
      vertical
    >
      <v-stepper-step
        :complete="country_stepper > 1"
        step="1"
      >
        Please supply one or more iccids
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-textarea
          autofocus
          filled
          auto-grow
          rounded
          name="iccids"
          v-model="iccids_input"
          rows="8"
          placeholder="8931234567891234567, 8931234567891234568, 8931234567891234569, ..."
        ></v-textarea>
        <v-dialog
          ref="dialog"
          v-model="date_modal"
          width="290px"
        >
          <template v-slot:[`activator`]="{ on }">
            <v-text-field
              v-model="billing_cycle"
              label="Choose a billing cycle"
              prepend-icon="mdi-calendar"
              readonly
              class="mt-n2"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="billing_cycle"
            type="month"
            scrollable
            @input="date_modal = false"
          ></v-date-picker>
        </v-dialog>
        <v-btn
          color="primary"
          class="mt-2"
          :disabled="continueButton"
          @click="diagnose_country_history()"
        >Continue</v-btn>
      </v-stepper-content>

      <v-stepper-step
        :complete="country_stepper > 2"
        step="2"
      >Diagnosing country history</v-stepper-step>

      <v-stepper-content step="2">
        <div v-if="processing">
          <div>Processing request. This will take a while, please stand by...</div>
          <v-progress-linear
            :value="progress"
            :indeterminate="query"
            rounded
            striped
            height="25"
          >
            <strong class="white--text">{{ progress }}%</strong>
          </v-progress-linear>
        </div>
        <div v-else>
          <v-toolbar
            dense
            dark
          >
            <v-text-field
              name="searchfield"
              prepend-icon="mdi-table-search"
              v-model="search"
              clearable
              hide-details
            >
            </v-text-field>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-menu offset-y>
                <template v-slot:[`activator`]="{ on }">
                  <v-btn
                    text
                    v-on="on"
                  >
                    <v-icon left>mdi-ballot-outline</v-icon>
                    Actions
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item-group color="primary">
                    <v-list-item @click="generateXLSX()">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-icon class="mr-1">mdi-file-download</v-icon>
                          Download Excel File
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-toolbar-items>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :search="search"
            :items="payload"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.countries`]="{ item }">
              <v-chip-group column>
                <v-chip
                  class="mr-1"
                  label
                  v-for="country in item.countries"
                  :key="country"
                  small
                >
                  <v-avatar left>
                    <flag :iso="country" />
                  </v-avatar>
                  {{ country | iso_to_country }}
                </v-chip>
              </v-chip-group>
            </template>
          </v-data-table>
        </div>

        <v-btn
          class="mt-8"
          dark
          @click="reset(true)"
        >Back</v-btn>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import XLSX from 'xlsx'
export default {
    data() {
        return {
            headers: [
                { text: 'ICCID', value: 'iccid' },
                { text: 'Countries', value: 'countries' },
            ],
            country_stepper: 1,
            search: null,
            iccids_input: '',
            iccids: [],
            date_modal: false,
            processing: false,
            query: true,
            progress: 0,
            billing_cycle: new Date().toISOString().substr(0, 7),
            cycle_year: '',
            cycle_month: '',
            token: '',
        }
    },
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
        }),
        continueButton() {
            return this.iccids_input ? false : true
        },
    },
    timers: {
        checkCountryHistoryStatus: { time: 1000, repeat: true },
    },
    methods: {
        diagnose_country_history() {
            this.processing = true
            this.country_stepper = 2
            this.iccids = this.iccids_input.split(/\s*[\n,]+\s*/)

            this.$store
                .dispatch('api/call', {
                    method: 'post',
                    url: '/diagnostics/country',
                    data: {
                        iccids: this.iccids,
                        cycle_year: this.cycle_year,
                        cycle_month: this.cycle_month,
                    },
                })
                .then(
                    (response) => {
                        if ('token' in response.data.payload) {
                            this.token = response.data.payload.token
                            this.$timer.start('checkCountryHistoryStatus')
                        }
                    },
                    (error) => {
                        this.reset(true)
                    }
                )
        },
        reset(stepper = false) {
            this.iccids = []
            this.iccids_input = ''
            this.processing = false
            this.query = true
            this.progress = 0
            this.token = ''
            this.cycle_year = ''
            this.cycle_month = ''

            if (stepper) {
                this.country_stepper = 1
            }
        },
        checkCountryHistoryStatus() {
            this.$store
                .dispatch('api/call', {
                    method: 'get',
                    url: '/diagnostics/country/' + this.token,
                })
                .then(
                    (response) => {
                        this.progress = response.data.progress

                        if (this.progress > 0) {
                            this.query = false
                        }

                        if (this.progress == 100) {
                            this.$timer.stop('checkCountryHistoryStatus')
                            this.reset()
                        }
                    },
                    (error) => {
                        console.log('error')
                        this.$timer.stop('checkCountryHistoryStatus')
                    }
                )
        },
        generateXLSX() {
            let ws_data = []
            for (let key in this.payload) {
                ws_data.push({ iccid: this.payload[key].iccid, countries: this.payload[key].countries.join(',') })
            }

            let ws = XLSX.utils.json_to_sheet(ws_data, { header: ['iccid', 'countries'] })
            let wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws, `${this.billing_cycle}`)
            XLSX.writeFile(wb, `country-history-${this.billing_cycle}.xlsx`)
        },
    },
}
</script>
