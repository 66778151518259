<template>
  <div>
    <h1 class="mb-4">Downloads</h1>
    <v-toolbar
      dense
      dark
    >
      <v-text-field
        name="searchfield"
        prepend-icon="mdi-file-search"
        v-model="search"
        clearable
        hide-details
      >
      </v-text-field>
      <v-spacer></v-spacer>
      <v-toolbar-items>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table
      :headers="filteredHeaders"
      :items="payload"
      :search="search"
      class="elevation-1"
      :mobile-breakpoint="0"
      :loading="pending"
      :footer-props="{ itemsPerPageOptions: [10, 30, 50, 100, 500] }"
    >
      <template v-slot:[`item.filetype`]="{ item }">
        <v-chip
          v-if="item.filetype === 'xlsx'"
          color="secondary"
          small
          outlined
        >
          <v-icon left>mdi-file-table</v-icon>Excel
        </v-chip>
        <v-chip
          v-else-if="item.filetype === 'pdf'"
          color="secondary"
          small
          outlined
        >
          <v-icon left>mdi-file-pdf</v-icon>PDF
        </v-chip>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ item.date | date }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <template v-if="item.generating">
          <v-progress-linear
            color="secondary"
            :indeterminate="true"
            class="my-1"
          ></v-progress-linear>
          <span>Generating file..</span>
        </template>
        <template v-else>
          <v-btn
            color="primary"
            small
            block
            @click="generateDownload(item)"
          >
            <v-icon left>mdi-file</v-icon>Download File
          </v-btn>
        </template>
      </template>
      <v-alert
        slot="no-results"
        :value="true"
        color="info"
        icon="mdi-magnify-close"
        class="mt-4"
        dark
      >
        There are no results that match your search.
      </v-alert>
    </v-data-table>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import axios from 'axios'
import XLSX from 'xlsx'
import jsPDF from 'jspdf'

export default {
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
            total: (state) => state.calls.default.total,
        }),
        ...mapState('session', {
            advanced_view: (state) => state.rules.advanced_view || false,
        }),
        filteredHeaders() {
            if (this.advanced_view) {
                return this.headers
            } else {
                return this.headers.filter((h) => h.advanced != true)
            }
        },
    },
    data() {
        return {
            headers: [
                {
                    text: 'Name',
                    align: 'left',
                    sortable: false,
                    value: 'name',
                },
                { text: 'Date', value: 'date' },
                { text: 'Category', value: 'category' },
                { text: 'Filetype', value: 'filetype' },
                { text: 'Account Name', value: 'account.name', advanced: true },
                { text: 'Account Email', value: 'account.email', advanced: true },
                { text: 'Action', value: 'action', width: '200px' },
            ],
            search: null,
        }
    },
    methods: {
        generateDownload(item) {
            Vue.set(item, 'generating', true)

            axios({
                url: '/downloads',
                method: 'get',
                params: {
                    category: item.category,
                    parameter: item.parameter,
                },
            }).then((response) => {
                if (item.category == 'consolidated') {
                    this.generateConsolidated(item, response.data.payload)
                } else if (item.category == 'invoice') {
                    this.generateInvoice(item, response.data.payload)
                }

                Vue.set(item, 'generating', false)
            })
        },
        generateConsolidated(item, data) {
            let ws = XLSX.utils.json_to_sheet(data)
            let wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws, item.name)
            XLSX.writeFile(wb, `consolidated_usage_${item.parameter}.xlsx`)
        },
        generateInvoice(item, data) {
            const date = new Date(item.date)
            const invoice_date = date.toLocaleDateString(['nl'], {
                timeZone: 'Europe/Amsterdam',
                hour12: false,
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            })

            let pdf_name = 'Wireless Logic Benelux Invoice - ' + data.order_id
            let doc = new jsPDF()

            let OpenSansRegular = doc.loadFile('./fonts/OpenSans-Regular.ttf')
            doc.addFileToVFS('OpenSans-Regular.ttf', OpenSansRegular)
            doc.addFont('OpenSans-Regular.ttf', 'OpenSans', 'normal')

            let OpenSansBold = doc.loadFile('./fonts/OpenSans-Bold.ttf')
            doc.addFileToVFS('OpenSans-Bold.ttf', OpenSansBold)
            doc.addFont('OpenSans-Bold.ttf', 'OpenSans', 'bold')

            // Invoice Title
            doc.setFont('OpenSans', 'normal')
            doc.setFontSize(22)
            doc.text('Invoice:', 20, 20)
            doc.text(data.order_id, 50, 20)

            // Invoice Date
            doc.setFontSize(16)
            doc.text('Wireless Logic Benelux', 140, 20)
            doc.text('Date:', 20, 30)
            doc.text(invoice_date, 35, 30)

            // Sim Services details
            doc.setFontSize(10)
            doc.text('Meerenakkerweg 1A', 139, 30)
            doc.text('5652 AR, Eindhoven', 139, 34)
            doc.text('Nederland', 139, 38)
            doc.text('email: info.bnx@wirelesslogic.com', 139, 44)
            doc.text('site: www.wirelesslogic.com', 139, 48)
            doc.text('IBAN: NL30RABO0177461209', 139, 56)
            doc.text('BIC: RABONL2U', 139, 60)
            doc.text('KvK: 56651678', 139, 68)
            doc.text('BTW nr: NL852238010B01', 139, 76)

            // Customer invoice details
            let offset_y = data.company ? 44 : 56

            doc.setFontSize(14)
            doc.text('Invoice details', 20, offset_y)
            doc.setFontSize(10)
            doc.text(data.first_name + ' ' + data.last_name, 20, offset_y + 8)
            doc.text(data.address, 20, offset_y + 12)
            doc.text(data.city + ' ' + data.postal_code, 20, offset_y + 16)
            doc.text(data.country, 20, offset_y + 20)
            if (data.company) {
                doc.text(data.company, 20, offset_y + 28)
                doc.text(data.vat_number, 20, offset_y + 32)
            }

            // Draw divider line
            doc.line(20, 100, 190, 100)

            // Actual invoice data
            doc.setFontSize(12)
            doc.setFont('OpenSans', 'bold')
            doc.text('Product', 30, 120)
            doc.text('Total', 170, 120)
            doc.setFont('OpenSans', 'normal')
            doc.setDrawColor(128, 128, 128)
            doc.line(30, 122, 180, 122)
            doc.text('1 x' + ' ' + data.credits + ' ' + 'Credits', 30, 128)
            doc.text('€' + ' ' + data.amount, 180, 128, { align: 'right' })

            // Subtotal divider
            doc.line(30, 130, 180, 130)
            doc.setFont('OpenSans', 'bold')
            doc.text('Subtotal', 30, 135)
            doc.text('€' + ' ' + data.amount, 180, 135, { align: 'right' })
            doc.setFont('OpenSans', 'normal')
            doc.line(30, 137, 180, 137)
            doc.text('VAT(21%)', 30, 142)
            doc.text('€' + ' ' + data.vat, 180, 142, { align: 'right' })
            doc.line(30, 144, 180, 144)
            doc.setFont('OpenSans', 'bold')
            doc.text('Total', 30, 149)
            doc.text('€' + ' ' + data.total_amount, 180, 149, { align: 'right' })

            // Draw footer line
            doc.line(20, 280, 190, 280)

            // Save PDF file
            doc.save(pdf_name + '.pdf')
        },
    },
    created() {
        this.$store.dispatch('api/call', {
            url: '/downloads',
        })
    },
}
</script>